import React, { useEffect } from 'react'
import axios from 'axios'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import './App.css'
import { EscolhaEcv } from './domains/home/EscolhaEcv'
import { NovaSolicitacaoStore } from './domains/home/NovaSolicitacaoStore'
import { Pagamento } from './domains/home/Pagamento'
import { Recibo } from './domains/home/Recibo'
import { AuthStore } from './domains/auth/AuthStore'
import { Home } from './domains/home/Home'
import { Adm } from './domains/adm/Adm'
import { ModalLogin } from './domains/auth/ModalLogin'
import { GlobalStore } from './GlobalStore'
import { ModalEsqueceuSenha } from './domains/auth/ModalEsqueceuSenha'
import { ModalEsqueceuEmail } from './domains/auth/ModalEsqueceuEmail'
import { ModalCadastro } from './domains/clientes/ModalCadastro'
import { MudarSenhaToken } from './domains/auth/MudarSenhaToken'
import { Agendamento } from './domains/home/Agendamento'
import { ComprovanteAgendamento } from './domains/home/ComprovanteAgendamento'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : 'http://localhost:3507/'

function App() {
  return (
    <>
      <Toaster
        toastOptions={{
          style: { background: '#363636', color: '#fff' },
        }}
      />
      <BrowserRouter>
        <GlobalStore.Provider>
          <AuthStore.Provider>
            <DefineAxiosDefaults />
            <NovaSolicitacaoStore.Provider>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/adm/*" element={<Adm />} />
                <Route path="/escolha-ecv" element={<EscolhaEcv />} />
                <Route path="/pagamento" element={<Pagamento />} />
                <Route path="/comprovante" element={<Recibo />} />
                <Route path="/agendamento" element={<Agendamento />} />
                <Route path="/mudar-senha/:token" element={<MudarSenhaToken />} />
                <Route path="/comprovante-agendamento" element={<ComprovanteAgendamento />} />
              </Routes>
              <ModalLogin />
              <ModalEsqueceuSenha />
              <ModalEsqueceuEmail />
              <ModalCadastro />
            </NovaSolicitacaoStore.Provider>
          </AuthStore.Provider>
        </GlobalStore.Provider>
      </BrowserRouter>
    </>
  )
}

function DefineAxiosDefaults() {
  const { token, clinicaIdSelecionada } = AuthStore.useState()
  useEffect(() => {
    axios.defaults.headers.common.authorization = `Bearer ${token}`
  }, [token])
  useEffect(() => {
    axios.defaults.headers.common.clinicaId = '' + (clinicaIdSelecionada || '')
  }, [clinicaIdSelecionada])
  return null
}

export default App
