import { createSimpleStore } from 'react-simple-reducer'

export type modalType = '' | 'LOGIN' | 'RECUPERAR_SENHA' | 'RECUPERAR_EMAIL' | 'CADASTRO' | 'FINALIZACAO_CADASTRO'
export const GlobalStore = createSimpleStore(
  {
    modalOpen: '' as modalType,
    globalCpfCnpj: '' as string,
  },
  {
    openModal (state, modal: modalType) {
      state.modalOpen = modal
    },
    setGlobalCpfCnpj: (state, globalCpfCnpj: string) => {
      state.globalCpfCnpj = globalCpfCnpj
    },
  }
)

export const useOpenModal = () => {
  const dispatch = GlobalStore.useDispatch()
  function openModal (modal: modalType) {
    dispatch(GlobalStore.actions.openModal(modal))
  }
  function closeModal () {
    dispatch(GlobalStore.actions.openModal(''))
  }
  return { openModal, closeModal }
}
