import React from 'react'
import styled from '@emotion/styled'

export const Button = ({
  primary,
  width,
  loading,
  ...props
}: { primary?: boolean; width?: number; loading?: boolean } & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  if (loading) {
    return (
      <ButtonLayout {...props} primary={primary} width={width} style={{ ...props.style }}>
        <Loader />
      </ButtonLayout>
    )
  }
  return <ButtonLayout {...props} primary={primary} width={width} />
}
const ButtonLayout = styled.button<any>(
  (props) => `
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #7F7F7D;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(81, 75, 57, 0.2);
  height: 44px;
  padding: 8px 8px;
  color: #292926;
  font-size: 14px;
  line-height: 120%;
  font-weight: 600;
  cursor: pointer;
  transition: .25s all;
  &:not(:disabled):hover {
    filter: brightness(0.95);
  }
  width: ${props.width}%;
  ${
    props.primary
      ? `
      background: #4f59b3;
      color: #FFFFFF;
      border: none;
    `
      : ''
  }
  &:disabled {
    background: #EAEAE9;
    color: #292926;
  }
`
)
const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid transparent;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
`
