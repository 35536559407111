import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import { UsuarioLogado } from '../../../components/UsuarioLogado'
import { Schemas } from '../../../components/ApiSchemas'
import { addDays, format } from 'date-fns'
import { Button } from '../../../components/Button'
import iconSearch from '../../../assets/icon-search.svg'
import { Input } from '../../../components/Input'
import iconSolicitacoes from '../../../assets/icon-solicitacoes.svg'
import iconSolicitacoesFinalizadas from '../../../assets/icon-solicitacoes-finalizadas.svg'
import iconSolicitacoesNaoRealizadas from '../../../assets/icon-solicitacoes-nao-realizadas.svg'
import iconSolicitacoesAguardandoPagamento from '../../../assets/icon-solicitacoes-aguardando-pagamento.svg'
import { createSimpleStore } from 'react-simple-reducer'
import { api, useApi } from '../../../components/Api'
import { createSelector } from 'reselect'
import toast from 'react-hot-toast'
import { Modal } from '../../../components/Modal'
import { ReciboComponent } from '../../home/components/ReciboComponent'
import { LogoMarcaVeiculo } from '../../../components/LogoMarcaVeiculo'
import { useWindowWidth } from '../../../components/hooks/use-window-width'
import { Show } from '../../../components/Show'
import { AuthStore, getUsuarioLogado } from '../../auth/AuthStore'
import { Paginacao } from '../../../components/Paginacao'
import { FluxoSolicitacao } from '../../home/components/FluxoSolicitacao'
import IconLaudo from '../../../assets/icon-laudo.png'

type TFiltroTipo = 'TODAS' | 'FINALIZADAS' | 'NAO_REALIZADAS' | 'AGUARDANDO_PAGAMENTO'
type TPaginacao = 15 | 25 | 50 | 100

const Store = createSimpleStore(
  {
    loading: false,
    total: 0,
    totalFinalizadas: 0,
    totalNaoRealizadas: 0,
    totalAguardandoPagamento: 0,
    filtroTipo: 'TODAS' as TFiltroTipo,
    filtroTexto: '',
    solicitacoes: [] as Schemas.Solicitacao[],
    limitPage: 15 as TPaginacao,
    paginaAtual: 1 as number,
  },
  {
    getSolicitacoesStarted(state) {
      state.loading = true
    },
    getSolicitacoesSuccess(state, responseGetSolicitacoesAdm) {
      state.total = responseGetSolicitacoesAdm.total
      state.totalFinalizadas = responseGetSolicitacoesAdm.totalFinalizadas
      state.totalNaoRealizadas = responseGetSolicitacoesAdm.totalNaoRealizadas
      state.totalAguardandoPagamento = responseGetSolicitacoesAdm.totalAguardandoPagamento
      state.solicitacoes = responseGetSolicitacoesAdm.solicitacoes
      state.loading = false
    },
    getSolicitacoesError(state) {
      state.loading = false
    },
    changeFiltroTipo(state, filtroTipo: TFiltroTipo) {
      state.filtroTipo = filtroTipo
    },
    changeFiltroTexto(state, value: string) {
      state.filtroTexto = value
    },
    changeQuerys(state, query) {
      state.limitPage = query.limit
      state.paginaAtual = query.page
      state.loading = true
    },
  },
  {
    thunks: {
      getSolicitacoesWithQueryParams({ page, offset, limit, filtroTexto, filtroTipo }) {
        return async (dispatch) => {
          try {
            dispatch(Store.actions.changeQuerys({ page, limit }))
            const responseGetVistoriasAdmWithQuery = await api.Solicitacoes.getSolicitacoesAdm({
              params: {
                limit,
                offset,
                order: 'createdAt',
                orderBy: 'DESC',
                filtroTexto,
                filtroTipo,
              } as any,
            })
            dispatch(Store.actions.getSolicitacoesSuccess(responseGetVistoriasAdmWithQuery))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro'
            toast.error(message)
            dispatch(Store.actions.getSolicitacoesError())
          }
        }
      },
    },
  }
)

type IState = ReturnType<typeof Store.useState>
const totaisSolicitacoes = createSelector(
  (s: IState) => s.total,
  (s: IState) => s.totalFinalizadas,
  (s: IState) => s.totalAguardandoPagamento,
  (s: IState) => s.totalNaoRealizadas,
  (s: IState) => s.filtroTipo,
  (total, finalizadas, aguardandoPagamento, naoRealizadas, tipo) => {
    if (tipo === 'AGUARDANDO_PAGAMENTO') return aguardandoPagamento
    if (tipo === 'FINALIZADAS') return finalizadas
    if (tipo === 'NAO_REALIZADAS') return naoRealizadas
    return total
  }
)

export const Solicitacoes = () => {
  const { Layout, Flex, Heading, Grid, Divider, UsuarioLogado, ContainerLista } = Solicitacoes
  const [solicitacaoByParams, setSolicitacaoByParams] = useState({
    solicitacaoShow: false,
    solicitacao: ({} as Schemas.Solicitacao) || null,
  })
  const [AcoesSolicitacao, setAcoesSolicitacao] = useState<Schemas.Solicitacao>(
    {} as Schemas.Solicitacao
  )
  const [modalAcoesSolicitacao, setModalAcoesStatus] = useState(false)
  const { clinicaIdSelecionada, token } = AuthStore.useState()
  const [optionPrintVisible, setModalOptionPrint] = useState(false)

  const init = useCallback((dispatch) => {
    if (!token) return
    dispatch(Store.thunks.getSolicitacoesWithQueryParams({ page: 1, offset: 0, limit: 15 }))
  }, [])

  const isWide = useWindowWidth() > 900

  const handleAcoesStatus = (solicitacao) => {
    setModalAcoesStatus(true)
    setAcoesSolicitacao(solicitacao)
  }

  return (
    <Store.Provider init={init} key={clinicaIdSelecionada}>
      <Layout>
        <Flex>
          <Busca />
          <UsuarioLogado aplicacao="preto" />
        </Flex>
        <Divider />
        <Flex>
          <Heading>Solicitações</Heading>
          <ButtonRelatorio setModalOptionPrint={setModalOptionPrint} />
        </Flex>
        <Grid>
          <BlocoSolicitacoes />
          <BlocoFinalizadas />
          <BlocoNaoRealizadas />
          <BlocoAguardandoPagamento />
        </Grid>
        <ContainerLista>
          <Lista
            setSolicitacaoByParams={setSolicitacaoByParams}
            solicitacaoByParams={solicitacaoByParams}
          >
            {(solicitacao) => (
              <ListaItem
                key={solicitacao.id}
                solicitacao={solicitacao}
                className={solicitacao.id}
                handleAcoesStatus={handleAcoesStatus}
              />
            )}
          </Lista>
        </ContainerLista>
        <PaginacaoValues />
      </Layout>
      {solicitacaoByParams.solicitacaoShow && (
        <Modal
          visible={solicitacaoByParams.solicitacaoShow}
          onClose={() => {
            setSolicitacaoByParams({
              solicitacao: {} as Schemas.Solicitacao,
              solicitacaoShow: false,
            })
            window.history.pushState('', '', '/adm')
          }}
          width={isWide ? 1400 : 800}
        >
          <ReciboComponent solicitacao={solicitacaoByParams.solicitacao} />
        </Modal>
      )}
      <Modal visible={modalAcoesSolicitacao} width={470} onClose={() => setModalAcoesStatus(false)}>
        <ContentAcoesStatusVistoria
          onClose={() => setModalAcoesStatus(false)}
          solicitacao={AcoesSolicitacao}
        />
      </Modal>
      <Modal visible={optionPrintVisible} width={470} onClose={() => setModalOptionPrint(false)}>
        <ContentOptionPrint onClose={() => setModalOptionPrint(false)} />
      </Modal>
    </Store.Provider>
  )
}
Solicitacoes.Layout = styled.div`
  padding: 24px;
  flex: 1;
  @media (max-width: 900px) {
    padding: 12px;
  }
`
Solicitacoes.Flex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
`
Solicitacoes.Heading = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: #545451;
  @media (max-width: 900px) {
    margin-top: 12px;
  }
`
Solicitacoes.Grid = styled.div`
  margin-top: 24px;
  gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  overflow: auto;
`
Solicitacoes.Divider = styled.div`
  border: 1px solid #d4d4d4;
  width: 100%;
  margin: 24px 0;
  @media (max-width: 900px) {
    display: none;
  }
`
Solicitacoes.UsuarioLogado = styled(UsuarioLogado)`
  @media (max-width: 900px) {
    display: none;
  }
`

Solicitacoes.ContainerLista = styled.div``

const Busca = () => {
  const { Layout, Input } = Busca
  const { filtroTexto } = Store.useState()
  const dispatch = Store.useDispatch()

  return (
    <Layout>
      <img src={iconSearch} />
      <Input
        placeholder="Buscar por Renach, Cobrança, CPF"
        value={filtroTexto}
        onChange={(e) => dispatch(Store.actions.changeFiltroTexto(e.target.value))}
      />
    </Layout>
  )
}
Busca.Layout = styled.div`
  position: relative;
  max-width: 620px;
  width: 100%;
  img {
    position: absolute;
    top: 13px;
    left: 26px;
  }
`
Busca.Input = styled(Input)`
  border-radius: 16px;
  padding-left: 60px;
  border: 1px solid #aaaaa8;
  height: 44px;
`

const Datas = () => {
  const { Layout } = Datas
  return <Layout>Datas</Layout>
}
Datas.Layout = styled.div``

const BlocoSolicitacoes = () => {
  const { total, filtroTipo } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.changeFiltroTipo('TODAS'))
  }

  return (
    <Bloco
      onClick={handleClick}
      quantidade={total}
      label={<span>Todas</span>}
      icon={iconSolicitacoes}
      primaryColor="#12A8EC"
      secondaryColor="#E7F6FD"
      active={filtroTipo === 'TODAS'}
    />
  )
}

const BlocoFinalizadas = () => {
  const { totalFinalizadas, filtroTipo } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.changeFiltroTipo('FINALIZADAS'))
  }

  return (
    <Bloco
      onClick={handleClick}
      quantidade={totalFinalizadas}
      label={<span>Concluídas</span>}
      icon={iconSolicitacoesFinalizadas}
      primaryColor="#00CC66"
      secondaryColor="#E9F5EB"
      active={filtroTipo === 'FINALIZADAS'}
    />
  )
}

const BlocoNaoRealizadas = () => {
  const { totalNaoRealizadas, filtroTipo } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.changeFiltroTipo('NAO_REALIZADAS'))
  }

  return (
    <Bloco
      onClick={handleClick}
      quantidade={totalNaoRealizadas}
      label={<span>Não Concluídas</span>}
      icon={iconSolicitacoesNaoRealizadas}
      primaryColor="#EB8D00"
      secondaryColor="#FDF4E5"
      active={filtroTipo === 'NAO_REALIZADAS'}
    />
  )
}

const BlocoAguardandoPagamento = () => {
  const { totalAguardandoPagamento, filtroTipo } = Store.useState()
  const dispatch = Store.useDispatch()

  function handleClick() {
    dispatch(Store.actions.changeFiltroTipo('AGUARDANDO_PAGAMENTO'))
  }

  return (
    <Bloco
      onClick={handleClick}
      quantidade={totalAguardandoPagamento}
      label={<span>Sem pagamento</span>}
      icon={iconSolicitacoesAguardandoPagamento}
      primaryColor="#EB2A00"
      secondaryColor="#FDEAE5"
      active={filtroTipo === 'AGUARDANDO_PAGAMENTO'}
    />
  )
}

const Bloco = ({ quantidade, label, icon, primaryColor, secondaryColor, active, ...props }) => {
  const { Layout } = Bloco
  return (
    <Layout {...props} primaryColor={primaryColor} secondaryColor={secondaryColor} active={active}>
      <div className="bloco-icon-container">
        <img src={icon} />
      </div>
      <div>
        <div className="bloco-contador">{quantidade}</div>
        <div className="bloco-label">{label}</div>
      </div>
    </Layout>
  )
}
Bloco.Layout = styled.div<{ primaryColor; secondaryColor; active }>(
  ({ primaryColor, secondaryColor, active }) => `
  background: #FFFFFF;
  border: 1px solid #D4D4D4;
  border-radius: 8px;
  height: 96px;
  ${active ? `border: 2px solid ${primaryColor};` : ''}
  ${active ? 'box-shadow: 0px 2px 8px rgba(81, 75, 57, 0.2);' : ''}
  transition: .25s all;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 32px;
  span {
    font-size: 16px;
  }
  .bloco-icon-container {
    border-radius: 50%;
    width: 48px;
    min-width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${secondaryColor};
  }
  .bloco-contador {
    color: #545451;
    font-weight: bold;
    font-size: 20px;
  }
  .bloco-label {
    font-size: 12px;
    line-height: 120%;
    color: #7F7F7D;
    white-space: nowrap;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(81, 75, 57, 0.2);
    background: #fafafa;
  }
  @media (max-width: 900px) {
    height: 78px;
    padding: 0 16px;
    gap: 8px;
  }
`
)

const ButtonRelatorio = ({ setModalOptionPrint }) => {
  return <Button onClick={() => setModalOptionPrint(true)}>Gerar Relatório</Button>
}

const Lista = ({ children, setSolicitacaoByParams, solicitacaoByParams }) => {
  const { Table } = Lista
  const { solicitacoes } = Store.useState()

  const windowWidth = useWindowWidth()

  useEffect(() => {
    if (solicitacaoByParams.solicitacaoShow || !solicitacoes.length) return
    const params = new URLSearchParams(window.location.search)
    const getStatusVistoria = params.get('modal-solicitacao-atual')
    if (getStatusVistoria) {
      const newVistorias = solicitacoes?.find(
        (solicitacao) => solicitacao.id === +getStatusVistoria
      )
      if (newVistorias) setSolicitacaoByParams({ solicitacaoShow: true, solicitacao: newVistorias })
    }
  }, [solicitacoes])

  if (windowWidth < 900) {
    return <div>{solicitacoes.map((solicitacao) => children(solicitacao))}</div>
  }

  return (
    <Table cellPadding={0} cellSpacing={0}>
      <thead>
        <tr>
          <th>N° Cobrança</th>
          <th>Candidato</th>
          <th>Tipo de exame</th>
          <th>Renach</th>
          <th style={{ width: 175 }}>Data da Solicitação</th>
          <th style={{ textAlign: 'center', width: 115 }}>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{solicitacoes.map((solicitacao) => children(solicitacao))}</tbody>
    </Table>
  )
}
Lista.Table = styled.table`
  width: 100%;
  margin-top: 32px;
  th {
    color: #7f7f7d;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1.5px solid #d4d4d4;
    padding-bottom: 16px;
    text-align: left;
    padding-right: 5px;
  }
  td {
    vertical-align: middle;
    padding: 18px 0 15px 0;
    border-bottom: 1.5px solid #d4d4d4;
    padding-right: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #545451;
    .enfatico {
      font-weight: 600;
    }
    .singelo {
      color: #7f7f7d;
      font-size: 12px;
    }
  }
  .button-content {
    display: flex;
    justify-content: center;
  }
`
const ListaItem = ({
  solicitacao,
  className,
  handleAcoesStatus,
}: {
  solicitacao: Schemas.Solicitacao
  className
  handleAcoesStatus: (solicitacao: Schemas.Solicitacao) => void
}) => {
  const { Flex, Title, SubTitle, Card, getNodeName, Button, getTipoExame } = ListaItem
  const [isModalVisible, setIsModalVisible] = useState(false)
  const windowWidth = useWindowWidth()
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)

  const validButtonAcoes = useMemo(() => {
    if (!solicitacao?.dataPagamentoCobranca) return true
    return false
  }, [solicitacao])

  const handleSetModalVisible = () => {
    if (solicitacao.deletedAt) return toast.error('Solicitação cancelada.')
    setIsModalVisible(true)
  }

  function closeModalVerMais() {
    setIsModalVisible(false)
  }

  if (windowWidth <= 900) {
    return (
      <Card>
        <Flex style={{ placeContent: 'end', justifyContent: 'space-between' }}>
          <Flex>
            <Title>#{solicitacao.cobrancaId}</Title>
            <SubTitle>
              {' '}
              - Solicitação em: {format(new Date(solicitacao.createdAt), 'dd/MM/yyyy')}{' '}
            </SubTitle>
          </Flex>
          <div data-cy={`status-text-content-${solicitacao?.id}`} className="status-acoes-mobile">
            <StatusVistoria solicitacao={solicitacao} />
          </div>
        </Flex>
        <Flex>
          <div>
            <strong>{solicitacao.cliente.nome}</strong>
          </div>
          <Show when={!!solicitacao.dataPagamentoCobranca}>
            <div>
              <div className="singelo"> Pagamento em: </div>
              <div>{format(new Date(solicitacao.dataPagamentoCobranca), 'dd/MM/yyyy')}</div>
            </div>
          </Show>
        </Flex>
        <div>
          <div style={{ display: 'flex' }}>
            <Title>{getTipoExame(solicitacao?.tipoExame)} &nbsp;</Title>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex' }}>
            <Title>{solicitacao?.renach} &nbsp;</Title> -{' '}
          </div>
        </div>
        <Button id={getNodeName(className)} onClick={handleSetModalVisible}>
          Ver mais
        </Button>
        <Modal visible={isModalVisible} onClose={closeModalVerMais} width={800}>
          <FluxoSolicitacao solicitacao={solicitacao} onClose={closeModalVerMais} />
        </Modal>
      </Card>
    )
  }

  return (
    <tr>
      <td>{solicitacao?.cobrancaId || '-'}</td>
      <td>
        <div>
          <div className="enfatico">{solicitacao?.cliente.nome || '-'}</div>
          <div className="singelo">{solicitacao?.cliente.cpfCnpj || '-'}</div>
        </div>
      </td>

      <td>{getTipoExame(solicitacao?.tipoExame) || '-'}</td>
      <td>
        <strong>{solicitacao?.renach}</strong>
      </td>
      <td>{format(new Date(solicitacao.createdAt), 'dd/MM/yyyy')}</td>
      <td data-cy={`status-text-content-${solicitacao?.id}`}>
        <StatusVistoria solicitacao={solicitacao} />
      </td>
      <td className="button-content">
        <Button onClick={handleSetModalVisible}>Ver mais</Button>
        <Modal visible={isModalVisible} onClose={closeModalVerMais} width={1400}>
          <FluxoSolicitacao solicitacao={solicitacao} onClose={closeModalVerMais} />
        </Modal>
      </td>
    </tr>
  )
}
ListaItem.Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 900px) {
    justify-content: space-between;
  }
`
ListaItem.Title = styled.h3`
  margin: 0px;
  font-size: 14px;
  color: #545451;
`
ListaItem.SubTitle = styled.span`
  margin: 0;
  font-size: 14px;
  color: #545451;
`
ListaItem.LogoMarcaVeiculo = styled(LogoMarcaVeiculo)`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.5px solid #d4d4d4;
  border-radius: 50%;
  img {
    width: 14px;
  }
`
ListaItem.Card = styled.div`
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #d4d4d4;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .enfatico {
    font-size: 20px;
    font-weight: 600;
  }
  .singelo {
    font-size: 12px;
    color: #7f7f7d;
  }
  .status-acoes-mobile {
    display: flex;
    place-items: center;
    gap: 15px;
  }
`
ListaItem.Button = styled(Button)`
  width: 150px;
  @media (max-width: 900px) {
    width: 100%;
  }
`
ListaItem.getTipoExame = (tipoExame: string) => {
  if (tipoExame === 'EXAME_MEDICO') return 'Exame Médico'
  return 'Exame Psicológico'
}
ListaItem.getNodeName = (className: string) => {
  return `card-solicitacao ${className}`
}

const StatusVistoria = ({ solicitacao, ...props }: { solicitacao: Schemas.Solicitacao }) => {
  const { Layout, Circle } = StatusVistoria
  if (solicitacao.dataConclusao) {
    return (
      <Layout {...props}>
        <Circle status="done" /> Concluída
      </Layout>
    )
  }
  if (solicitacao.dataAgendamento && !solicitacao.dataConclusao) {
    return (
      <Layout {...props}>
        <Circle status="done" /> Agendado
      </Layout>
    )
  }
  if (solicitacao.dataPagamentoCobranca) {
    return (
      <Layout {...props}>
        <Circle status="waiting" /> Paga
      </Layout>
    )
  }
  return (
    <Layout {...props}>
      <Circle status="unpaid" /> Aguardando pagamento
    </Layout>
  )
}
StatusVistoria.Layout = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  line-height: 120%;
  @media (max-width: 900px) {
    flex-direction: column;
    font-size: 12px;
  }
`
StatusVistoria.Circle = styled.div<{ status }>(
  ({ status }) => `
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${getColorByStatus(status)};
`
)

function getColorByStatus(status) {
  if (status === 'done') return '#00CC66'
  if (status === 'waiting') return '#FFBC01'
  return '#EB2A00'
}

const ContentAcoesStatusVistoria = ({
  solicitacao,
  onClose,
}: {
  solicitacao: Schemas.Solicitacao
  onClose
}) => {
  const { Layout, Grid, Flex } = ContentAcoesStatusVistoria
  return (
    <Layout data-cy="content-acoes-status-solicitacao">
      <Grid>
        <img src={IconLaudo} width={100} />
        <ContainerTituloVistoriaContent solicitacao={solicitacao} />
      </Grid>
      <Grid>
        <Flex>
          <strong>Solicitante</strong>
          <span>{solicitacao?.cliente?.nome}</span>
        </Flex>
        <Flex>
          <strong>CPF/CNPJ</strong>
          <span>{solicitacao?.cliente?.cpfCnpj}</span>
        </Flex>
        <Flex>
          <strong>Local</strong>
          <span>
            {solicitacao?.clinica?.bairro}{' '}
            {solicitacao?.clinica?.endereco && `/ ${solicitacao?.clinica?.endereco}`}
          </span>
        </Flex>
      </Grid>
      <div style={{ marginTop: 20 }} className="divider-titulo-solicitacao-status" />
      <ContainerButtons solicitacao={solicitacao} onClose={onClose} />
    </Layout>
  )
}

ContentAcoesStatusVistoria.Layout = styled.div`
  padding: 12px 20px;
  .divider-titulo-solicitacao-status {
    height: 1px;
    width: 100%;
    background: #d4d4d4;
  }
`
ContentAcoesStatusVistoria.Grid = styled.div`
  display: grid;
  gap: 10px;
  text-align: center;
  place-items: center;
  &:not(:first-child) {
    margin-top: 15px;
  }
  h2 {
    color: #122640;
  }
  &:nth-child(2) {
    gap: 8px;
    place-items: start;
  }
`

ContentAcoesStatusVistoria.Flex = styled.div`
  display: flex;
  gap: 5px;
  strong {
    font-size: 16px;
    font-weight: 550;
  }
  span {
    font-size: 14px;
  }
`

const ContainerButtons = ({
  solicitacao,
  onClose,
}: {
  solicitacao: Schemas.Solicitacao
  onClose
}) => {
  const { solicitacoes } = Store.useState()

  const { Layout, ButtonName, atualizarVistoriaType } = ContainerButtons
  const [loading, setLoading] = useState(false)

  const handleUpdateStatusVistoria = async (type: string) => {
    try {
      setLoading(true)
      const data = await api.Solicitacoes.updateDataConclusao(
        { vistoriaId: solicitacao.id },
        { params: { type }, options: { manual: true } }
      )
      setLoading(false)
      toast.success('Solicitacao atualizada com sucesso.')
      onClose()
    } catch (error: any) {
      setLoading(false)
      console.log(error)

      const messageError =
        error?.response?.data?.message ?? 'Erro ao atualizar status da solicitacao'
      toast.error(messageError)
    }
  }

  return (
    <Layout>
      <Button data-cy="btn-fechar-modal-status-solicitacao" onClick={onClose}>
        Fechar
      </Button>
      <Button
        data-cy="btn-atualizar-status-solicitacao"
        primary
        loading={loading}
        onClick={async () => await handleUpdateStatusVistoria(atualizarVistoriaType(solicitacao))}
      >
        {ButtonName(solicitacao)}
      </Button>
    </Layout>
  )
}

ContainerButtons.ButtonName = (solicitacao) => {
  if (solicitacao?.dataConclusao) return 'Alterar para reprovada'
  return 'Aprovar'
}

ContainerButtons.atualizarVistoriaType = (solicitacao) => {
  if (solicitacao?.dataConclusao) return 'REPROVADA'
  return 'APROVADA'
}

ContainerButtons.Layout = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
`

const ContainerTituloVistoriaContent = ({ solicitacao }: { solicitacao: Schemas.Solicitacao }) => {
  const { Layout, Title, SubTitle, DefineTitleFromStatus, DefineSubTitleFromStatus } =
    ContainerTituloVistoriaContent

  return (
    <Layout>
      <Title status={!!solicitacao?.dataConclusao}>
        {DefineTitleFromStatus(solicitacao?.dataConclusao)}
      </Title>
      <SubTitle>{DefineSubTitleFromStatus(solicitacao?.dataConclusao)}</SubTitle>
      <div className="divider-titulo-solicitacao-status" />
    </Layout>
  )
}

ContainerTituloVistoriaContent.DefineTitleFromStatus = (dataConclusao) => {
  if (dataConclusao) return 'Solicitação Concluída'
  return 'Solicitação não Concluída'
}
ContainerTituloVistoriaContent.DefineSubTitleFromStatus = (dataConclusao) => {
  if (!dataConclusao) return 'Confirme o status desta solicitacao se estiver aprovada.'
  return `Essa solicitacao foi aprovada por você em ${format(
    new Date(dataConclusao),
    'dd/MM/yyyy'
  )}. Confirme se deseja alterar.`
}

ContainerTituloVistoriaContent.Layout = styled.div`
  text-align: center;
  display: grid;
  gap: 10px;
`
ContainerTituloVistoriaContent.Title = styled.div<{ status }>(
  ({ status }) => `
  font-size: 20px;
  color: ${getStatusDataConclusao(status)};
  font-weight: bold;
  line-height: 120%;
`
)

ContainerTituloVistoriaContent.SubTitle = styled.div`
  font-size: 16px;
  font-weight: 480;
`

const getStatusDataConclusao = (status) => {
  if (status) return '#00CC66'
  return '#FD2E00'
}
const PaginacaoValues = () => {
  const { limitPage, paginaAtual } = Store.useState()
  const total = Store.useSelector(totaisSolicitacoes)
  const dispatch = Store.useDispatch()
  const handlePageActive = ({ page, newLimit }: { page: number; newLimit?: number }) => {
    const offset = (page - 1) * limitPage
    dispatch(
      Store.thunks.getSolicitacoesWithQueryParams({
        page: page,
        offset,
        limit: newLimit ?? limitPage,
      })
    )
  }

  return (
    <Paginacao
      total={total}
      limitPage={limitPage}
      paginaAtual={paginaAtual}
      handlePageActive={handlePageActive}
    />
  )
}

const ContentOptionPrint = ({ onClose }: { onClose }) => {
  const { Layout, Grid, Flex, Title } = ContentOptionPrint
  const { filtroTipo } = Store.useState()

  const [optionPrint, setOptionPrint] = useState('')

  function gerarRelatorio() {
    if (!optionPrint) return toast.error('Selecione um formato de arquivo')
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/solicitacoes/relatorio?exportar=true&status=${filtroTipo}&extension=${optionPrint}`
    )
  }

  return (
    <Layout data-cy="content-acoes-status-vistoria">
      <Title>Selecione o formato do arquivo do relatório</Title>

      <Flex style={{ marginBottom: '20px' }}>
        <label>
          <input
            type="radio"
            checked={optionPrint === 'xlsx'}
            onChange={() => setOptionPrint('xlsx')}
          />{' '}
          XLSX
        </label>
        <label>
          <input
            type="radio"
            checked={optionPrint === 'pdf'}
            onChange={() => setOptionPrint('pdf')}
          />{' '}
          PDF
        </label>
      </Flex>

      <Flex>
        <Button width={120} onClick={onClose}>
          Cancelar
        </Button>
        <Button width={120} onClick={gerarRelatorio}>
          Gerar
        </Button>
      </Flex>
    </Layout>
  )
}

ContentOptionPrint.Layout = styled.div`
  padding: 0px;
  .divider-titulo-vistoria-status {
    height: 1px;
    width: 100%;
    background: #d4d4d4;
  }
`
ContentOptionPrint.Grid = styled.div`
  display: grid;
  gap: 10px;
  text-align: center;
  place-items: center;
  &:not(:first-child) {
    margin-top: 15px;
  }
  h2 {
    color: #122640;
  }
  &:nth-child(2) {
    gap: 8px;
    place-items: start;
  }
`

ContentOptionPrint.Flex = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  strong {
    font-size: 16px;
    font-weight: 550;
  }
  span {
    font-size: 14px;
  }
`

ContentOptionPrint.Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  color: #122640;
  line-height: 120%;
`

ContentOptionPrint.SubTitle = styled.div`
  font-size: 16px;
  font-weight: 480;
`
