import React, { ReactNode, useEffect, useState } from 'react'
import { ReciboComponent } from './components/ReciboComponent'
import { NovaVistoriaContainer } from './nova-vistoria/NovaVistoriaContainer'
import { NovaSolicitacaoStore as Store } from './NovaSolicitacaoStore'
import styled from '@emotion/styled'
import { Modal } from '../../components/Modal'
import iconError from '../../assets/icon-error.svg'
import { Button } from '../../components/Button'
import { useNavigate } from 'react-router'
import { api } from 'components/Api'
import toast from 'react-hot-toast'
import { Schemas } from 'components/ApiSchemas'
import { AgendamentoComponent } from './components/AgendamentoComponent'

export const ComprovanteAgendamento = () => {
  const { solicitacao } = Store.useState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalAgendamentoActive, setModalAgendamento] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = Store.useDispatch()

  const handleCancelaAgendamento = async () => {
    try {
      await api.Solicitacoes.cancelarAgendamento({ vistoriaId: solicitacao.id })
      dispatch(Store.actions.cancelarAgendamentoSuccess())
      toast.success('Agendamento cancelado!')
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Erro ao cancelar agendamento!')
    }
  }

  const handleAlteraAgendamento = () => {
    setModalAgendamento(true)
  }

  const handleConsultaServiço = async (solicitacao: Schemas.Solicitacao) => {
    try {
      const response = await api.Solicitacoes.getAgendamentoByIdControle({
        idSolicitacao: solicitacao?.id,
      })
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }

  const handleAgendar = async (
    horarioSelecionadoId: number,
    horariosDisponiveis: Schemas.GetHorariosDisponiveisResponseDto[]
  ) => {
    if (loading) return
    if (horarioSelecionadoId === null) return toast.error('Selecione um horário!')
    const dataAgendamento: any = horariosDisponiveis[horarioSelecionadoId].inicio

    try {
      setLoading(true)
      await api.Solicitacoes.createAgendamento(
        { dataAgendamento },
        { solicitacaoId: solicitacao.id }
      )
      toast.success('Agendamento efetuado com sucesso!')
      dispatch(Store.actions.setSolicitacao({ ...solicitacao, dataAgendamento }))
      setModalAgendamento(false)
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? 'Erro ao criar agendamento!')
      setLoading(false)
    }
  }

  return (
    <NovaVistoriaContainer passoAtual={6}>
      <HeaderComprovanteAgendamento />
      <ReciboComponent solicitacao={solicitacao}>
        <ContainerOptionsButton>
          <Button
            style={{
              backgroundColor: '#d15656',
              border: 'solid 1px #d15656',
              color: '#FFF',
            }}
            onClick={handleCancelaAgendamento}
          >
            Cancelar agendamento
          </Button>
          <Button onClick={handleAlteraAgendamento}>Alterar agendamento</Button>
          <Button onClick={() => handleConsultaServiço(solicitacao)}>Consultar serviço</Button>
        </ContainerOptionsButton>
      </ReciboComponent>
      <Modal visible={isModalVisible} onClose={() => setIsModalVisible(false)} width={400}>
        <CofirmAlertVistoria />
      </Modal>
      <Modal
        visible={isModalAgendamentoActive}
        width={850}
        onClose={() => setModalAgendamento(false)}
      >
        <AgendamentoComponent solicitacao={solicitacao} handleAgendar={handleAgendar} />
      </Modal>
    </NovaVistoriaContainer>
  )
}

const HeaderComprovanteAgendamento = () => {
  const { Layout, Title, SubTitle } = HeaderComprovanteAgendamento

  return (
    <Layout>
      <Title>Agendamento confirmado</Title>
      <SubTitle>
        A partir de agora você já pode comparecer no local escolhido para realizar seu exame.
        Procure chegar no horário agendado. O tempo máximo de tolerância para atraso é de no máximo
        30 minutos.
      </SubTitle>
    </Layout>
  )
}
HeaderComprovanteAgendamento.Layout = styled.div`
  display: grid;
  margin: 0 auto;
  text-align: center;
  max-width: 900px;
  margin-top: 20px;
`
HeaderComprovanteAgendamento.Title = styled.span`
  color: #122640;
  font-size: 23px;
  margin-bottom: 20px;
  font-weight: bold;
`
HeaderComprovanteAgendamento.SubTitle = styled.span`
  color: #292926;
  padding: 0 10px;
`
const ContainerOptionsButton = ({ children }: { children: ReactNode }) => {
  const { Layout } = ContainerOptionsButton
  return <Layout>{children}</Layout>
}
ContainerOptionsButton.Layout = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 15px;
`

const CofirmAlertVistoria = () => {
  return <ConfirmAlertReprovada />
}

CofirmAlertVistoria.Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const ConfirmAlertReprovada = () => {
  const { solicitacao } = Store.useState()
  const { cliente, clinica } = solicitacao
  const {
    Layout,
    Grid,
    Title,
    SubTitleEnfatico,
    SubTitleSingelo,
    Content,
    ButtonsContainer,
    Button,
  } = ConfirmAlertReprovada

  return (
    <Layout>
      <Grid>
        <img src={iconError} />
        <Title>Vistoria reprovada!</Title>
        <SubTitleEnfatico>
          O solicitante tem 30 dias após a data de reprovação para renovar o pedido de forma
          gratuita.
        </SubTitleEnfatico>
        <SubTitleSingelo>
          Caso perca o prazo ou seja novamente reprovado, será necessário um novo pedido.
        </SubTitleSingelo>
      </Grid>
      <Content>
        <div>
          Solicitante: <strong>{cliente.nome}</strong>
        </div>
        <div>
          {cliente.cpfCnpj?.length === 14 ? 'CPF' : 'CNPJ'}: <strong>{cliente.cpfCnpj}</strong>
        </div>
        <div>
          Vistoriadora: <strong>{clinica?.nome}</strong>
          <br />
          <small>
            {clinica?.endereco} {clinica?.bairro}
          </small>
        </div>
      </Content>
      <ButtonsContainer>
        <Button>Fechar</Button>
        <Button primary>Reagendar</Button>
      </ButtonsContainer>
    </Layout>
  )
}

ConfirmAlertReprovada.Layout = styled.div`
  display: grid;
  gap: 20px;
`
ConfirmAlertReprovada.Grid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  border-bottom: 1px solid #d4d4d4;
  img {
    width: 50px;
    margin-bottom: 20px;
  }
  span {
    text-align: center;
  }
`
ConfirmAlertReprovada.Title = styled.span`
  font-size: 18px;
  color: #292926;
  font-weight: 500;
`
ConfirmAlertReprovada.SubTitleEnfatico = styled.span`
  color: #545451;
  font-size: 14px;
  font-weight: 400;
`

ConfirmAlertReprovada.SubTitleSingelo = styled.span`
  color: #545451;
  font-size: 14px;
`

ConfirmAlertReprovada.Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  font-size: 15px;
  div {
    color: #545451;
    font-weight: 600;
    font-size: 14px;
  }
  strong {
    font-weight: 400;
  }
  small {
    font-weight: 300;
  }
`

ConfirmAlertReprovada.ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`
ConfirmAlertReprovada.Button = styled(Button)`
  text-align: center;
  font-weight: 500;
`
