export const createApi = (
  fn: (params: {
    url: string
    method: 'get' | 'post' | 'put' | 'delete'
    body?
    params?
    options?
    callee?: [string, string]
  }) => any
) => {
  return {
    Solicitacoes: {
      getVistorias(extraProps?: { params?: any; options?: any }): Promise<Schemas.Solicitacao[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/solicitacoes',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Solicitacoes', 'getVistorias'],
        })
      },
      cancelaSolicitacao(
        body: Schemas.CancelaSolicitacaoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/solicitacoes',
          method: 'delete',
          body: body,
          params,
          options,
          callee: ['Solicitacoes', 'cancelaSolicitacao'],
        })
      },
      createSolicitacao(
        body: Schemas.CreateSolicitacaoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.CreateSolicitacaoResponse> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/solicitacoes',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Solicitacoes', 'createSolicitacao'],
        })
      },
      getSolicitacao(extraProps?: {
        params?: { renach: string; tipoServico: number }
        options?: any
      }): Promise<Schemas.ConsultaCondutorResponseDto> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/solicitacoes/get-solicitacao',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Solicitacoes', 'getSolicitacao'],
        })
      },
      getSolicitacoesAdm(extraProps?: {
        params?: { query: undefined; clinicaId: undefined }
        options?: any
      }): Promise<Schemas.GetVistoriasAdmResponseDto> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/solicitacoes/adm',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Solicitacoes', 'getSolicitacoesAdm'],
        })
      },
      getHorariosDisponiveis(
        pathParams: { solicitacaoId: number },
        extraProps?: { params?: { data: string }; options?: any }
      ): Promise<Schemas.GetHorariosDisponiveisResponseDto[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/solicitacoes/${pathParams.solicitacaoId}/horarios-disponiveis`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Solicitacoes', 'getHorariosDisponiveis'],
        })
      },
      getRelatorioAgendamentos(
        pathParams: { ecvId: number },
        extraProps?: { params?: { data: string }; options?: any }
      ): Promise<Schemas.Solicitacao[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/solicitacoes/relatorio/agendamento/${pathParams.ecvId}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Solicitacoes', 'getRelatorioAgendamentos'],
        })
      },
      checkPagamento(
        pathParams: { solicitacaoId: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Solicitacao> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/solicitacoes/check-pagamento/${pathParams.solicitacaoId}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Solicitacoes', 'checkPagamento'],
        })
      },
      createAgendamento(
        body: Schemas.CreateAgendamentoDto,
        pathParams: { solicitacaoId: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/solicitacoes/${pathParams.solicitacaoId}/create-agendamento`,
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Solicitacoes', 'createAgendamento'],
        })
      },
      cancelarAgendamento(
        pathParams: { vistoriaId: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/solicitacoes/${pathParams.vistoriaId}/cancelar-agendamento`,
          method: 'post',
          body: undefined,
          params,
          options,
          callee: ['Solicitacoes', 'cancelarAgendamento'],
        })
      },
      postServicosPagamentosDetran(
        body: Schemas.Solicitacao,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/solicitacoes/servicos-pagamentos-detran',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Solicitacoes', 'postServicosPagamentosDetran'],
        })
      },
      updateDataConclusao(
        pathParams: { vistoriaId: number },
        extraProps?: { params?: { type: string }; options?: any }
      ): Promise<Schemas.Solicitacao> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/solicitacoes/${pathParams.vistoriaId}/update-dataconclusao`,
          method: 'put',
          body: undefined,
          params,
          options,
          callee: ['Solicitacoes', 'updateDataConclusao'],
        })
      },
      getBoletosByContaId(extraProps?: { params?: any; options?: any }): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/solicitacoes/relatorio',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Solicitacoes', 'getBoletosByContaId'],
        })
      },
      getAgendamentoByIdControle(
        pathParams: { idSolicitacao: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<Record<string, any>> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/solicitacoes/servicos/agendamentos/${pathParams.idSolicitacao}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Solicitacoes', 'getAgendamentoByIdControle'],
        })
      },
      alteraPagamentoDetran(
        body: Schemas.AlteraPagamentoDetranDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Solicitacao> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/solicitacoes/altera-pagamento-detran',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Solicitacoes', 'alteraPagamentoDetran'],
        })
      },
      cancelaPagamentoDetran(
        body: Schemas.CancelaPagamentoDetranDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Solicitacao> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/solicitacoes/cancela-pagamento-detran',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Solicitacoes', 'cancelaPagamentoDetran'],
        })
      },
    },
    Clinicas: {
      getEcvs(extraProps?: {
        params?: { municipio: string }
        options?: any
      }): Promise<Schemas.Clinica[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clinicas',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Clinicas', 'getEcvs'],
        })
      },
      getMunicipiosDisponiveis(extraProps?: { params?: any; options?: any }): Promise<string[]> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clinicas/municipios',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Clinicas', 'getMunicipiosDisponiveis'],
        })
      },
      importEcv(extraProps?: {
        params?: { cnpj: string }
        options?: any
      }): Promise<Schemas.Clinica> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clinicas/importa',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Clinicas', 'importEcv'],
        })
      },
      getEcv(
        pathParams: { id: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Clinica> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/clinicas/${pathParams.id}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Clinicas', 'getEcv'],
        })
      },
      updateECV(
        body: Schemas.Clinica,
        pathParams: { id: number },
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Clinica> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/clinicas/update/${pathParams.id}`,
          method: 'put',
          body: body,
          params,
          options,
          callee: ['Clinicas', 'updateECV'],
        })
      },
      getEcvFoto(extraProps?: { params?: { ecvId: number }; options?: any }): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clinicas/adm/foto',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Clinicas', 'getEcvFoto'],
        })
      },
      uploadEcvFoto(
        body: Schemas.UploadEcvFotoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clinicas/adm/foto',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Clinicas', 'uploadEcvFoto'],
        })
      },
      deleleEcvFoto(
        body: Schemas.DeleleEcvFotoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clinicas/adm/foto',
          method: 'delete',
          body: body,
          params,
          options,
          callee: ['Clinicas', 'deleleEcvFoto'],
        })
      },
      getEcvCertificado(extraProps?: {
        params?: { ecvId: number }
        options?: any
      }): Promise<Record<string, any>> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clinicas/adm/certificado',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Clinicas', 'getEcvCertificado'],
        })
      },
      uploadEcvCertificado(
        body: Schemas.UploadEcvCertificadoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clinicas/adm/certificado',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Clinicas', 'uploadEcvCertificado'],
        })
      },
      deleleEcvCertificado(
        body: Schemas.DeleleEcvCertificadoDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clinicas/adm/certificado',
          method: 'delete',
          body: body,
          params,
          options,
          callee: ['Clinicas', 'deleleEcvCertificado'],
        })
      },
    },
    Clientes: {
      getOrCreateCliente(
        body: Schemas.GetOrCreateClienteDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.GetOrCreateClienteResponseDto> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/get-or-create',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Clientes', 'getOrCreateCliente'],
        })
      },
      updateCadastro(
        body: Schemas.UpdateCadastroDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.ValidaTokenDto> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes',
          method: 'put',
          body: body,
          params,
          options,
          callee: ['Clientes', 'updateCadastro'],
        })
      },
      verificaCadastroIncompleto(
        pathParams: { cpfCnpj: string },
        extraProps?: { params?: any; options?: any }
      ): Promise<boolean> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/clientes/verifica-cadastro-incompleto/${pathParams.cpfCnpj}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Clientes', 'verificaCadastroIncompleto'],
        })
      },
      finalizaCadastro(
        body: Schemas.FinalizaCadastroDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Cliente> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/finaliza-cadastro',
          method: 'put',
          body: body,
          params,
          options,
          callee: ['Clientes', 'finalizaCadastro'],
        })
      },
      updateSenha(
        body: Schemas.UpdateSenhaDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.Cliente> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/senha',
          method: 'put',
          body: body,
          params,
          options,
          callee: ['Clientes', 'updateSenha'],
        })
      },
      getIfExistCliente(
        pathParams: { cpfCnpj: string },
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.ClienteIfExistDTO> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/clientes/get-if-exist/${pathParams.cpfCnpj}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Clientes', 'getIfExistCliente'],
        })
      },
      recuperaSenha(
        body: Schemas.RecuperaSenhaDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/recupera-senha',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Clientes', 'recuperaSenha'],
        })
      },
      recuperaEmail(
        body: Schemas.RecuperaEmailDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<string> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/recupera-email',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Clientes', 'recuperaEmail'],
        })
      },
      validaToken(
        body: Schemas.ValidaTokenDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<boolean> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/valida-token',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Clientes', 'validaToken'],
        })
      },
      updateSenhaToken(
        body: Schemas.UpdateSenhaTokenDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<boolean> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/clientes/senha/token',
          method: 'put',
          body: body,
          params,
          options,
          callee: ['Clientes', 'updateSenhaToken'],
        })
      },
    },
    Auth: {
      login(
        body: Schemas.LoginDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Schemas.LoginResponseDto> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/auth/login',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Auth', 'login'],
        })
      },
    },
    Webhooks: {
      notificarMudancaStatusPagamentoRep(extraProps?: {
        params?: any
        options?: any
      }): Promise<unknown> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/webhooks/pagamento/hyperpay/{cobrancaId}',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Webhooks', 'notificarMudancaStatusPagamentoRep'],
        })
      },
    },
    Detran: {
      getAccessToken(extraProps?: { params?: any; options?: any }): Promise<string> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/detran/access-token',
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Detran', 'getAccessToken'],
        })
      },
      postServicosAgendamentos(
        body: Schemas.ServicosAgendamentosDto,
        extraProps?: { params?: any; options?: any }
      ): Promise<Record<string, any>> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: '/detran/servicos/agendamentos',
          method: 'post',
          body: body,
          params,
          options,
          callee: ['Detran', 'postServicosAgendamentos'],
        })
      },
      getAgendamentoByIdControle(
        pathParams: { idControle: number },
        extraProps?: {
          params?: {
            idControle: number
            tipoServico: number
            renach: string
            chassi?: string
            codgChaveAutorizacao?: string
          }
          options?: any
        }
      ): Promise<Record<string, any>> {
        const { params, options } = extraProps || { options: {} }
        return fn({
          url: `/detran/servicos/agendamentos/${pathParams.idControle}`,
          method: 'get',
          body: undefined,
          params,
          options,
          callee: ['Detran', 'getAgendamentoByIdControle'],
        })
      },
    },
  }
}

export namespace Schemas {
  export interface Clinica extends Model {
    nome: string
    cnpj: string
    valor: number
    municipio: string
    uf: string
    endereco: string
    bairro: string
    telefone: string
    foto: string
    certificado: string
    tipoAtendimento: 'AGENDAMENTO' | 'ORDEM_CHEGADA'
    qtdClinicas: number
    repassesAdicionais: string[]
    repassesAdicionaisExibicao: string[]
    createdBy: string
  }
  export interface Solicitacao extends Model {
    clinicaId: number
    clinica: Schemas.Clinica
    clienteId: number
    cliente: Schemas.Cliente
    cobrancaId: number
    dataVencimentoCobranca: string
    dataPagamentoCobranca: string
    dataConclusao?: string | null
    dataAgendamento?: string | null
    valorCobranca: number
    valorRepasse: number
    meioPagamento: 'CARTAO_CREDITO' | 'PIX' | 'BOLETO' | 'DINHEIRO'
    createdBy: string
    tipoExame: 'EXAME_MEDICO' | 'EXAME_PSICOLOGICO'
    renach: string
    idControle: number
  }
  export interface Cliente extends Model {
    cpfCnpj: string
    nome: string
    email: string
    telefone: string
    senha: string
    token: string
    createdBy: string
    solicitacoes: Schemas.Solicitacao[]
  }
  export interface EnderecoClinicaDto {
    cep: string
    logradouro: string
    complemento: Record<string, any>
    numero: string
    bairro: string
    municipio: string
    uf: string
  }
  export interface ConsultaCondutorResponseDto {
    msgErro: string
    nomeCandidato: string
    cpf: string
    examesNecessarios: string
    razaoClinicaMed: string
    telefoneClinicaMed: string
    razaoClinicaPsi: string
    telefoneClinicaPsi: string
    cnpjClinicaMed: string
    cnpjClinicaPsi: string
    enderecoMed: Schemas.EnderecoClinicaDto
    enderecoPsi: Schemas.EnderecoClinicaDto
    solicitacaoMedica: Schemas.Solicitacao
    solicitacaoPsi: Schemas.Solicitacao
  }
  export interface CancelaSolicitacaoDto {
    solicitacaoId: number
  }
  export interface GetVistoriasAdmResponseDto {
    total: Record<string, any>
    totalFinalizadas: number
    totalNaoRealizadas: number
    totalAguardandoPagamento: number
    solicitacoes: Schemas.Solicitacao[]
  }
  export interface GetHorariosDisponiveisResponseDto {
    inicio: string
    fim: string
  }
  export interface SolicitacaoDetranResponse {
    msgErro: Record<string, any>
    nomeCandidato: string
    cpf: string
    examesNecessarios: string
    razaoClinicaMed: string
    telefoneClinicaMed: string
    razaoClinicaPsi: string
    telefoneClinicaPsi: string
    cnpjClinicaMed: string
    cnpjClinicaPsi: string
    enderecoMed: Schemas.EnderecoClinicaDto
    enderecoPsi: Schemas.EnderecoClinicaDto
    cnpjClinicaMedico: Record<string, any>
  }
  export interface CreateSolicitacaoDto {
    solicitacaoDetran: Schemas.SolicitacaoDetranResponse
    tipoExame: 'EXAME_MEDICO' | 'EXAME_PSICOLOGICO'
    renach: string
  }
  export interface CreateSolicitacaoResponse {
    solicitacao: Schemas.Solicitacao
    cobranca: Record<string, any>
  }
  export interface CreateAgendamentoDto {
    dataAgendamento: string
  }
  export interface AlteraPagamentoDetranDto {}
  export interface CancelaPagamentoDetranDto {}
  export interface UploadEcvFotoDto {
    ecvId: number
    imageBase64: string
  }
  export interface DeleleEcvFotoDto {
    ecvId: number
  }
  export interface UploadEcvCertificadoDto {
    ecvId: number
    fileName: string
    certificadoBase64: string
  }
  export interface DeleleEcvCertificadoDto {
    ecvId: number
    fileName: string
  }
  export interface GetOrCreateClienteDto {
    cpfCnpj: string
    nome: string
    telefone?: string | null
    email?: string | null
    senha?: string | null
  }
  export interface GetOrCreateClienteResponseDto {
    token: string
    cliente: Schemas.Cliente
  }
  export interface UpdateCadastroDto {
    cpfCnpj: string
    nome: string
    telefone: string
  }
  export interface ValidaTokenDto {
    token: string
  }
  export interface FinalizaCadastroDto {
    cpfCnpj: string
    telefone?: string | null
    email: string
    senha: string
    confirmarSenha: string
  }
  export interface UpdateSenhaDto {
    cpfCnpj: string
    senhaAtual: string
    novaSenha: string
    confirmarSenha: string
  }
  export interface ClienteIfExistDTO {
    cliente: Record<string, any>
  }
  export interface RecuperaSenhaDto {
    email: string
  }
  export interface RecuperaEmailDto {
    cpfCnpj: string
  }
  export interface UpdateSenhaTokenDto {
    token: string
    senha: string
  }
  export interface LoginDto {
    cpfCnpj: string
    senha: string
  }
  export interface LoginResponseDto {
    token: string
    clinicas?: Schemas.Clinica[] | null
  }
  export interface ServicosAgendamentosDto {
    idControle: number
    tipoServico: number
    renach: string
    cnpjEmpresaTi: string
    cnpjEmpresaPrestadora: string
    dataAgendamento: string
    horaAgendamento: string
    cpfInformanteAgendamento: string
  }
}

interface Model {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt?: string
}
