import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Modal } from '../../components/Modal'
import { GlobalStore, useOpenModal } from '../../GlobalStore'
import { InputLabel } from '../../components/InputLabel'
import { Button } from '../../components/Button'
import toast from 'react-hot-toast'
import { AuthStore } from './AuthStore'
import { Show } from '../../components/Show'

export const ModalEsqueceuSenha = () => {
  const { modalOpen } = GlobalStore.useState()
  const { Layout, Heading, Subheading, Button, EsqueceuEmail, RecuperacaoEmailContainer, ButtonFechar } =
    ModalEsqueceuSenha
  const { openModal, closeModal } = useOpenModal()
  const [email, setEmail] = useState('')
  const authDispatch = AuthStore.useDispatch()
  const { loading, envioEmailSucceded } = AuthStore.useState()

  function enviaEmailRecuperacaoSenha(email: string) {
    try {
      if (!email) return toast.error('Insira um endereço de e-mail')
      authDispatch(AuthStore.thunks.recuperarSenha({ email }))
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Erro ao enviar email de recuperação de senha'
      toast.error(message)
    }
  }

  function closeModalOpenLogin() {
    authDispatch(AuthStore.actions.clearEnvioEmailSucceded())
    setEmail('')
    openModal('LOGIN')
  }

  function closeModalAndClearEnvioEmail() {
    authDispatch(AuthStore.actions.clearEnvioEmailSucceded())
    setEmail('')
    closeModal()
  }

  return (
    <Modal visible={modalOpen === 'RECUPERAR_SENHA'} width={386} onClose={() => closeModalAndClearEnvioEmail()}>
      <Layout>
        <Heading>Redefinir senha</Heading>
        <Show when={!envioEmailSucceded}>
          <Subheading>Um link para redefinição de senha será enviado para o e-mail informado abaixo!</Subheading>
          <InputLabel
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="E-mail"
            placeholder="Insira o e-mail cadastrado"
            style={{ marginTop: 24, display: 'block' }}
            autoFocus
          />
          <RecuperacaoEmailContainer>
            <EsqueceuEmail onClick={() => openModal('RECUPERAR_EMAIL')}>Esqueci meu e-mail</EsqueceuEmail>
          </RecuperacaoEmailContainer>
          <Button primary loading={loading} onClick={() => enviaEmailRecuperacaoSenha(email)} style={{ width: '100%' }}>
            Enviar
          </Button>
        </Show>
        <Show when={!!envioEmailSucceded}>
          <Subheading>
            Um link para redefinição de senha será enviado no e-mail:
            <br />
            <strong>{email}</strong>
          </Subheading>
        </Show>
        <ButtonFechar onClick={() => closeModalOpenLogin()}>Fechar</ButtonFechar>
      </Layout>
    </Modal>
  )
}
ModalEsqueceuSenha.Layout = styled.div``
ModalEsqueceuSenha.EsqueceuEmail = styled.div`
  margin-top: 4px;
  text-align: right;
  font-size: 12px;
  color: #2043a1;
  cursor: pointer;
`

ModalEsqueceuSenha.RecuperacaoEmailContainer = styled.div`
  margin-top: 8px;
  font-size: 14px;
  align-items: end;
  display: flex;
  justify-content: end;

  span {
    font-size: 14px;
  }
`

ModalEsqueceuSenha.Heading = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #122640;
  text-align: center;
`
ModalEsqueceuSenha.Subheading = styled.div`
  font-size: 14px;
  line-height: 120%;
  color: #636c74;
  text-align: center;
  margin-top: 12px;
`
ModalEsqueceuSenha.Button = styled(Button)`
  width: 100%;
  margin-top: 24px;
`
ModalEsqueceuSenha.ButtonFechar = styled(Button)`
  border: none;
  width: 100%;
  margin-top: 12px;
`
