import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { UsuarioLogado } from '../../../components/UsuarioLogado'
import { createSimpleStore } from 'react-simple-reducer'
import { AuthStore, selectClinicaAtual } from '../../auth/AuthStore'
import { api, useApi } from '../../../components/Api'
import { Schemas } from '../../../components/ApiSchemas'
import { Input } from '../../../components/Input'
import toast, { LoaderIcon } from 'react-hot-toast'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { ReactComponent as CertificateIcon } from '../../../assets/icon-certificate.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/icon-download.svg'
import { addMinutes, addHours, startOfDay, format } from 'date-fns'
import { Button } from '../../../components/Button'
import { CadastroInfoClinica } from './cadastro-clinica/CadastroInfoCliente'
import { useWindowWidth } from '../../../components/hooks/use-window-width'
import { InputLabel } from '../../../components/InputLabel'
import DatePickerComponent from '../../../components/DatePicker'
import { Show } from '../../../components/Show'
import { useParams } from 'react-router-dom'

type TAba = 'Cadastro' | 'Foto' | 'Valores' | 'Certificado Digital' | 'Agendamentos'
const Store = createSimpleStore(
  {
    abaAtiva: '' as TAba,
    loading: false,
    relatorioVistorias: [] as Schemas.Solicitacao[],
    maximoAgendamentoIntervalo: 5,
    dataAgendamento: new Date(),
  },
  {
    changeAba(state, aba: TAba) {
      state.abaAtiva = aba
    },
    changeAbaByParams(state, aba: TAba) {
      state.abaAtiva = aba
    },
    getVistoriasRelatorioStarted(state) {
      state.loading = true
    },
    getVistoriasRelatorioSuccess(state, responseRelatorioVistoria) {
      state.loading = false
      state.relatorioVistorias = responseRelatorioVistoria
    },
    getVistoriasRelatorioFail(state) {
      state.loading = false
    },
    setDataAgendamento(state, data: Date) {
      state.dataAgendamento = data
    },
  }
)

const TabController = ({ children }: any) => {
  const dispatch = Store.useDispatch()
  const currentTab = useParams().agendamentos ? 'Agendamentos' : 'Cadastro'

  const a = useParams()

  useEffect(() => {
    if (currentTab) dispatch(Store.actions.changeAbaByParams(currentTab as TAba))
  }, [a])

  return <>{children}</>
}

export const CadastroClinica = () => {
  const windowWidth = useWindowWidth()
  const { Layout, Flex, Abas, Divider } = CadastroClinica
  const { token } = AuthStore.useState()

  if (!token) return null

  return (
    <Store.Provider>
      <TabController>
        <Layout>
          <Flex>
            <UsuarioLogado aplicacao="preto" />
          </Flex>
          <Divider />
          <div>
            <Abas>
              <AbaTitulo aba="Cadastro" />
              <AbaTitulo aba="Valores" />
              <AbaTitulo aba="Foto" />
              <AbaTitulo aba="Certificado Digital" />
              <AbaTitulo aba="Agendamentos" />
            </Abas>
            <AbaConteudo aba="Cadastro">
              <Cadastro />
            </AbaConteudo>
            <AbaConteudo aba="Valores">
              <Valores />
            </AbaConteudo>
            <AbaConteudo aba="Foto">
              <Foto />
            </AbaConteudo>
            <AbaConteudo aba="Certificado Digital">
              <CertificadoDigital />
            </AbaConteudo>
            <AbaConteudo aba="Agendamentos">
              <Agendamentos />
            </AbaConteudo>
          </div>
        </Layout>
      </TabController>
    </Store.Provider>
  )
}
CadastroClinica.Layout = styled.div`
  width: 100%;
  padding: 0 40px;
  @media (max-width: 900px) {
    padding: 0 24px;
  }
`
CadastroClinica.Flex = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: flex-end;
  @media (max-width: 900px) {
    display: none;
  }
`
CadastroClinica.VerticalDivider = styled.div`
  height: 18px;
  width: 3px;
  background: #545451;
  margin-top: 2px;
`
CadastroClinica.Abas = styled.div`
  display: flex;
  margin: 20px 0px 20px 0px;
  gap: 12px;
  align-items: center;
  @media (max-width: 515px) and (min-width: 360px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  @media (max-width: 360px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 90px;
    overflow-y: auto;
  }
`
CadastroClinica.Divider = styled.div`
  border-bottom: 2px solid #d4d4d4;
  width: 100%;
  @media (max-width: 900px) {
    display: none;
  }
`

const AbaTitulo = ({ aba }: { aba: TAba }) => {
  const { Style } = AbaTitulo
  const dispatch = Store.useDispatch()
  const { abaAtiva } = Store.useState()

  return (
    <Style onClick={() => dispatch(Store.actions.changeAba(aba))} active={abaAtiva === aba}>
      {aba}
    </Style>
  )
}
AbaTitulo.Style = styled.div<{ active }>(
  ({ active }) => `
  color: ${active ? 'black' : '#aaaaa8'};
  font-weight: bold;
  cursor: pointer;
  font-size: 20px;
  @media(min-width: 516px){
    &:not(:last-child):after {
    content: ' ';
    height: 18px;
    width: 2.7px;
    background: #545451;
    margin-top: 2px;
    display: inline-block;
    margin-left: 12px;
  }
  }
  @media(max-width: 515px){
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 50px;
    border: 1px solid #2d4565;
    background-color: ${active ? '#122640' : '#FFFFFF'};
    color: ${active ? '#FFFFFF ' : '#122640'};
    box-shadow: ${active ? '0px 0px 3px #122640' : 'none'};
    padding: 4px 0px;
    transition: .3s all;
  }
`
)

const AbaConteudo = ({ aba, children }: { aba: TAba; children }) => {
  const { abaAtiva } = Store.useState()

  if (abaAtiva !== aba) return null

  return children
}

const Cadastro = () => {
  const { Layout, Titulo, CadastroClinicaContainer } = Cadastro
  return (
    <Layout>
      <Titulo>Informações de Cadastro Clínica</Titulo>
      <CadastroClinicaContainer>
        <CadastroInfoClinica />
      </CadastroClinicaContainer>
    </Layout>
  )
}
Cadastro.Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 1024px;
  max-width: 100%;
`
Cadastro.Titulo = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
`
Cadastro.CadastroClinicaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Valores = () => {
  const { formatValor, Layout, UgcContainer, Input, Flex, Button, Heading, Info } = Valores
  const clinicaAtual = AuthStore.useSelector(selectClinicaAtual)

  const [{ data: clinica }] = useApi.Clinicas.getEcv({ id: clinicaAtual?.id ?? 0 })

  if (!clinica) return null

  return (
    <Layout>
      <Heading>Vinculado à UGC</Heading>
      <Info>Para alterar entre em contato com o suporte.</Info>
      <Heading>Valor da UGC por vistoria</Heading>
      <Heading>Valor da vistoria</Heading>
      <Flex style={{ marginTop: 12 }}>
        <Input value={formatValor(clinica.valor)} disabled />
        <Button disabled>Alterar</Button>
      </Flex>
    </Layout>
  )
}

Valores.formatValor = (valor: number) => 'R$ ' + valor.toFixed(2).replace('.', ',')
Valores.Layout = styled.div`
  max-width: 600px;
  margin: 24px auto 0 auto;
`
Valores.Heading = styled.div`
  color: #545451;
  font-size: 18px;
  margin-top: 36px;
`
Valores.UgcContainer = styled.div`
  font-size: 34px;
  font-weight: 600;
  width: 100%;
  border-radius: 8px;
  border: 4px solid #ffbc01;
  padding: 24px;
  text-align: center;
  display: inline-block;
  margin-top: 12px;
`
Valores.Input = styled(Input)`
  height: 80px;
  font-size: 34px;
`
Valores.Flex = styled.div`
  display: flex;
  gap: 24px;
`
Valores.Info = styled.div`
  color: #545451;
  text-align: right;
`
Valores.Button = styled(Button)`
  height: 80px;
  width: 250px;
`

const Foto = () => {
  const { Layout, getPhoto, resizeBase64Image } = Foto
  const { clinicaIdSelecionada } = AuthStore.useState()

  const [{ data, loading, error }, loadFoto] = useApi.Clinicas.getEcvFoto({
    params: { ecvId: clinicaIdSelecionada as any },
  })

  const imageBase64 = (data as any)?.imageBase64

  useEffect(() => {
    if (!clinicaIdSelecionada) return
    void loadFoto()
  }, [clinicaIdSelecionada])

  const handleUploadPhoto = async () => {
    if (!clinicaIdSelecionada) return

    const { cancelled, success, message, image } = await getPhoto()
    if (cancelled) return
    if (!success) return toast.error(message || '')

    // upload image
    const resizedImage = await resizeBase64Image(image)
    await api.Clinicas.uploadEcvFoto({ ecvId: clinicaIdSelecionada, imageBase64: resizedImage })
    await loadFoto()
    toast.success('Foto enviada com sucesso!')
  }

  const handleDeletePhoto = async () => {
    if (!clinicaIdSelecionada) return
    await api.Clinicas.deleleEcvFoto({ ecvId: clinicaIdSelecionada })
    void loadFoto()
    toast.success('Foto excluida com sucesso!')
  }

  if (loading) {
    return (
      <div style={{ margin: '20px 0px' }}>
        <LoaderIcon
          style={{
            width: '200px',
            height: '200px',
            margin: '0px auto',
          }}
        />
      </div>
    )
  }

  return (
    <Layout>
      <Show when={!imageBase64}>
        <p style={{ color: '#868585', margin: '80px 0px', textAlign: 'center' }}>Nenhuma foto encontrada!</p>
      </Show>
      <Show when={!(loading || error) && imageBase64}>
        <img style={{ width: '100%' }} src={imageBase64} />
      </Show>
      <div
        style={{
          display: 'flex',
          gap: '20px',
          justifyContent: 'end',
          marginBottom: '100px',
        }}
      >
        <Button onClick={handleUploadPhoto}>Carregar Foto</Button>
        <Show when={imageBase64}>
          <Button
            onClick={handleDeletePhoto}
            style={{
              backgroundColor: '#f14d4d',
              color: '#FFFF',
              border: '1px red',
            }}
          >
            Excluir Foto
          </Button>
        </Show>
      </div>
    </Layout>
  )
}
Foto.Layout = styled.div``
Foto.getPhoto = async () => {
  try {
    const photo = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
    })

    return {
      success: true,
      image: photo?.base64String ?? '',
    }
  } catch (e: any) {
    if (e.message === 'User cancelled photos app') return { cancelled: true }

    return {
      success: false,
      message: 'Ocorreu um erro ao capturar a foto!',
    }
  }
}
Foto.resizeBase64Image = async (image) => {
  return await new Promise<string>((resolve) => {
    const img = document.createElement('img')

    img.onload = function () {
      const canvas = document.createElement('canvas')
      const ctx: any = canvas.getContext('2d')
      ctx.imageSmoothingEnabled = true
      ctx.imageSmoothingQuality = 'high'

      const ratio = 640 / Math.max(img.width, img.height)

      canvas.width = img.width * ratio
      canvas.height = img.height * ratio

      ctx.drawImage(this, 0, 0, canvas.width, canvas.height)

      const dataURL = canvas.toDataURL('image/jpeg', 0.7)

      resolve(dataURL.replace(/^data:([A-Za-z-+/]+);base64,/, 'data:image;base64,'))
    }

    img.src = 'data:image;base64,' + image.replace(/^data:([A-Za-z-+/]+);base64,/, '')
  })
}

const CertificadoDigital = () => {
  const { Layout, ButtonsContainer, fileToBase64 } = CertificadoDigital
  const { clinicaIdSelecionada } = AuthStore.useState()
  const inputFileRef = useRef() as any

  const [{ data, loading, error }, loadCertificado] = useApi.Clinicas.getEcvCertificado({
    params: { ecvId: clinicaIdSelecionada as any },
  })

  const certificado = data as { fileName: string; certificadoBase64: string; url: string } | null

  useEffect(() => {
    if (!clinicaIdSelecionada) return
    void loadCertificado()
  }, [clinicaIdSelecionada])

  const handleCarregarCertificado = () => {
    inputFileRef.current.click()
  }

  const handleChange = (e) => {
    void uploadCertificado(e.target.files[0])
  }

  const uploadCertificado = async (file) => {
    if (!clinicaIdSelecionada) return

    const certificadoBase64 = await fileToBase64(file)

    // upload certificado
    await api.Clinicas.uploadEcvCertificado({
      ecvId: clinicaIdSelecionada,
      fileName: file?.name,
      certificadoBase64,
    })

    await loadCertificado()
    toast.success('Certificado enviado com sucesso!')
  }

  const handleDeleteCertificado = async () => {
    if (!clinicaIdSelecionada || !certificado) return
    await api.Clinicas.deleleEcvCertificado({
      ecvId: clinicaIdSelecionada,
      fileName: certificado.fileName,
    })
    void loadCertificado()
    toast.success('Certificado excluído com sucesso!')
  }

  if (loading) {
    return (
      <div style={{ margin: '20px 0px' }}>
        <LoaderIcon style={{ width: '200px', height: '200px', margin: '0px auto' }} />
      </div>
    )
  }

  return (
    <Layout>
      <Show when={!certificado}>
        <p style={{ color: '#575757', margin: '80px 0px', textAlign: 'center' }}>Nenhum certificado encontrado!</p>
      </Show>
      <Show when={!(loading || error) && !!certificado}>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '80px 0px', gap: '10px' }}>
          <CertificateIcon width={50} height={50} />
          <p style={{ color: '#575757' }}>{certificado?.fileName}</p>
        </div>
      </Show>
      <input type="file" name="file" style={{ display: 'none' }} ref={inputFileRef} onChange={handleChange} />
      <ButtonsContainer>
        <Button onClick={handleCarregarCertificado}>Carregar Certificado</Button>
        <Show when={!!certificado}>
          <a href={certificado?.url} style={{ textDecoration: 'none' }}>
            <Button style={{ display: 'flex', gap: '10px', justifyContent: 'center', width: '100%' }}>
              <DownloadIcon style={{ cursor: 'pointer', marginTop: '-4px', fill: '#36a1d3' }} />
              Baixar Certificado
            </Button>
          </a>
          <Button
            onClick={handleDeleteCertificado}
            style={{ backgroundColor: '#f14d4d', color: '#FFFF', border: '1px red' }}
          >
            Excluir Certificado
          </Button>
        </Show>
      </ButtonsContainer>
    </Layout>
  )
}
CertificadoDigital.Layout = styled.div``
CertificadoDigital.ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: end;
  margin-bottom: 100px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
CertificadoDigital.fileToBase64 = async (file) => {
  return await new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  })
}

const Agendamentos = () => {
  const { Layout, Titulo, ContainerTable, Flex } = Agendamentos

  const ecvAtual = AuthStore.useSelector(selectClinicaAtual)
  const { dataAgendamento } = Store.useState()
  const dispatch = Store.useDispatch()

  const [{ loading }, relatorios] = useApi.Solicitacoes.getRelatorioAgendamentos(
    {
      ecvId: ecvAtual?.id as any,
    },
    {
      params: { data: dataAgendamento.toISOString() },
      options: { useCache: false, manual: true },
    }
  )

  async function handleRelatorioAgendamento() {
    try {
      if (!ecvAtual?.id) return
      dispatch(Store.actions.getVistoriasRelatorioStarted())
      const response = await relatorios().then((d) => d.data)
      dispatch(Store.actions.getVistoriasRelatorioSuccess(response))
      toast.success('Relatório gerado')
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro'
      toast.error(message)
      dispatch(Store.actions.getVistoriasRelatorioFail())
    }
  }

  return (
    <Layout>
      <Titulo>Relatório de Agendamentos</Titulo>
      <Flex>
        <DatePickerComponent
          selected={dataAgendamento}
          onChange={(e) => {
            dispatch(Store.actions.setDataAgendamento(e))
          }}
        />
        <Button primary onClick={handleRelatorioAgendamento}>
          Procurar
        </Button>
      </Flex>
      <ContainerTable>
        <Lista />
      </ContainerTable>
    </Layout>
  )
}

Agendamentos.Flex = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
`

Agendamentos.Layout = styled.div`
  display: flex;
  flex-direction: column;
`

Agendamentos.Titulo = styled.p`
  font-size: 16px;
  text-align: start;
`

Agendamentos.ContainerTable = styled.div`
  padding: 12px;
`

const Lista = () => {
  const { Table } = Lista

  const { dataAgendamento } = Store.useState()

  const horarios = Array.from(Array(20).keys()).map((i) => {
    const inicio = addMinutes(addHours(startOfDay(dataAgendamento), 8), 30 * i)
    return { inicio, fim: addMinutes(inicio, 30) }
  })

  return (
    <Table>
      {horarios.map((horario, index) => (
        <Horario key={index} horario={horario} />
      ))}
    </Table>
  )
}
Lista.Table = styled.div`
  width: 100%;
  margin-top: 20px;
  overflow: auto;
  height: 640px;
  th {
    font-size: 14px;
    font-weight: bold;
    color: #7f7f7d;
    align-self: center;
  }
  tr {
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 20px 0px;
  }
`

const Horario = ({ horario }: { horario }) => {
  const { getVistoriasHorario, Tr } = Horario
  const { relatorioVistorias, maximoAgendamentoIntervalo } = Store.useState()

  let vistoriasHorario = getVistoriasHorario(relatorioVistorias, horario)

  const vistoriasVazias = Array(Math.max(maximoAgendamentoIntervalo - vistoriasHorario.length, 0))
    .fill(null)
    .map((x) => null)
  vistoriasHorario = [...vistoriasHorario, ...vistoriasVazias]

  return (
    <Tr>
      <div>
        {format(horario.inicio, 'HH:mm')} - {format(horario.fim, 'HH:mm')}
      </div>
      {vistoriasHorario.map((vistoria, i) => (
        <td key={vistoria?.id ?? i}>
          <ListaItem solicitacao={vistoria} />
        </td>
      ))}
    </Tr>
  )
}
Horario.getVistoriasHorario = (
  relatorioVistoria: Schemas.Solicitacao[],
  horario: { inicio; fim }
): Array<Schemas.Solicitacao | null> => {
  const compareHour = (inicioDate: Date, dataAgendamento) => {
    const fimDate = addMinutes(inicioDate, 30)
    if (
      new Date(dataAgendamento).getTime() <= new Date(fimDate).getTime() &&
      new Date(inicioDate).getTime() < new Date(dataAgendamento).getTime()
    )
      return true
    return false
  }

  const newRelatorio = relatorioVistoria.filter((vistoria) => {
    return compareHour(horario.inicio, vistoria.dataAgendamento)
  })

  return newRelatorio
}
Horario.Tr = styled.tr`
  display: flex;
  align-items: center;
`

const ListaItem = ({ solicitacao }: { solicitacao: Schemas.Solicitacao | null }) => {
  const { Card, CardItemFlex, Span } = ListaItem
  if (!solicitacao) {
    return (
      <Card status={getStatus('VAZIO')}>
        <CardItemFlex status={getStatus('VAZIO')}>
          <Span className="titulo-card-item">Status</Span>
          <Span className="valor-card-item status-enfatico">{getStatus('VAZIO')}</Span>
        </CardItemFlex>
      </Card>
    )
  }
  return (
    <Card status={getStatus(solicitacao.dataConclusao)}>
      <CardItemFlex>
        <div className="card-item-grid">
          <Span className="titulo-card-datagendamento">Data Agendamento</Span>
          <Span>
            {solicitacao.dataAgendamento ? format(new Date(solicitacao.dataAgendamento), 'dd/MM/yyyy HH:mm') : ''}
          </Span>
        </div>
      </CardItemFlex>

      <CardItemFlex status={getStatus(solicitacao.dataConclusao)}>
        <Span className="titulo-card-item">Status</Span>
        <Span className="valor-card-item status-enfatico">{getStatus(solicitacao.dataConclusao)}</Span>
      </CardItemFlex>
    </Card>
  )
}

ListaItem.Card = styled.div<{ status? }>(
  ({ status }) => `
  height: ${status === 'LIVRE' ? '150px;' : ''};
  border-radius: 5px 5px 8px 8px;
  padding: 18px 12px 12px 40px;
  border-top: ${boderColorStatus(status)};
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: 0.4s all;
  box-shadow: 0px 2px 8px rgba(53, 49, 36, 0.2);
  width: 380px;
`
)

ListaItem.CardItemFlex = styled.div<{ status? }>(
  ({ status }) => `
display: flex;
align-items: center;
gap: 8px;
.titulo-card-item{
  color: #7F7F7D;
  font-weight: bold;
  font-size: 12px;
}
.valor-card-item{
  font-size: 15px;
}
.valor-card-item.enfatico{
  font-weight: bold;
}
.valor-card-item.status-enfatico{
  font-weight: bold;
  color: ${colorStatus(status)}
}
.card-item-grid{
  display: grid;
  .titulo-card-datagendamento{
    font-size: 13px;
    color: #7F7F7D;
  }
}
`
)

ListaItem.Span = styled.span``

const boderColorStatus = (status: string) => {
  if (status === 'REALIZADO') return '3px solid #022859;'
  if (status === 'NÃO REALIZADO') return '3px solid #EB2A00;'
  if (status === 'LIVRE') return '3px solid #12A8EC;'
  if (status === 'INDISPONIVEL') return '3px solid #EB8D00;'
  return '3px solid #d4d4d4;'
}

const colorStatus = (status: string) => {
  if (status === 'REALIZADO') return '#022859;'
  if (status === 'NÃO REALIZADO') return '#EB2A00;'
  if (status === 'LIVRE') return '#12A8EC;'
  if (status === 'INDISPONIVEL') return '#EB8D00;'
  return 'black;'
}

const getStatus = (dataConclusao: any) => {
  if (!dataConclusao) return 'NÃO REALIZADO'
  if (dataConclusao !== 'VAZIO') return 'REALIZADO'
  if (dataConclusao === 'VAZIO') return 'LIVRE'
}
