import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Modal } from '../../components/Modal'
import { GlobalStore, useOpenModal } from '../../GlobalStore'
import { InputLabel } from '../../components/InputLabel'
import { Button } from '../../components/Button'
import toast from 'react-hot-toast'
import { AuthStore } from './AuthStore'
import { CpfCnpjInput } from '../../components/CpfCnpjInput'
import { Show } from '../../components/Show'

export const ModalEsqueceuEmail = () => {
  const { modalOpen } = GlobalStore.useState()
  const { Layout, Heading, Subheading, Button, ButtonFechar } = ModalEsqueceuEmail
  const { openModal, closeModal } = useOpenModal()
  const [cpfCnpj, setCpfCnpj] = useState('')
  const authDispatch = AuthStore.useDispatch()
  const { loading, emailExists } = AuthStore.useState()

  function enviaEmailRecuperacaoEmail(cpfCnpj: string) {
    try {
      if (!CpfCnpjInput.isValid(cpfCnpj)) return toast.error('Informe um CPF ou CNPJ válido')
      authDispatch(AuthStore.thunks.recuperarEmail({ cpfCnpj }))
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Erro ao buscar email vinculado ao cpf/cnpj'
      toast.error(message)
    }
  }

  useEffect(() => {
    authDispatch(AuthStore.actions.clearEmail())
  }, [])

  function closeModalAndClearEmail() {
    authDispatch(AuthStore.actions.clearEmail())
    setCpfCnpj('')
    closeModal()
  }

  function closeModalOpenLogin() {
    authDispatch(AuthStore.actions.clearEmail())
    setCpfCnpj('')
    openModal('LOGIN')
  }

  return (
    <Modal visible={modalOpen === 'RECUPERAR_EMAIL'} width={386} onClose={() => closeModalAndClearEmail()}>
      <Layout>
        <Heading>Esqueci meu e-mail</Heading>
        <Show when={!emailExists}>
          <div style={{ marginTop: 24 }}>
            <CpfCnpjInput
              label="CPF/CNPJ"
              placeholder="Insira o número de CPF ou CNPJ"
              value={cpfCnpj}
              onChange={(e) => setCpfCnpj(e.target.value)}
              onBlur={undefined}
              name={'cpfCnpj'}
              autoFocus
            />
          </div>

          <Button
            primary
            loading={loading}
            onClick={() => enviaEmailRecuperacaoEmail(cpfCnpj)}
            style={{ width: '100%' }}
          >
            Enviar
          </Button>
        </Show>

        <Show when={!!emailExists}>
          <Subheading>Este CPF ou CNPJ já está cadastrado com o e-mail {emailExists}</Subheading>
        </Show>
        <ButtonFechar onClick={() => closeModalOpenLogin()}>Fechar</ButtonFechar>
      </Layout>
    </Modal>
  )
}
ModalEsqueceuEmail.Layout = styled.div``
ModalEsqueceuEmail.Heading = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  color: #122640;
  text-align: center;
`
ModalEsqueceuEmail.Subheading = styled.div`
  font-size: 14px;
  line-height: 120%;
  color: #636c74;
  text-align: center;
  margin-top: 12px;
`
ModalEsqueceuEmail.Button = styled(Button)`
  width: 100%;
  margin-top: 24px;
`
ModalEsqueceuEmail.ButtonFechar = styled(Button)`
  border: none;
  width: 100%;
  margin-top: 12px;
`
