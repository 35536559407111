import { Schemas } from '../../components/ApiSchemas'
import { createSimpleStore } from 'react-simple-reducer'
import toast from 'react-hot-toast'
import { api } from '../../components/Api'
import { createSelector } from 'reselect'
import { addMinutes, startOfHour } from 'date-fns'
import { validateCpfCnpj } from './functions'

export const NovaSolicitacaoStore = createSimpleStore(
  {
    cpfCnpj: '' as string,
    renach: '' as string,
    solicitacao: {} as Schemas.Solicitacao,
    vistoria: {} as Schemas.Solicitacao,
    cobranca: null as Schemas.CreateSolicitacaoResponse['cobranca'] | null,
    ecvs: [] as Schemas.Clinica[],
    ufAtual: 'Goiás',
    municipioAtual: 'Goiânia',
    municipiosDisponiveis: [] as string[],
    ecvIdSelecionada: null as number | null,
    loading: {
      ecvs: false,
      veiculo: false,
      municipiosDisponiveis: false,
      checkPagamento: false,
      solicitacao: false,
    },
    filtroEcvEspecifica: '',
  },
  {
    clear(state) {
      state.cpfCnpj = ''
      state.solicitacao = {} as Schemas.Solicitacao
      state.cobranca = null
      state.renach = ''
    },
    setCpfCnpj(state, cpfCnpj: string) {
      state.cpfCnpj = cpfCnpj
    },
    setRenach(state, renach: string) {
      state.renach = renach
    },
    getOrCreateClienteSuccess(state, cliente: Schemas.Cliente) {
      state.vistoria = { ...state.vistoria, cliente }
    },
    getEcvsStarted(state, municipio?) {
      state.loading = { ...state.loading, ecvs: true }
      if (municipio) state.municipioAtual = municipio
    },
    getEcvsSuccess(state, ecvs: Schemas.Clinica[]) {
      state.ecvs = ecvs
      state.loading = { ...state.loading, ecvs: false }
      state.ecvIdSelecionada = null
    },
    getEcvsError(state) {
      state.loading = { ...state.loading, ecvs: false }
    },
    getVeiculoStarted(state) {
      state.loading = { ...state.loading, veiculo: true }
    },
    getVeiculoError(state) {
      state.loading = { ...state.loading, veiculo: false }
    },
    getMunicipiosDisponiveisStarted(state) {
      state.loading = { ...state.loading, municipiosDisponiveis: true }
    },
    getMunicipiosDisponiveisSuccess(state, municipiosDisponiveis) {
      state.municipiosDisponiveis = municipiosDisponiveis
      state.loading = { ...state.loading, municipiosDisponiveis: false }
    },
    getMunicipiosDisponiveisError(state) {
      state.loading = { ...state.loading, municipiosDisponiveis: false }
    },
    selecionaEcv(state, ecvId) {
      state.ecvIdSelecionada = ecvId
    },
    setEcvToVistoria(state, clinica: Schemas.Clinica) {
      state.solicitacao = { ...state.vistoria, clinica, clinicaId: clinica.id }
    },
    setSolicitacao(state, solicitacao) {
      state.solicitacao = solicitacao
    },
    createSolicitacaoSuccess(state, payload: Schemas.CreateSolicitacaoResponse) {
      state.solicitacao = payload.solicitacao
      state.cobranca = payload.cobranca
    },
    efetuaPagamentoSuccess(state, meioPagamento) {
      state.vistoria.dataPagamentoCobranca = new Date().toISOString()
      if (state.cobranca) {
        state.cobranca = { ...state.cobranca, dataPagamento: new Date() }
        state.cobranca = { ...state.cobranca, meioPagamento: meioPagamento }
      }
    },
    changeFiltroEcvEspecifica(state, value: string) {
      state.filtroEcvEspecifica = value
    },
    efetuarAgendamentoSuccess(state, dataAgendamento: string) {
      state.solicitacao.dataAgendamento = dataAgendamento
    },
    cancelarAgendamentoSuccess(state) {
      state.solicitacao.dataAgendamento = null
    },
    checkPagamentoStarted(state) {
      state.loading = { ...state.loading, checkPagamento: true }
    },
    checkPagamentoSuccess(state, solicitacao: Schemas.Solicitacao) {
      state.solicitacao = {
        ...state.solicitacao,
        ...solicitacao,
      }
      state.loading = { ...state.loading, checkPagamento: false }
    },
    checkPagamentoError(state) {
      state.loading = { ...state.loading, checkPagamento: false }
    },
  },
  {
    thunks: {
      getEcvs({ municipio }) {
        return async (dispatch) => {
          try {
            dispatch(NovaSolicitacaoStore.actions.getEcvsStarted(municipio))
            const ecvs = await api.Clinicas.getEcvs({ params: { municipio } })
            const ecvsWithOrder = ecvs.map((ecv) => ({
              ...ecv,
              ordenacao: ~~(Math.random() * 1e4),
            }))
            dispatch(NovaSolicitacaoStore.actions.getEcvsSuccess(ecvsWithOrder))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro'
            toast.error(message)
            dispatch(NovaSolicitacaoStore.actions.getEcvsError())
          }
        }
      },
      getVeiculo({ placa, cpfCnpj }) {
        return async (dispatch) => {
          try {
            dispatch(NovaSolicitacaoStore.actions.getVeiculoStarted())
            // const veiculo = await api.Veiculos.getOrCreateVeiculo({ placa, cpfCnpj })
            // dispatch(NovaSolicitacaoStore.actions.getVeiculoSuccess(veiculo))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro'
            toast.error(message)
            dispatch(NovaSolicitacaoStore.actions.getVeiculoError())
          }
        }
      },
      getMunicipiosDisponiveis() {
        return async (dispatch) => {
          try {
            dispatch(NovaSolicitacaoStore.actions.getMunicipiosDisponiveisStarted())
            const municipiosDisponiveis = await api.Clinicas.getMunicipiosDisponiveis()
            dispatch(
              NovaSolicitacaoStore.actions.getMunicipiosDisponiveisSuccess(municipiosDisponiveis)
            )
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Ocorreu um erro'
            toast.error(message)
            dispatch(NovaSolicitacaoStore.actions.getMunicipiosDisponiveisError())
          }
        }
      },
      checkPagamento() {
        return async (dispatch, getState) => {
          try {
            const {
              solicitacao: { id: solicitacaoId },
            } = getState()
            dispatch(NovaSolicitacaoStore.actions.checkPagamentoStarted())
            const solicitacaoComPagamento = await api.Solicitacoes.checkPagamento({ solicitacaoId })
            dispatch(NovaSolicitacaoStore.actions.checkPagamentoSuccess(solicitacaoComPagamento))
          } catch (error: any) {
            const message =
              error.response?.data?.message ?? 'Ocorreu um erro ao buscar informações do pagamento'
            toast.error(message)
            dispatch(NovaSolicitacaoStore.actions.checkPagamentoError())
          }
        }
      },
    },
    options: {
      cache: {
        key: 'VISTORIA_FACIL_NOVA_VISTORIA',
        location: 'SESSIONSTORAGE',
      },
    },
  }
)

type IState = ReturnType<typeof NovaSolicitacaoStore.useState>

export const selectEcvs = createSelector(
  (s: IState) => s.ecvs,
  (s: IState) => s.filtroEcvEspecifica,
  (ecvs, filtroEcvEspecifica) => {
    const sortedEcvs = [...ecvs].sort((a: any, b: any) => (a.ordenacao > b.ordenacao ? 1 : -1))
    if (!filtroEcvEspecifica) return sortedEcvs
    return sortedEcvs.filter((e) => {
      const normalize = (str) =>
        str
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      const nome = normalize(e.nome)
      const filtro = normalize(filtroEcvEspecifica)
      return nome.includes(filtro)
    })
  }
)
