import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { HyperpayCobranca } from '@hyperdev-tech/hyperpay-cobranca'
import { darken } from 'polished'
import { Schemas } from '../../../components/ApiSchemas'
import { formatValor } from '../../../components/format-valor'
import { api } from '../../../components/Api'
import { handleClickWhatsapp, ReciboComponent } from './ReciboComponent'
import { Button } from '../../../components/Button'
import iconWhatsapp from '../../../assets/icon-whatsapp.svg'
import { Show } from '../../../components/Show'
import toast from 'react-hot-toast'
import { Modal } from '../../../components/Modal'
import { AgendamentoComponent } from './AgendamentoComponent'
import { createSimpleStore } from 'react-simple-reducer'

const Store = createSimpleStore(
  {
    isModalCancelamentoOpen: false,
    isModalAgendamentoOpen: false,
    loadingCheckPagamento: false,
    solicitacao: {} as any,
  },
  {
    checkPagamentoStarted: (state) => ({ ...state, loadingCheckPagamento: true }),
    checkPagamentoError: (state) => ({ ...state, loadingCheckPagamento: false }),
    checkPagamentoSuccess: (state, payload) => ({
      ...state,
      solicitacao: { ...state.solicitacao, ...payload },
      loadingCheckPagamento: false,
    }),
    toogleModalCancelamento: (state) => ({
      ...state,
      isModalCancelamentoOpen: !state.isModalCancelamentoOpen,
    }),
    toogleModalAgendamento: (state) => ({
      ...state,
      isModalAgendamentoOpen: !state.isModalAgendamentoOpen,
    }),
    setSolicitacao: (state, solicitacao) => ({ ...state, solicitacao }),
  },
  {
    thunks: {
      checkPagamentoSolicitacao: (id: number) => {
        return async (dispatch) => {
          try {
            dispatch(Store.actions.checkPagamentoStarted())
            const data = await api.Solicitacoes.checkPagamento({ solicitacaoId: id })
            dispatch(Store.actions.checkPagamentoSuccess(data))
          } catch (error) {
            console.log(error)
            dispatch(Store.actions.checkPagamentoError())
          }
        }
      },
    },
  }
)
export const FluxoSolicitacao = ({
  solicitacao,
  onClose,
}: {
  solicitacao: Schemas.Solicitacao | null
  onClose: () => void
}) => {
  const init = useCallback(
    (dispatch) => {
      dispatch(Store.actions.setSolicitacao(solicitacao))
    },
    [solicitacao]
  )

  return (
    <Store.Provider init={init}>
      <ContainerFluxo onClose={onClose} />
    </Store.Provider>
  )
}

type validType = 'fazerpagamento' | 'comprovantepagamento' | 'comprovanteagendamento' | 'concluido'
const ContainerFluxo = ({ onClose }: { onClose: () => void }) => {
  const {
    ContainerValor,
    ContainerEcv,
    HyperpayCobranca,
    ButtonWhatsapp,
    ButtonContainer,
    ButtonCancelar,
  } = ContainerFluxo

  const { isModalCancelamentoOpen, solicitacao, loadingCheckPagamento } = Store.useState()
  const dispatch = Store.useDispatch()
  const cobrancaEnv: any = process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV
    : process.env.NODE_ENV

  const selectTipoFluxo: validType = useMemo(() => {
    if (!solicitacao.dataPagamentoCobranca) return 'fazerpagamento'
    if (solicitacao.dataConclusao) return 'concluido'
    if (solicitacao.dataPagamentoCobranca && !solicitacao.dataAgendamento)
      return 'comprovantepagamento'
    return 'comprovanteagendamento'
  }, [solicitacao.dataAgendamento, solicitacao.dataPagamentoCobranca])

  const handleToggleModalCancelamento = () => {
    dispatch(Store.actions.toogleModalCancelamento())
  }

  const hancleCheckPagamento = () => {
    if (loadingCheckPagamento) return
    dispatch(Store.thunks.checkPagamentoSolicitacao(solicitacao.id))
  }

  return (
    <React.Fragment>
      <Show when={['fazerpagamento'].includes(selectTipoFluxo)}>
        <Heading>Seja bem vindo ao ambiente de pagamento Exame fácil</Heading>
        <Subheading>Selecione a forma de pagamento desejada!</Subheading>
        <Subheading>Cobrança {solicitacao?.cobrancaId}</Subheading>
        <ContainerValor>Valor: {formatValor(solicitacao?.valorCobranca)}</ContainerValor>
        <ContainerEcv>{solicitacao?.ecv?.nome}</ContainerEcv>
        <HyperpayCobranca
          cobrancaId={solicitacao?.cobrancaId}
          token="asdfasdf"
          env={cobrancaEnv}
          onCobrancaSuccess={hancleCheckPagamento}
        />
        <ButtonContainer>
          <Show when={!!solicitacao.cobrancaId && !solicitacao.dataPagamentoCobranca}>
            <ButtonCancelar onClick={handleToggleModalCancelamento}>
              Cancelar Solicitação
            </ButtonCancelar>
          </Show>
          <ButtonWhatsapp onClick={() => handleClickWhatsapp(solicitacao)}>
            Enviar via WhatsApp <img src={iconWhatsapp} />
          </ButtonWhatsapp>
        </ButtonContainer>
        <Subheading>
          Você tem até 30 dias a partir da data de emissão dessa solicitação para realizar o
          pagamento. Após essa data a solicitação será concluída automaticamente.
        </Subheading>
      </Show>

      <Show when={!['fazerpagamento'].includes(selectTipoFluxo)}>
        <Show when={['comprovantepagamento'].includes(selectTipoFluxo)}>
          <ComprovantePagamento selectedSolicitacao={solicitacao} onClose={onClose} />
        </Show>
        <Show when={['comprovanteagendamento'].includes(selectTipoFluxo)}>
          <ComprovanteAgendamento />
        </Show>
        <Show when={['concluido'].includes(selectTipoFluxo)}>
          <SolicitacaoConcluida />
        </Show>
      </Show>

      <Modal
        width={400}
        visible={isModalCancelamentoOpen}
        onClose={handleToggleModalCancelamento}
        style={{ padding: 24 }}
      >
        <ModalCancelamentoSolicitacao
          selectedSolicitacao={solicitacao}
          close={handleToggleModalCancelamento}
        />
      </Modal>
    </React.Fragment>
  )
}
ContainerFluxo.ButtonContainer = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  gap: 16px;
  align-items: center;
  @media print {
    display: none;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`
const Heading = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #122640;
  max-width: 490px;
  margin: 0 auto;
  margin-top: 14px;
  padding: 0 10px;
`
const Subheading = styled.div`
  color: #545451;
  font-size: 16px;
  text-align: center;
  margin-top: 14px;
`
ContainerFluxo.ContainerValor = styled.div`
  margin-top: 15px;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #2043a1;
`
ContainerFluxo.Divider = styled.div`
  border-bottom: 1px solid #d4d4d4;
  max-width: 564px;
  margin: 0 auto;
  margin-top: 24px;
`
ContainerFluxo.ContainerEcv = styled.div`
  font-size: 16px;
  color: #545451;
  text-align: center;
  font-weight: bold;
`
ContainerFluxo.HyperpayCobranca = styled(HyperpayCobranca)`
  --primary-color: #888df2;
  --primary-color-shade: ${darken(0.1, '#888DF2')};
  --titles-color: white;
  --text-color: #545451;

  font-family: 'Inter', 'Molde', sans-serif;

  .layout {
    border-radius: 8px;
  }
  .meio-pagamento-pix {
    border-radius: 8px 8px 0 0;
  }
  .meio-pagamento-cartao_credito {
    border-radius: 0 0 8px 8px;
  }
  button {
    font-weight: 600;
    height: 35px;
  }
  .heading-container {
    background: none;
    margin-top: 0;
    .heading {
      display: none;
    }
  }
  .instrucao {
    display: none;
  }
  .title {
    font-weight: 600;
  }
  .codigo-seguranca {
    width: 145px !important;
  }
  .data-expiracao {
    width: 125px !important;
  }
  .email-titular {
    width: 280px !important;
  }
  .codigo-barras {
    max-width: calc(100vw - 40px);
  }
`
ContainerFluxo.Button = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
`
ContainerFluxo.ButtonWhatsapp = styled(ContainerFluxo.Button)`
  border-color: #239c39;
  color: #239c39;
`
ContainerFluxo.ButtonCancelar = styled(ContainerFluxo.Button)`
  border-color: #0d0e0f;
  color: #0d0e0f;
`
const ComprovanteAgendamento = () => {
  const { solicitacao } = Store.useState()
  return (
    <React.Fragment>
      <Heading>Agendamento confirmado</Heading>
      <Subheading>
        A partir de agora você já pode comparecer no local escolhido para realizar sua solicitação.
        <br />
        Procure chegar no horário agendado. O tempo máximo de tolerância para atraso é de no máximo
        30 minutos.
      </Subheading>
      <ReciboComponent solicitacao={solicitacao} />
    </React.Fragment>
  )
}

const ComprovantePagamento = ({
  selectedSolicitacao,
  onClose,
}: {
  selectedSolicitacao: Schemas.Solicitacao
  onClose
}) => {
  const { ContainerButtonAgendamento, ButtonAgendar } = ComprovantePagamento
  const { isModalAgendamentoOpen, solicitacao } = Store.useState()
  const dispatch = Store.useDispatch()
  const [loading, setLoading] = useState(false)

  const handleToggleModalAgendamento = () => {
    dispatch(Store.actions.toogleModalAgendamento())
  }

  const handleAgendar = async (
    horarioSelecionadoId: number,
    horariosDisponiveis: Schemas.GetHorariosDisponiveisResponseDto[]
  ) => {
    if (loading) return
    if (horarioSelecionadoId === null) return toast.error('Selecione um horário!')
    const dataAgendamento: any = horariosDisponiveis[horarioSelecionadoId].inicio

    try {
      setLoading(true)
      await api.Solicitacoes.createAgendamento(
        { dataAgendamento },
        { solicitacaoId: selectedSolicitacao.id }
      )
      toast.success('Agendamento efetuado com sucesso!')
      dispatch(Store.actions.setSolicitacao({ ...solicitacao, dataAgendamento }))
      handleToggleModalAgendamento()
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response?.data?.message ?? 'Erro ao criar agendamento!')
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <Heading>Pagamento confirmado</Heading>
      <Subheading>
        A partir de agora você já pode agendar o horário no local escolhido para realizar sua
        vistoria quando quiser.
      </Subheading>
      <Show when={!selectedSolicitacao.dataAgendamento && !selectedSolicitacao.dataConclusao}>
        <ContainerButtonAgendamento>
          <ButtonAgendar onClick={handleToggleModalAgendamento}>Agendar &gt;</ButtonAgendar>
        </ContainerButtonAgendamento>
      </Show>
      <Modal width={1300} visible={isModalAgendamentoOpen} onClose={handleToggleModalAgendamento}>
        <AgendamentoComponent solicitacao={selectedSolicitacao} handleAgendar={handleAgendar} />
      </Modal>
      <ReciboComponent solicitacao={solicitacao} />
    </React.Fragment>
  )
}
ComprovantePagamento.ButtonAgendar = styled(Button)(
  ({ disabled }) => `
  background-color: #FFBC01;
  color: #292926;
  border: ${disabled ? 'none' : '1px solid #FFBC01'};
  width: 201px;
  height: 44px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
    
`
)
ComprovantePagamento.ContainerButtonAgendamento = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  grid-gap: 20px;
  @media print {
    display: none;
  }
`
const ModalCancelamentoSolicitacao = ({ selectedSolicitacao, close }) => {
  const { Layout, SubHeading, Button, ButtonContainer } = ModalCancelamentoSolicitacao
  const [loading, setLoading] = useState(false)

  async function handleCancelarSolicitacao() {
    try {
      setLoading(true)
      const response: any = await api.Solicitacoes.cancelaSolicitacao({
        solicitacaoId: selectedSolicitacao.id,
      })
      if (response.success) {
        toast.success('Solicitação cancelada')
        close()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return toast.error('Falha ao cancelar solicitação')
    }
  }

  return (
    <Layout>
      <SubHeading>Tem certeza que deseja cancelar esta solicitação?</SubHeading>
      <ButtonContainer>
        <Button primary loading={loading} onClick={handleCancelarSolicitacao}>
          Sim
        </Button>
        <Button primary onClick={close}>
          Não
        </Button>
      </ButtonContainer>
    </Layout>
  )
}
ModalCancelamentoSolicitacao.Layout = styled.div`
  width: 100%;
  color: #545451;
`
ModalCancelamentoSolicitacao.Heading = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #122640;
  text-align: center;
`
ModalCancelamentoSolicitacao.SubHeading = styled.div`
  font-size: 16px;
  color: #122640;
  text-align: center;
  margin-top: 16px;
`
ModalCancelamentoSolicitacao.Button = styled(Button)`
  width: 40%;
  margin-top: 24px;
  height: 36px;
  font-size: 15px;
  text-align: center;
`
ModalCancelamentoSolicitacao.ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const SolicitacaoConcluida = () => {
  const { solicitacao } = Store.useState()
  return (
    <React.Fragment>
      <Heading>Vistoria concluída</Heading>
      <ReciboComponent solicitacao={solicitacao} />
    </React.Fragment>
  )
}
