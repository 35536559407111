import { format } from 'date-fns'
import { TValidarSenha } from './dadosDto'
export const removeCaracter = text => {
  if (!text) return

  return text.replace(/[^0-9]/g, '')
}

const contemQtdMinCaracter = senha => {
  if (!senha) return false

  return senha.length > 7
}

const contemNumeros = text => {
  if (!text) return false

  const regex = /[0-9]/
  return regex.test(text)
}

const contemPalavrasMaiusculas = text => {
  if (!text) return false

  const regex = /[A-Z]/
  return regex.test(text)
}

export const validaTelefone = (telefone: string) => {
  if (!telefone) return false

  const regex = /^\\([0-9]{2}\\)((3[0-9]{3}-[0-9]{4})|(9[0-9]{3}-[0-9]{5}))$/
  return regex.test(telefone)
}

export const validarSenha = (senha: string): TValidarSenha => {
  return {
    valid: senha &&
    contemQtdMinCaracter(senha),
    contemQtdMinCaracter: contemQtdMinCaracter(senha),
  }
}
