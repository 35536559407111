import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Modal } from '../../components/Modal'
import { GlobalStore, useOpenModal } from '../../GlobalStore'
import { InputLabel } from '../../components/InputLabel'
import { Button } from '../../components/Button'
import { DadosSolicitante } from '../home/DadosSolicitante'
import { Schemas } from '../../components/ApiSchemas'
import { AuthStore } from '../auth/AuthStore'
import toast from 'react-hot-toast'

export const ModalCadastro = () => {
  const { modalOpen } = GlobalStore.useState()
  const { Layout, Title, Subtitle, Divider, Heading } = ModalCadastro
  const authDispatch = AuthStore.useDispatch()
  const { openModal, closeModal } = useOpenModal()

  function handleSuccessCadastroSolicitante(response: Schemas.GetOrCreateClienteResponseDto) {
    authDispatch(AuthStore.actions.loginSuccess({ token: response.token }))
    toast.success('Cadastro efetuado com sucesso!')
    closeModal()
  }

  return (
    <Modal
      data-cy="modal-cadastro"
      visible={modalOpen === 'CADASTRO'}
      width={696}
      onClose={() => openModal('LOGIN')}
    >
      <Layout>
        <Title>Bem vindo ao Clinica Fácil</Title>
        <Subtitle>Crie sua conta de modo rápido e fácil</Subtitle>
        <Divider />
        <Heading>Dados cadastrais</Heading>
        <DadosSolicitante
          fechar={() => openModal('LOGIN')}
          onSuccess={handleSuccessCadastroSolicitante}
        />
      </Layout>
    </Modal>
  )
}
ModalCadastro.Layout = styled.div``
ModalCadastro.Title = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #122640;
  text-align: center;
`
ModalCadastro.Subtitle = styled.div`
  font-size: 14px;
  line-height: 120%;
  color: #636c74;
  text-align: center;
  margin-top: 12px;
`
ModalCadastro.Divider = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #d4d4d4;
`
ModalCadastro.Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 120%;
  margin-top: 24px;
  color: #545451;
`
ModalCadastro.ButtonFechar = styled(Button)`
  border: none;
`
