import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Route, Routes, useMatch, useNavigate, useResolvedPath } from 'react-router'
import { Solicitacoes } from './solicitacoes/Solicitacoes'
import { CadastroCliente } from './cadastro/CadastroCliente'
import logo from '../../assets/logo-clinica-facil-branco.svg'
import { Button } from '../../components/Button'
import { AuthStore, getUsuarioLogado } from '../auth/AuthStore'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { ReactComponent as IconHamburguer } from '../../assets/icon-hamburguer.svg'
import { ReactComponent as IconLogo } from '../../assets/icon-logo.svg'
import { ReactComponent as SolicitacoesIcon } from '../../assets/solicitacoes-icon.svg'
import { ReactComponent as EngrenagemIcon } from '../../assets/engrenagem-icon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/calendar-icon.svg'
import { UsuarioLogado } from '../../components/UsuarioLogado'
import { useWindowWidth } from '../../components/hooks/use-window-width'
import { Show } from '../../components/Show'
import Select from 'react-select'
import { CadastroClinica } from './cadastro/CadastroClinica'

export const Adm = () => {
  const { Layout, Menu, Logo, Container, Button, Flex, MenuResponsive, UsuarioLogado } = Adm
  const { token } = AuthStore.useState()
  const navigate = useNavigate()
  const windowWidth = useWindowWidth()
  const [hideMenu, setHideMenu] = useState(false)
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const isUsuarioEcv = ('' + usuarioLogado?.id).startsWith('hyperpay')

  useEffect(() => {
    if (!token) {
      navigate('/')
      // toast('Somente usuários logados podem visualizar esta tela')
    }
  }, [token])

  useEffect(() => {
    if (windowWidth <= 900) setHideMenu(true)
    if (windowWidth > 900) setHideMenu(false)
  }, [windowWidth])

  function closeMenuIfNeeded() {
    if (!hideMenu && (windowWidth || 0) <= 900) setHideMenu(true)
  }

  return (
    <div>
      <Show when={hideMenu}>
        <MenuResponsive>
          <IconHamburguer onClick={() => setHideMenu(false)} className="adm-icon-hamburguer" />
          <IconLogo className="adm-icon-logo" />
          <div style={{ flex: 1 }}></div>
          <UsuarioLogado aplicacao="preto" />
        </MenuResponsive>
      </Show>
      <Layout>
        <Show when={!hideMenu}>
          <Menu>
            <Flex>
              <Logo src={logo} />
              <IconHamburguer onClick={() => setHideMenu(true)} className="adm-icon-hamburguer" />
            </Flex>

            <SelectEcv />

            <MenuItem to="" onClick={closeMenuIfNeeded}>
              <SolicitacoesIcon className="icon" />
              <span>Solicitações</span>
            </MenuItem>
            <Show when={!!usuarioLogado?.isAdmin}>
              <MenuItem to="cadastro/agendamentos" onClick={closeMenuIfNeeded}>
                <CalendarIcon className="icon" />
                <span>Agendamentos</span>
              </MenuItem>
            </Show>
            <MenuItem to="cadastro" onClick={closeMenuIfNeeded}>
              <EngrenagemIcon className="icon" />
              <span>Cadastro</span>
            </MenuItem>
            <Button primary onClick={() => navigate('/')}>
              Nova solicitação &gt;
            </Button>
          </Menu>
        </Show>
        <Container onClick={closeMenuIfNeeded}>
          <Routes>
            <Route index element={<Solicitacoes />} />
            <Route path="/cadastro" element={isUsuarioEcv ? <CadastroClinica /> : <CadastroCliente />} />
            <Route path="/cadastro/:agendamentos" element={isUsuarioEcv ? <CadastroClinica /> : <CadastroCliente />} />
          </Routes>
        </Container>
      </Layout>
    </div>
  )
}
Adm.Layout = styled.div`
  display: flex;
`
Adm.Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 48px 24px;
`
Adm.Menu = styled.div`
  background: #122640;
  width: 252px;
  height: 100vh;
  z-index: 1;
  .adm-icon-hamburguer {
    cursor: pointer;
  }
  @media (max-width: 900px) {
    position: absolute;
  }

  .icon {
    margin-right: 7px;
  }
`
Adm.MenuResponsive = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
  height: 48px;
  border-bottom: 1px solid #d4d4d4;
  .adm-icon-logo {
    width: 24px;
  }
  .adm-icon-hamburguer {
    filter: invert(1);
    cursor: pointer;
  }
`
Adm.Logo = styled.img`
  width: 95px;
`
Adm.Container = styled.div`
  flex: 1;
  height: 100vh;
  overflow: auto;
`
Adm.Button = styled(Button)`
  width: 184px;
  margin: 145px auto 0 auto;
  display: block;
`
Adm.UsuarioLogado = styled(UsuarioLogado)`
  z-index: 1;
  @media (min-width: 901px) {
    display: none;
  }
`

const MenuItem = ({ to, ...props }) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })
  const { Layout } = MenuItem
  return <Layout to={to} active={match} {...props} />
}
MenuItem.Layout = styled(Link)<{ active }>(
  ({ active }) => `
  color: white;
  padding: 18px 18px 18px 27px;
  cursor: pointer;
  background: #122640;
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    filter: brightness(1.1);
  }
  ${active ? 'background: #545451;' : ''}
  ${active ? 'border-left:4px solid #fff;' : ''}
  ${active ? 'padding: 18px 18px 18px 23px;' : ''}

  .icon {
    path {
      ${active ? 'fill: #ffffff;' : '#AAAAA8 '}
    } 
  } 
  
`
)

const SelectEcv = () => {
  const { Layout } = SelectEcv
  const dispatch = AuthStore.useDispatch()
  const { clinicaIdSelecionada, clinicas } = AuthStore.useState()

  if (!clinicas?.length) return null

  const clinicaCopy = [...clinicas]

  const options = [] as any

  clinicaCopy.forEach((clinica) => options.push({ value: clinica.id, label: `${clinica.nome} - ${clinica.municipio}` }))

  function changeInput(option) {
    dispatch(AuthStore.actions.changeEcv(option.value))
  }

  const clinicaSelecionada: any = clinicas.filter((ecv: any) => ecv.id === clinicaIdSelecionada)

  const styles = {
    control: (base) => ({
      ...base,
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      fontSize: '14px',
    }),
  }

  return (
    <Layout>
      <Select
        styles={styles}
        options={options}
        onChange={changeInput}
        value={{
          value: `${clinicaSelecionada[0].id}`,
          label: `${clinicaSelecionada[0].nome} - ${clinicaSelecionada[0].municipio} `,
        }}
      />
    </Layout>
  )
}
SelectEcv.Layout = styled.div`
  padding: 8px 8px 24px 8px;
`
