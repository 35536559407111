import styled from '@emotion/styled'

export const Title = styled.span`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.3;
  @media (max-width: 768px) {
    font-size: 25px;
  }
`
export const SubTitle = styled.span`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`
export const Paragraph = styled.span`
  font-size: 14px;
  @media (max-width: 1124px) {
    font-size: 12.5px;
  }
`
