import { isValid as validateCpf } from '@fnando/cpf'
import { isValid as validateCnpj } from '@fnando/cnpj'
import iconCheck from '../../assets/icon-check.svg'
import iconError from '../../assets/icon-solicitacoes-nao-realizadas.svg'

interface IValidRegexType {
  value: string
  setErrors?: React.Dispatch<React.SetStateAction<string[]>>
  existeUsuario?: boolean
}

export const validateNomeRegex = ({ value, setErrors, existeUsuario }: IValidRegexType) => {
  const validateNomeRegex = existeUsuario ? /[a-z A-Z À-ú]{2,}/ : /[a-z A-Z À-ú]{2,}( ).+[a-z A-Z À-ú]{1,}/
  if (value && !validateNomeRegex.test(value)) {
    return setErrors ? setErrors([`${value} não é um nome válido`]) : false
  }
  return setErrors ? setErrors([]) : true
}

export const validateEmailRegex = ({ value, setErrors }: IValidRegexType) => {
  const validEmailRegex = /\S+@\S+\.\S+/
  if (value && !validEmailRegex.test(value)) {
    return setErrors ? setErrors([`${value} não é um email válido`]) : false
  }
  return setErrors ? setErrors([]) : true
}

export const validateCapitalize = (nome: string) => {
  nome = nome.toLowerCase().replace(/(?:^|\s)\S/g, capitalize => { return capitalize.toUpperCase() })

  const PreposM = ['Da', 'Do', 'Das', 'Dos', 'A', 'E']
  const prepos = ['da', 'do', 'das', 'dos', 'a', 'e']

  for (let i = PreposM.length - 1; i >= 0; i--) {
    nome = nome.replace(RegExp('\\b' + PreposM[i].replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&') + '\\b', 'g'), prepos[i])
  }

  return nome
}

export const validateCpfCnpj = (cpfCnpj) => cpfCnpj.length === 18 ? validateCnpj(cpfCnpj) : cpfCnpj.length === 14 ? validateCpf(cpfCnpj) : false

export const selectSvg = (value: any, errors: string[]) => {
  return value && !errors.length ? iconCheck : value && errors.length ? iconError : null
}

export const partialHideEmail = (email: string) => {
  if (!email) return ''
  return email.replace(/(\w\w)(\w+)@(\w.+)/, (a, b, c, d, e, f) => `${b}${c.replace(/./g, '*')}@${d}`)
}

export const partialHideTelefone = (telefone: string) => {
  if (!telefone) return ''
  return telefone.replace(/()(\w+)-(\w.+)/, (a, b, c, d, e, f) => `${b}${c.replace(/./g, '_')}-${d}`)
}

export const partialHideNome = (nome: string) => {
  if (!nome) return ''
  const [firstName] = nome.split(' ')
  return firstName
}
