import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import ReactDOM from 'react-dom'
import { Modal } from '../../components/Modal'
import { GlobalStore, modalType, useOpenModal } from '../../GlobalStore'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useApi } from '../../components/Api'
import { Button } from '../../components/Button'
import { CpfCnpjInput } from '../../components/CpfCnpjInput'
import { InputSenha } from '../../components/InputSenha'
import { AuthStore } from './AuthStore'
import { NovaSolicitacaoStore as Store } from '../../domains/home/NovaSolicitacaoStore'
import iconWarning from '../../assets/warning-icon.png'
import axios from 'axios'
import { Show } from '../../components/Show'

export const ModalLogin = () => {
  const {
    Layout,
    Heading,
    SubHeading,
    EsqueceuSenha,
    RecuperacaoSenhaContainer,
    Button,
    NovoCadastro,
    CadastreAgora,
  } = ModalLogin
  const { modalOpen } = GlobalStore.useState()
  const authDispatch = AuthStore.useDispatch()
  const navigate = useNavigate()
  const { openModal, closeModal } = useOpenModal()

  const [cpfCnpj, setCpfCnpj] = useState('')
  const [senha, setSenha] = useState('')

  const initialValuesErrorLogin = {
    type: '',
    messageError: '',
  }
  const [errorLogin, setErrorLogin] = useState<typeof initialValuesErrorLogin>()
  const [{ loading }, reqLogin] = useApi.Auth.login(
    { cpfCnpj, senha },
    { options: { manual: true } }
  )

  async function preencheCpfCnpj(value) {
    setCpfCnpj(value)
    setErrorLogin(initialValuesErrorLogin)
  }

  async function executeLogin() {
    if (!CpfCnpjInput.isValid(cpfCnpj)) return toast.error('Informe um CPF ou CNPJ válido')
    if (senha.length < 6) return toast.error('Informe uma senha válida')
    try {
      const response = await reqLogin().then((x) => x.data)
      authDispatch(
        AuthStore.actions.loginSuccess({ token: response.token, clinicas: response.clinicas })
      )
      setCpfCnpj('')
      setSenha('')
      navigate('/adm')
      closeModal()
    } catch (error: any) {
      const message = error?.response?.data
      if (!message) return toast.error('Error ao efeturar login')
      const [type, messageError] = message
      setErrorLogin({ type, messageError })
    }
  }

  useEffect(() => {
    if (modalOpen !== 'LOGIN') setErrorLogin(initialValuesErrorLogin)
  }, [modalOpen])

  return (
    <Modal
      visible={modalOpen === 'LOGIN'}
      onClose={() => openModal('')}
      width={430}
      data-cy="modal-login-cadastro"
    >
      <Layout>
        <Heading>Bem vindo ao Exame Fácil</Heading>
        <SubHeading>Insira os dados para realizar login</SubHeading>
        <Show when={!!errorLogin?.type}>
          <NewError errorLogin={errorLogin} />
        </Show>
        <div style={{ marginTop: 24 }}>
          <CpfCnpjInput
            label="CPF/CNPJ"
            placeholder="Insira o número de CPF ou CNPJ"
            value={cpfCnpj}
            onChange={async (e) => await preencheCpfCnpj(e.target.value)}
            onBlur={undefined}
            name={'cpfCnpj'}
            autoFocus
            data-cy="input-modal-login-cpfcnpj"
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <InputSenha
            label="Senha"
            placeholder="Digite sua senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            errors={senha && senha.length < 6 ? ['A senha deve ter no mínimo 6 caractéres'] : []}
            onKeyPress={(e) => {
              if (e.key === 'Enter') void executeLogin()
            }}
            data-cy="input-modal-login-senha"
          />
        </div>
        <RecuperacaoSenhaContainer>
          <EsqueceuSenha onClick={() => openModal('RECUPERAR_SENHA')}>
            {' '}
            Esqueci minha senha{' '}
          </EsqueceuSenha>
        </RecuperacaoSenhaContainer>
        <Button data-cy="btn-modal-login" primary loading={loading} onClick={executeLogin}>
          Login
        </Button>
        <NovoCadastro>
          Não possui cadastro?
          <br />
          <CadastreAgora data-cy="btn-cadastre-agora" onClick={() => openModal('CADASTRO')}>
            Cadastre agora!
          </CadastreAgora>
        </NovoCadastro>
      </Layout>
    </Modal>
  )
}
ModalLogin.Layout = styled.div`
  width: 100%;
  color: #545451;
`
ModalLogin.Heading = styled.div`
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  color: #122640;
  text-align: center;
`
ModalLogin.SubHeading = styled.div`
  font-size: 16px;
  color: #122640;
  text-align: center;
  margin-top: 16px;
`
ModalLogin.EsqueceuSenha = styled.div`
  margin-top: 4px;
  text-align: right;
  font-size: 12px;
  color: #2043a1;
  cursor: pointer;
`
ModalLogin.EsqueceuEmail = styled.div`
  margin-top: 4px;
  text-align: right;
  font-size: 12px;
  color: #2043a1;
  cursor: pointer;
`
ModalLogin.Button = styled(Button)`
  width: 100%;
  margin-top: 24px;
  height: 44px;
  font-size: 20px;
`
ModalLogin.NovoCadastro = styled.div`
  margin-top: 24px;
  color: #aaaaa8;
  font-size: 14px;
  text-align: center;
`
ModalLogin.CadastreAgora = styled.div`
  font-size: 16px;
  color: #2043a1;
  font-weight: bold;
  cursor: pointer;
`

ModalLogin.RecuperacaoSenhaContainer = styled.div`
  margin-top: 8px;
  font-size: 14px;
  align-items: end;
  display: flex;
  justify-content: end;

  span {
    font-size: 14px;
  }
`

const NewError = ({ errorLogin, ...props }) => {
  const { Container, Flex, Warning, Grid } = NewError
  const { openModal } = useOpenModal()

  return (
    <Container data-cy="info-error-login">
      <Flex>
        <Warning src={iconWarning} />
        <Show when={errorLogin.type === 'semcadastro'}>
          <Grid data-cy="info-error-login-cadastro">
            <span>{errorLogin.messageError}</span>
            <span>
              Não tem conta?&nbsp;
              <span data-cy="info-error-login-cadastrar" onClick={() => openModal('CADASTRO')}>
                Cadastre-se
              </span>
            </span>
          </Grid>
        </Show>
        <Show when={errorLogin.type === 'senhaincorreta'}>
          <Grid data-cy="info-error-login-senha">
            <span>{errorLogin.messageError}</span>
            <span>
              Esqueceu sua senha?&nbsp;
              <span onClick={() => openModal('RECUPERAR_SENHA')}>Crie uma nova senha</span>
            </span>
          </Grid>
        </Show>
      </Flex>
    </Container>
  )
}

NewError.Container = styled.div`
  padding: 15px 5px;
  background-color: #df2935;
  border-radius: 3px;
  box-sizing: border-box;
  margin-top: 20px;
  transition: 0.55s all;
`

NewError.Flex = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: space-around;
`
NewError.Warning = styled.img`
  width: 30px;
`

NewError.Grid = styled.div`
  display: grid;
  gap: 3px;
  span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
  }
  span:last-child {
    font-size: 13px;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`
