import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { NovaSolicitacaoStore as Store } from './NovaSolicitacaoStore'
import { useNavigate, Link } from 'react-router-dom'
import { HyperpayCobranca } from '@hyperdev-tech/hyperpay-cobranca'
import logo from '../../assets/logo-clinica-facil-roxo-fundo-branco.svg'
import { darken } from 'polished'
import { formatValor } from '../../components/format-valor'
import { UsuarioLogado } from '../../components/UsuarioLogado'
import { NovaVistoriaContainer } from './nova-vistoria/NovaVistoriaContainer'
import { AuthStore } from '../auth/AuthStore'
import { ComprovantePagamento } from './ComprovantePagamento'
import { Button } from '../../components/Button'
import { handleClickWhatsapp } from './components/ReciboComponent'
import iconWhatsapp from '../../assets/icon-whatsapp.svg'
import { Show } from '../../components/Show'
import toast from 'react-hot-toast'
import { api } from '../../components/Api'
import { Modal } from '../../components/Modal'

export const Pagamento = () => {
  const { token } = AuthStore.useState()
  const { solicitacao } = Store.useState()
  const navigate = useNavigate()
  // useEffect(() => { if (!token) navigate('/') }, [token])

  useEffect(() => {
    if (!solicitacao?.cobrancaId) navigate('/escolha-ecv')
    if (solicitacao?.dataPagamentoCobranca) navigate('/agendamento')
  }, [solicitacao])

  const conditionPagamento = !!solicitacao?.dataPagamentoCobranca

  return (
    <NovaVistoriaContainer passoAtual={4}>
      {conditionPagamento ? <ComprovantePagamento /> : <PagamentoContainer />}
    </NovaVistoriaContainer>
  )
}

const PagamentoContainer = () => {
  const {
    getCobrancaEnv,
    ContainerValor,
    Divider,
    ContainerEcv,
    HyperpayCobranca,
    Heading,
    Subheading,
    ButtonWhatsapp,
    ButtonContainer,
    ButtonSideBar,
    ModalContainer,
  } = PagamentoContainer
  const dispatch = Store.useDispatch()
  const cobrancaEnv = getCobrancaEnv()

  const { solicitacao } = Store.useState()
  const [isModalVisible, setIsModalVisible] = useState(false)

  function handleCobrancaSuccess() {
    dispatch(Store.thunks.checkPagamento())
  }

  return (
    <>
      <Heading>Seja bem vindo ao ambiente de pagamento Exame Fácil</Heading>
      <Subheading>Selecione a forma de pagamento desejada!</Subheading>
      <Divider />
      <Subheading>Cobrança {solicitacao?.cobrancaId}</Subheading>

      <ContainerValor>Valor: {formatValor(solicitacao?.valorCobranca)}</ContainerValor>
      <ContainerEcv>{solicitacao?.clinica?.nome}</ContainerEcv>
      <HyperpayCobranca
        cobrancaId={solicitacao?.cobrancaId}
        token="asdfasdf"
        env={cobrancaEnv}
        onCobrancaSuccess={handleCobrancaSuccess}
      />
      <ButtonWhatsapp onClick={() => handleClickWhatsapp(solicitacao)}>
        Enviar via WhatsApp <img src={iconWhatsapp} />
      </ButtonWhatsapp>
      <Show when={!!solicitacao.cobrancaId && !solicitacao.dataPagamentoCobranca}>
        <ButtonContainer>
          <ButtonSideBar onClick={() => setIsModalVisible(true)}>
            Cancelar solicitação
          </ButtonSideBar>
          <Link style={{ textDecoration: 'none' }} to={'/'}>
            <ButtonSideBar>Voltar à tela Inicial</ButtonSideBar>
          </Link>
        </ButtonContainer>
      </Show>

      <ModalContainer
        width={400}
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        style={{ padding: 24, marginTop: '600px', width: '100%' }}
      >
        <ModalCancelamentoSolicitacao close={() => setIsModalVisible(false)} />
      </ModalContainer>
    </>
  )
}

PagamentoContainer.ButtonContainer = styled.div`
  width: 100%;
  justify-content: space-around;
  display: none;

  @media (max-width: 600px) {
    display: grid;
    margin: auto;
    align-items: center;
  }
`
PagamentoContainer.ModalContainer = styled(Modal)`
  display: none;

  @media (max-width: 600px) {
    display: grid;
  }
`

PagamentoContainer.ButtonSideBar = styled.div`
  border: 1px solid;
  border-radius: 8px;
  height: 35px;
  width: 184px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  color: #0d0e0f;
  font-weight: bold;
  font-size: 13px;
  place-content: center;
  cursor: pointer;
  &:hover {
    background: #0d0e0f;
    color: #fff;
  }
  &:last-child {
    margin-top: 10px;
  }
`

PagamentoContainer.getCobrancaEnv = (): any => {
  if (process.env.REACT_APP_ENV) return process.env.REACT_APP_ENV
  return process.env.NODE_ENV
}
PagamentoContainer.Layout = styled.div``
PagamentoContainer.Topo = styled.div`
  height: 102px;
  border-bottom: 1px solid #d4d4d4;
`
PagamentoContainer.Logo = styled.img`
  margin: 18px 0 18px 120px;
`
PagamentoContainer.Grid = styled.div`
  display: grid;
  height: calc(100vh - 102px);
  grid-template-columns: 276px 1fr;
`
PagamentoContainer.ContainerPassoAPasso = styled.div`
  padding: 24px;
  overflow: auto;
`
PagamentoContainer.ContainerPagamento = styled.div`
  border-left: 1px solid #d4d4d4;
`
PagamentoContainer.Heading = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #122640;
  max-width: 490px;
  margin: 0 auto;
  margin-top: 36px;
  padding: 0 10px;
`
PagamentoContainer.Subheading = styled.div`
  color: #545451;
  font-size: 16px;
  text-align: center;
  margin-top: 24px;
`
PagamentoContainer.ContainerValor = styled.div`
  margin-top: 32px;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #2043a1;
`
PagamentoContainer.Divider = styled.div`
  border-bottom: 1px solid #d4d4d4;
  max-width: 564px;
  margin: 0 auto;
  margin-top: 24px;
`
PagamentoContainer.ContainerEcv = styled.div`
  font-size: 16px;
  color: #545451;
  text-align: center;
  font-weight: bold;
`
PagamentoContainer.HyperpayCobranca = styled(HyperpayCobranca)`
  --primary-color: #888df2;
  --primary-color-shade: ${darken(0.1, '#888DF2')};
  --titles-color: white;
  --text-color: #545451;

  font-family: 'Inter', 'Molde', sans-serif;

  .layout {
    border-radius: 8px;
  }
  .meio-pagamento-pix {
    border-radius: 8px 8px 0 0;
  }
  .meio-pagamento-cartao_credito {
    border-radius: 0 0 8px 8px;
  }
  button {
    font-weight: 600;
    height: 35px;
  }
  .heading-container {
    background: none;
    margin-top: 0;
    .heading {
      display: none;
    }
  }
  .instrucao {
    display: none;
  }
  .title {
    font-weight: 600;
  }
  .codigo-seguranca {
    width: 145px !important;
  }
  .data-expiracao {
    width: 125px !important;
  }
  .email-titular {
    width: 280px !important;
  }
  .codigo-barras {
    max-width: calc(100vw - 40px);
  }
`

PagamentoContainer.Button = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
`
PagamentoContainer.ButtonWhatsapp = styled(PagamentoContainer.Button)`
  border-color: #239c39;
  color: #239c39;
  margin: auto;
  margin-bottom: 10px;
`

const ModalCancelamentoSolicitacao = ({ close }) => {
  const { Layout, Heading, SubHeading, Button, ButtonContainer } = ModalCancelamentoSolicitacao
  const { solicitacao } = Store.useState()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  async function handleCancelarSolicitacao() {
    try {
      setLoading(true)
      const response: any = await api.Solicitacoes.cancelaSolicitacao({
        solicitacaoId: solicitacao.id,
      })
      if (response.success) {
        navigate('/adm')
        return toast.success('Solicitação cancelada')
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return toast.error('Falha ao cancelar solicitação')
    }
  }

  return (
    <Layout>
      <SubHeading>Tem certeza que deseja cancelar esta solicitação?</SubHeading>
      <ButtonContainer>
        <Button primary loading={loading} onClick={handleCancelarSolicitacao}>
          Sim
        </Button>
        <Button primary onClick={close}>
          Não
        </Button>
      </ButtonContainer>
    </Layout>
  )
}

ModalCancelamentoSolicitacao.Layout = styled.div`
  width: 100%;
  color: #545451;
`
ModalCancelamentoSolicitacao.Heading = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #122640;
  text-align: center;
`
ModalCancelamentoSolicitacao.SubHeading = styled.div`
  font-size: 16px;
  color: #122640;
  text-align: center;
  margin-top: 16px;
`
ModalCancelamentoSolicitacao.Button = styled(Button)`
  width: 40%;
  margin-top: 24px;
  height: 36px;
  font-size: 15px;
  text-align: center;
`
ModalCancelamentoSolicitacao.ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`
