import React, { useEffect, useState } from 'react'
import { ReciboComponent } from './components/ReciboComponent'
import { NovaVistoriaContainer } from './nova-vistoria/NovaVistoriaContainer'
import { NovaSolicitacaoStore as Store } from './NovaSolicitacaoStore'
import styled from '@emotion/styled'
import { Modal } from '../../components/Modal'
import iconError from '../../assets/icon-error.svg'
import iconAgendar from '../../assets/icon-agendar.svg'
import { Button } from '../../components/Button'
import { AuthStore } from '../auth/AuthStore'
import { useNavigate } from 'react-router'

export const ComprovantePagamento = () => {
  const { solicitacao } = Store.useState()
  const { token } = AuthStore.useState()
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <React.Fragment>
      <HeaderComprovantePagamento />
      <ReciboComponent solicitacao={solicitacao} />
    </React.Fragment>
  )
}

const HeaderComprovantePagamento = () => {
  const { Layout, Title, SubTitle, ContainerButton } = HeaderComprovantePagamento
  const navigate = useNavigate()

  return (
    <Layout>
      <Title>Pagamento confirmado</Title>
      <SubTitle>
        A partir de agora você já pode agendar o horário no local escolhido para realizar seu exame
        quando quiser.
      </SubTitle>
      <ContainerButton>
        <Button primary onClick={() => navigate('/agendamento')}>
          Agendar exame
        </Button>
      </ContainerButton>
    </Layout>
  )
}
HeaderComprovantePagamento.Layout = styled.div`
  display: grid;
  margin: 0 auto;
  text-align: center;
  max-width: 900px;
  margin-top: 20px;
  justify-content: center;
  gap: 15px;
`
HeaderComprovantePagamento.Title = styled.span`
  color: #122640;
  font-size: 23px;
  font-weight: bold;
`
HeaderComprovantePagamento.SubTitle = styled.span`
  color: #292926;
  font-size: 15px;
  padding: 20px;
  .aviso-comprovante-pagamento {
    color: #ffbc10;
    font-weight: bold;
  }
`
HeaderComprovantePagamento.ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`
