import axios from 'axios'
import useAxios, { UseAxiosResult } from 'axios-hooks'
import { createApi } from './ApiSchemas'

export const api = createApi(async ({
  url,
  method,
  body,
  params,
}) => await axios({ url, method, data: body, params }).then(x => x.data))

export const useApi: useApiType = createApi(({
  url,
  method,
  body,
  params,
  options,
}) => useAxios({ url, method, data: body, params }, options)) as any

type Unwrap<T> =
  T extends Promise<infer U> ? U :
    T extends (...args: any) => Promise<infer U> ? U :
      T extends (...args: any) => infer U ? U :
        T

type RT = ReturnType<typeof createApi>
type useApiType = {
  [T in keyof RT]: {
    [Y in keyof RT[T]]: (
      bodyOrOptions?: Parameters<RT[T][Y] extends (...props) => any ? RT[T][Y] : never>[0],
      options?: Parameters<RT[T][Y] extends (...props) => any ? RT[T][Y] : never>[1]
    ) => UseAxiosResult<Unwrap<RT[T][Y]>>
  }
}
