import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { ReactComponent as Logo } from '../../assets/logo-clinica-facil.svg'
import { ReactComponent as LogoBranco } from '../../assets/logo-clinica-facil-branco.svg'
import Background from '../../assets/background-clinica-facil.png'
import iconSearch from '../../assets/icon-search.svg'
import InputMask from 'react-input-mask'
import toast from 'react-hot-toast'
import { NovaSolicitacaoStore as Store } from './NovaSolicitacaoStore'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { UsuarioLogado } from '../../components/UsuarioLogado'
import { AuthStore, getUsuarioLogado } from '../auth/AuthStore'
import { Modal } from '../../components/Modal'
import { Schemas } from '../../components/ApiSchemas'
import { Show } from '../../components/Show'
import { api, useApi } from '../../components/Api'
import { CpfCnpjInput } from '../../components/CpfCnpjInput'
import { format } from 'date-fns'
import { ReactComponent as TextoFacil } from '../../assets/texto-facil.svg'
import { useOpenModal } from '../../GlobalStore'
import selecioneEcvIcon from '../../assets/icon-selecione-ecv.svg'
import pagueCobrancaIcon from '../../assets/icon-pague-cobranca.svg'
import agendeHorarioIcon from '../../assets/icon-agende-seu-horario.svg'
import realizeVistoriaIcon from '../../assets/icon-realize-vistoria.svg'
import acesseLaudoIcon from '../../assets/icon-acesse-laudo.svg'
import comoFuncionaIcon from '../../assets/icon-como-funciona.svg'
import introLpVistoriaIcon from '../../assets/title-intro-clinicafacil-lp.png'
import introLpClinicaFacilLogo from '../../assets/icon-cruz-vermelha.png'
import iconCalendar from '../../assets/icon-calendar.svg'
import logoSmartSistem from '../../assets/smart-sistem-logo.svg'
import beneficiosIcon from '../../assets/beneficios-icon.svg'
import groupFormaPgIcon from '../../assets/boleto-pix-icon.svg'
import cadastroLpImg from '../../assets/cadastro-lp.png'
import { validateCpfCnpj } from './functions'
import { ExameComponent } from './components/ExameComponent'
import { InputLabel } from 'components/InputLabel'

export const Home = () => {
  const {
    Layout,
    Title,
    Subtitle,
    ContainerOptions,
    ContainerInput,
    ContainerButton,
    Flex,
    Container,
    Button,
    Logo,
  } = Home
  const { renach } = Store.useState()
  const dispatch = Store.useDispatch()
  const [loading, setLoading] = useState(false)
  const [modalSolicitacaoEmAberto, setModalSolicitacaoEmAberto] = useState(false)
  const [solicitacaoDetran, setSolicitacaoDetran] = useState(
    {} as Schemas.ConsultaCondutorResponseDto
  )
  useEffect(() => {
    dispatch(Store.actions.clear())
  }, [])

  const submitSolicitacao = async () => {
    if (loading) return
    const hasSolicitacao = !!Object.keys(solicitacaoDetran).length
    if (!hasSolicitacao) return
    return setModalSolicitacaoEmAberto(true)
  }

  async function handleChange(renach: string) {
    dispatch(Store.actions.setRenach(renach))
    if (!renach || renach.length < 11) return
    try {
      setLoading(true)
      const data = await api.Solicitacoes.getSolicitacao({
        params: { renach: renach, tipoServico: 3 },
        options: { manual: true },
      })
      console.log(data)
      setSolicitacaoDetran(data)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      setSolicitacaoDetran({} as Schemas.ConsultaCondutorResponseDto)
      const errorMessage =
        error?.response?.data?.message ?? 'Não foi possível realizar a solicitação ao DETRAN.'
      toast.error(errorMessage)
    }
  }

  return (
    <React.Fragment>
      <Layout>
        <Flex>
          <Logo />
          <UsuarioLogado aplicacao="branco" />
        </Flex>
        <Container>
          <Title>
            Clínicas credenciadas a <br />a um toque de distância
          </Title>
          <Subtitle>
            Precisando de exame médico ou psicoténico DETRAN?
            <br />
            <strong>Aqui é simples, rápido, fácil e seguro.</strong>
          </Subtitle>
          <ContainerOptions>
            <ContainerInput>
              <InputMask
                mask="GO999999999"
                maskChar=""
                name="renach"
                value={renach}
                onChange={async (e) => await handleChange(e.target.value)}
              >
                {(inputProps) => (
                  <InputLabel {...inputProps} label="Renach" style={{ width: '100%' }} />
                )}
              </InputMask>
            </ContainerInput>
            <ContainerButton>
              <Button
                primary
                loading={loading}
                onClick={submitSolicitacao}
                data-cy="btn-buscar-clinica"
              >
                Pesquisar clínicas
              </Button>
            </ContainerButton>
          </ContainerOptions>
        </Container>
      </Layout>
      <ModalSolicitacaoEmAberto
        solicitacaoDetran={solicitacaoDetran}
        modalSolicitacaoEmAberto={modalSolicitacaoEmAberto}
        setModalSolicitacaoEmAberto={setModalSolicitacaoEmAberto}
      />
      <LandingPageIntroducao />
      {/* <LandingPageComoFunciona />
      <LandingPageBeneficios />
      <LandingPageCadastroClinica /> */}
      <LandingPageFooter />
    </React.Fragment>
  )
}
Home.Layout = styled.div`
  &::before {
    content: '';
    position: absolute;
    min-height: 90vh;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(32, 39, 106, 0.33);
  }
  & > * {
    z-index: 1;
  }
  color: #ffffff;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  background: url(${Background}) no-repeat right/cover;
  padding: 20px 20px 20px 10vw;

  @media (max-width: 700px) {
    padding: 0;
  }
`
Home.Container = styled.div`
  @media (min-width: 700px) {
    max-width: 50vw;
  }
  @media (max-width: 700px) {
    padding: 0 24px;
  }
`
Home.TextoFacil = styled(TextoFacil)`
  width: 47px;
  height: 33px;
  position: relative;
  top: 9px;
`
Home.Title = styled.h1`
  margin: 0;
  font-size: 57px;
  line-height: 120%;
  font-weight: 700;
  @media (max-width: 700px) {
    font-size: 28px;
    margin-top: 12px;
  }
`
Home.Subtitle = styled.div`
  margin: 0;
  margin-top: 24px;
  font-size: 24px;
  line-height: 150%;
  font-weight: 400;
  strong {
    font-weight: 700;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    margin-top: 12px;
  }
`

Home.ContainerOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 60px;
  gap: 10px;
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`

Home.ContainerInput = styled.div`
  gap: 24px;
  display: flex;
  position: relative;
  flex: 1;
  input {
    height: 42px;
    font-size: 18px;
    line-height: 105%;
    border-radius: 20px;
    border: 1px solid #7f7f7d;
    &:focus {
      outline: none;
    }

    &:required:valid {
      text-transform: uppercase;
    }
  }
  @media (max-width: 700px) {
    input {
      width: 100%;
      height: 42px;
      font-size: 18px;
    }
  }
`
Home.ContainerButton = styled('div')``
Home.Button = styled(Button)`
  width: 250px;
  font-weight: 600;
  font-size: 18px;
  padding: 0 12px;
  border-radius: 7px;
  @media (max-width: 700px) {
    font-size: 16px;
    height: 37px;
  }
`

Home.Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 108px;
  @media (max-width: 700px) {
    padding: 0 24px;
    align-items: center;
  }
`
Home.Logo = styled(Logo)`
  @media (max-width: 700px) {
    width: 80px;
  }
`
Home.ImagemCarro = styled.img`
  width: 100%;
  @media (min-width: 700px) {
    display: none;
  }
`
Home.Rodape = styled.div`
  width: 100%;
  .mobile {
    text-align: center;
    font-size: 12px;
  }
  @media (min-width: 700px) {
    .mobile {
      display: none;
      text-align: center;
      font-size: 12px;
    }
  }
  @media (max-width: 701px) {
    .desktop {
      display: none;
    }
  }
`

const LandingPageIntroducao = () => {
  const { Layout, Grid } = LandingPageIntroducao

  return (
    <Layout>
      <Grid>
        <img src={introLpClinicaFacilLogo} />
        <LandingPageIntroContent />
      </Grid>
    </Layout>
  )
}

LandingPageIntroducao.Layout = styled.div`
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(86, 134, 255, 0.3));
  padding: 20px 2px;
`

LandingPageIntroducao.Grid = styled.div`
  max-width: 1000px;
  display: grid;
  margin: 0 auto;
  padding: 0px 20px;
  & > img {
    width: 130px;
    margin: -87px auto 0px auto;
    z-index: 1;
  }
`

const LandingPageIntroContent = () => {
  const { Layout } = LandingPageIntroContent

  return (
    <Layout>
      <img src={introLpVistoriaIcon} />
      <span className="text-lp-clinicafacil-intro">
        Nós <strong>conectamos você</strong> com a <strong>clínica médica</strong> credenciada{' '}
        <strong>mais próxima</strong> e te
        <br />
        ajudamos desde a <strong>escolha do melhor local</strong> até o{' '}
        <strong>agendamento do seu exame.</strong>
      </span>
    </Layout>
  )
}

LandingPageIntroContent.Layout = styled.div`
  display: grid;
  gap: 20px;
  place-items: center;
  .text-lp-clinicafacil-intro {
    font-size: 21px;
    color: #022859;
    text-align: center;
    padding-bottom: 30px;
  }
  img {
    max-width: 100%;
    margin: 0 auto;
  }
`

const LandingPageComoFunciona = () => {
  const { Layout, Flex, ContainerButton, ButtonSolicitarExame } = LandingPageComoFunciona

  return (
    <Layout>
      <Flex style={{ marginBottom: '90px' }}>
        <BlocoComoFuncionaInicial
          iconSvg={comoFuncionaIcon}
          text="É simples, rápido, fácil e seguro"
        />
        <BlocoComoFunciona
          iconSvg={selecioneEcvIcon}
          title="Escolha uma clínica"
          text="Pesquise e selecione a clínica médica credenciada de sua preferência."
          color="#12A8EC"
        />
        <BlocoComoFunciona
          iconSvg={pagueCobrancaIcon}
          title="Pague a cobrança"
          text="Pague a cobrança da forma que preferir: pix, boleto ou cartão de débito."
          color="#239C39"
        />
        <BlocoComoFunciona
          iconSvg={agendeHorarioIcon}
          title="Agende seu horário"
          text="Está sem tempo? não tem problema, temos horários flexíveis esperando por você."
          color="#239C39"
        />
        <BlocoComoFunciona
          iconSvg={realizeVistoriaIcon}
          title="Realize o exame"
          text="As melhores clínicas, com os melhores médicos. Verifique o seu agendamento e vem!"
          color="#B4BBFC"
        />
        <BlocoComoFunciona
          iconSvg={acesseLaudoIcon}
          title="Acesse o laudo"
          text="Parabéns, você seguiu todos os passos anteriores, agora é a hora de pegar o seu laudo."
          color="#12A8EC"
        />
      </Flex>
      <ContainerButton>
        <ButtonSolicitarExame onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          Solicitar Exame
        </ButtonSolicitarExame>
      </ContainerButton>
    </Layout>
  )
}

LandingPageComoFunciona.Layout = styled.div`
  background-color: #ffffff;
  padding: 40px 0px;
`

LandingPageComoFunciona.ButtonSolicitarExame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #022859;

  &:hover {
    box-shadow: 0px 0px 60px #a2a0eb;
    transition: 0.25s all;
  }
  transition: 0.45s all;

  border-radius: 8px;
  width: 326px;
  height: 73px;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  cursor: pointer;
`

LandingPageComoFunciona.Flex = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
`

LandingPageComoFunciona.ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

const BlocoComoFunciona = ({
  iconSvg,
  title,
  text,
  color,
  ...props
}: {
  iconSvg: any
  title?: string
  text: string
  color?
}) => {
  const { Layout, Grid } = BlocoComoFunciona

  return (
    <Layout color={color} {...props}>
      <Grid className="content-card-cf">
        <div className="icon-img-cf">
          <img src={iconSvg} />
        </div>

        <div className="title-cf">
          <h2>{title}</h2>
        </div>

        <div className="descricao-cf">
          <div className="divider-cf" />
          <span>{text}</span>
        </div>
      </Grid>
    </Layout>
  )
}

BlocoComoFunciona.Layout = styled.div<{ color }>(
  ({ color }) => `
  padding: 13px 18px;
  border: 1px solid #D4D4D4;
  width: 300px;
  height: 225px;
  border-radius: 20px;
  transition: .25s all;
  &:hover{
    ${`border: 1px solid ${color};`}
  }
`
)

BlocoComoFunciona.Grid = styled.div`
  display: grid;
  align-items: center;
  gap: 3px;
  box-sizing: border-box;
  .icon-img-cf {
    width: 40px;
    height: 40px;
  }
  .title-cf {
    font-size: 16px;
    color: #122640;
  }
  .descricao-cf {
    font-size: 15px;
    color: #545451;
  }
`

const BlocoComoFuncionaInicial = styled(BlocoComoFunciona)`
  border: none;
  .content-card-cf {
    height: 100%;
    align-items: start;
    gap: 20px;
    box-sizing: border-box;
  }
  .title-cf {
    font-size: 16px;
    color: #122640;
    display: none;
  }
  .descricao-cf {
    .divider-cf {
      width: 80px;
      background-color: #fff;
      height: 3px;
      margin-bottom: 8px;
      border-radius: 40px;
    }
    span {
      font-size: 24px;
      font-weight: 380;
      color: #545451;
    }
  }
`

const LandingPageBeneficios = () => {
  const { Layout, Flex } = LandingPageBeneficios

  return (
    <Layout>
      <TitleBenefios />
      <Flex style={{ justifyContent: 'center' }}>
        <ContainerBlocoBeneficios />
        <ContaineirImagesBeneficios />
      </Flex>
    </Layout>
  )
}

LandingPageBeneficios.Layout = styled.div`
  background-color: #f4f4f4;
  padding: 40px 10px;
`

LandingPageBeneficios.Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const TitleBenefios = () => {
  const { Layout } = TitleBenefios

  return (
    <Layout>
      <div className="content-title-beneficios">
        <img src={beneficiosIcon} />
        <div className="divider-beneficios-lp" />
        <span className="title-beneficios-lp">Sistema de solicitações e pagamentos de exames.</span>
      </div>
    </Layout>
  )
}

TitleBenefios.Layout = styled.div`
  display: grid;
  justify-content: center;
  .divider-beneficios-lp {
    width: 80px;
    height: 3px;
    background-color: #122640;
    border-radius: 30px;
  }
  .content-title-beneficios {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  span {
    text-align: center;
    color: #292926;
    font-size: 20px;
  }
`

const ContaineirImagesBeneficios = () => {
  const { Layout } = ContaineirImagesBeneficios
  return (
    <Layout>
      <img className="img-sistem-sol-lp" src={logoSmartSistem} />
    </Layout>
  )
}

ContaineirImagesBeneficios.Layout = styled.div`
  .img-sistem-sol-lp {
    max-width: 100%;
  }
`

const ContainerBlocoBeneficios = () => {
  const { Layout } = ContainerBlocoBeneficios

  return (
    <Layout>
      <BlocoBeneficios
        iconSvg={iconCalendar}
        title="Otimização de processos"
        text="Além de conectar o candidato com a clínica, nossas soluções
        oferecem ferramentas totalmente automatizadas, desde o pagamento à
        emissão do laudo, te acompanharemos ao logo de todos os processos."
      />
      <BlocoBeneficios
        iconSvg={iconCalendar}
        title="Facilidade para pagar"
        text="É fácil até na hora de pagar. Escolha a forma de pagamento que
         seja mais confortável para você: Boleto, Pix ou cartão de débito."
      />
      <BlocoBeneficios
        iconSvg={iconCalendar}
        title="Pagamentos inteligentes e seguros"
        text="A nossa robusta plataforma direciona o seu pagamento para a nossa
        carteira digital, passando por diversos protocolos de segurança no caminho."
      />
      <BlocoBeneficios
        iconSvg={iconCalendar}
        title="Suporte humanizado"
        text="Conte com um suporte feito por gente de verdade. Estamos prontos para te ajudar com o que precisar."
      />
      <BlocoBeneficios
        iconSvg={iconCalendar}
        title="Painel Administrativo"
        text="Tenha o total controle administrativo e financeiro da sua clínica. Tudo seguro, flexível e ágil."
      />
    </Layout>
  )
}

ContainerBlocoBeneficios.Layout = styled.div`
  padding: 10px;
  width: 600px;
  & > *:not(:first-child) {
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    width: auto;
  }
`

const BlocoBeneficios = ({
  title,
  text,
  iconSvg,
  ...props
}: {
  title: string
  text: string
  iconSvg
}) => {
  const { Layout, Flex } = BlocoBeneficios

  return (
    <Layout {...props}>
      <Flex>
        <img src={iconSvg} width={35} />
        <span className="title-lp-beneficios">{title}</span>
      </Flex>
      <span className="text-lp-beneficios">{text}</span>
    </Layout>
  )
}

BlocoBeneficios.Layout = styled.div`
  display: grid;
  gap: 15px;
  .text-lp-beneficios {
    color: #545451;
    font-size: 15px;
    font-weight: 400;
  }
`
BlocoBeneficios.Flex = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  .title-lp-beneficios {
    color: #292926;
    font-size: 19px;
    font-weight: 600;
  }
`

const LandingPageCadastroClinica = () => {
  const { Layout, Button } = LandingPageCadastroClinica

  return (
    <Layout>
      <div>
        <span>Deseja cadastrar sua clínica no Clínica Fácil?</span>
        <Button
          onClick={() => window.open('https://app.pipefy.com/public/form/6-KIXtt-', '_blank')}
        >
          Cadastrar agora
        </Button>
      </div>
    </Layout>
  )
}

LandingPageCadastroClinica.Layout = styled.div`
  background-size: cover;
  height: 235px;
  background-image: url(${cadastroLpImg});
  display: grid;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 0 20px;
    span {
      color: #ffffff;
      font-size: 30px;
      font-weight: 650;
    }
    button {
      background-color: transparent;
      color: #ffffff;
      border: 1px solid #ffffff;
      padding: 0px 30px;
    }
  }
`
LandingPageCadastroClinica.Button = styled(Button)`
  border: 1px solid #022859;
  display: block;
  font-size: 17px;
`

const LandingPageFooter = () => {
  const { Layout, Logo, Grid } = LandingPageFooter

  return (
    <Layout>
      <Grid>
        <div className="logo-footer">
          <Logo />
        </div>

        <div className="sobrenos-footer">
          <span className="sobrenos-footer-title">Sobre nós</span>
          <span className="sobrenos-footer-text">
            Somos uma empresa especializada em soluções de solicitaçõe e pagamentos de exames
            médicos e psicotécnicos em todo o Estado de Goiás, fazendo o elo entre o solicitante e
            as clínicas médicas devidamente credenciadas junto ao DETRAN- GO.
          </span>
        </div>

        <div className="contato-footer">
          <span className="contato-footer-title">Contato</span>
          <span className="contato-footer-email">oi@clinicafacil.one</span>
          <span className="contato-footer-links-title">Links externos</span>
          <div className="contato-footer-links">
            <a href="/politica-de-privacidade" target="_blank" rel="noreferrer">
              Politica de privacidade
            </a>
            <a href="/termos-de-uso" target="_blank" rel="noreferrer">
              Termos de uso
            </a>
          </div>
        </div>

        <div className="formapg-footer">
          <span>Forma de Pagamento</span>
          <img src={groupFormaPgIcon} />
        </div>
      </Grid>
    </Layout>
  )
}

LandingPageFooter.Layout = styled.div`
  background-color: #022859;
  padding: 40px 100px;
`

LandingPageFooter.Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'logo-footer sobrenos-footer contato-footer formapg-footer';
  gap: 40px;
  & > * {
    height: fit-content;
    color: #ffffff;
  }
  .logo-footer {
    grid-area: logo-footer;
    display: flex;
    justify-content: center;
  }
  .sobrenos-footer {
    grid-area: sobrenos-footer;
    display: grid;
    gap: 20px;
    .sobrenos-footer-title {
      font-weight: bold;
    }
    .sobrenos-footer-text {
      font-size: 13px;
      font-weight: 330;
    }
  }
  .contato-footer {
    grid-area: contato-footer;
    display: grid;
    gap: 10px;
    justify-content: center;
    & > *:nth-child(odd) {
      font-weight: bold;
    }
    .contato-footer-email {
      font-size: 14px;
    }
    .contato-footer-links {
      display: grid;
      gap: 7px;
    }
    a {
      cursor: pointer;
      font-size: 13px;
      font-weight: 330;
      text-decoration: none;
      color: #ffffff;
    }
  }
  .formapg-footer {
    grid-area: formapg-footer;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    span {
      font-weight: bold;
    }
  }
  @media (min-width: 620px) and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'sobrenos-footer logo-footer'
      'contato-footer formapg-footer';
    padding: 10px;
    .sobrenos-footer,
    .contato-footer {
      text-align: center;
    }
  }
  @media (max-width: 620px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'sobrenos-footer'
      'contato-footer'
      'logo-footer'
      'formapg-footer';
    padding: 10px;
    .sobrenos-footer,
    .contato-footer {
      text-align: center;
    }
  }
`
LandingPageFooter.Logo = styled(LogoBranco)`
  width: 140px;
`

const ModalSolicitacaoExistente = ({
  visible,
  close,
  avancaVistoria,
  veiculo,
}: {
  visible
  close
  avancaVistoria
  veiculo
}) => {
  const { Title, Subtitle, Divider, Heading, Placa, ButtonBig, Flex, ContainerFechar } =
    ModalSolicitacaoExistente
  const usuarioLogado = AuthStore.useSelector(getUsuarioLogado)
  const navigate = useNavigate()
  const dispatch = Store.useDispatch()
  const [cpfCnpj, setCpfCnpj] = useState('')
  const { openModal, closeModal } = useOpenModal()

  const solicitacaoEmAberto = veiculo?.vistorias?.find((v) => {
    return v.dataConclusao == null && v.cliente.cpfCnpj === usuarioLogado?.cpfCnpj
  })

  function visualizarSolicitacoes() {
    return navigate('/adm')
  }

  return (
    <Modal
      width={696}
      visible={visible}
      onClose={close}
      data-cy="modal-solicitacaoDetran-existente"
    >
      <Title>Solicitação de Vistoria em Aberto</Title>
      <Subtitle>Você pode acompanhar uma solicitação iniciada anteriormente.</Subtitle>
      <Placa>{veiculo?.placa}</Placa>
      <Divider />
      <Show when={!!usuarioLogado}>
        <Heading>
          Você pode acompanhar a solicitação efetuada em{' '}
          <strong>{format(new Date(solicitacaoEmAberto?.createdAt || 0), 'dd/MM/yyy')}</strong>.
          <ButtonBig
            data-cy="btn-acompanhar-status-vistoria"
            primary
            onClick={() => visualizarSolicitacoes()}
          >
            Visualizar solicitação
          </ButtonBig>
        </Heading>
      </Show>
      <Flex>
        <ContainerFechar>
          <Button data-cy="btn-login-vistoria-aberta" primary onClick={() => openModal('LOGIN')}>
            Fazer login
          </Button>
          <Button
            data-cy="btn-novo-cadastro-vistoria-aberta"
            primary
            onClick={() => openModal('CADASTRO')}
          >
            Novo cadastro
          </Button>
          <Button onClick={close}>Fechar</Button>
        </ContainerFechar>
      </Flex>
    </Modal>
  )
}
ModalSolicitacaoExistente.Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #122640;
`
ModalSolicitacaoExistente.Subtitle = styled.div`
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #122640;
  margin-top: 8px;
`
ModalSolicitacaoExistente.Divider = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #d4d4d4;
`
ModalSolicitacaoExistente.Heading = styled.div`
  font-size: 18px;
  line-height: 120%;
  margin-top: 24px;
`
ModalSolicitacaoExistente.Placa = styled.div`
  border: 3px solid #ffbc01;
  border-radius: 8px;
  height: 84px;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  font-weight: bold;
  margin: 24px auto;
`
ModalSolicitacaoExistente.ButtonBig = styled(Button)`
  font-size: 1.3em;
  width: 280px;
  height: 56px;
  display: block;
  margin: 0 auto;
  margin-top: 24px;
`
ModalSolicitacaoExistente.Flex = styled.div`
  display: grid;
  gap: 12px;
  align-items: flex-end;
  justify-content: center;
`
ModalSolicitacaoExistente.ContainerFechar = styled.div`
  margin-top: 24px;
  text-align: right;
  display: grid;
  gap: 10px;
`

const ModalSolicitacaoEmAberto = ({
  solicitacaoDetran,
  modalSolicitacaoEmAberto,
  setModalSolicitacaoEmAberto,
}: {
  solicitacaoDetran: Schemas.ConsultaCondutorResponseDto
  modalSolicitacaoEmAberto: boolean
  setModalSolicitacaoEmAberto
}) => {
  const { Title } = ModalSolicitacaoEmAberto
  const dispatch = Store.useDispatch()
  const { renach } = Store.useState()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  async function defineFluxoExame({ tipo, solicitacaoDetran, solicitacaoExame }) {
    if (!!solicitacaoExame) {
      dispatch(Store.actions.setSolicitacao(solicitacaoExame))
      if (!solicitacaoExame?.dataPagamentoCobranca) return navigate('/pagamento')
      if (solicitacaoExame?.dataAgendamento) return navigate('/comprovante-agendamento')
      return navigate('/agendamento')
    }
    await handleClickProsseguir({ tipo, solicitacaoDetran })
  }

  async function handleClickProsseguir({ tipo, solicitacaoDetran }) {
    if (loading) return
    setLoading(true)
    try {
      const data = await api.Solicitacoes.createSolicitacao(
        {
          solicitacaoDetran,
          tipoExame: tipo,
          renach: renach,
        },
        { options: { manual: true } }
      )
      dispatch(Store.actions.createSolicitacaoSuccess(data))
      navigate('/pagamento')
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const message =
        error.response?.data?.message ?? 'Ocorreu um erro ao prosseguir para pagamento'
      toast(message)
    }
  }

  return (
    <Modal
      width={1200}
      visible={modalSolicitacaoEmAberto}
      onClose={() => setModalSolicitacaoEmAberto(false)}
    >
      <Title>Solicitação de Consultas em Aberto</Title>
      <ExameComponent
        solicitacaoDetran={solicitacaoDetran}
        onClose={() => setModalSolicitacaoEmAberto(false)}
        handleFluxoConsulta={defineFluxoExame}
      />
    </Modal>
  )
}

ModalSolicitacaoEmAberto.Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: #122640;
`
ModalSolicitacaoEmAberto.Subtitle = styled.div`
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #122640;
  margin-top: 8px;
`
ModalSolicitacaoEmAberto.Divider = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #d4d4d4;
`
ModalSolicitacaoEmAberto.Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 120%;
  margin-top: 24px;
`

function sanitize(text: string) {
  return text
    .replace(/[ ]/, '_')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase()
}
