import axios from 'axios'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { createSelector } from 'reselect'
import { createSimpleStore } from 'react-simple-reducer'
import toast from 'react-hot-toast'
import { Schemas } from '../../components/ApiSchemas'
import { api } from '../../components/Api'

export const AuthStore = createSimpleStore(
  {
    fetching: false,
    loading: false,
    isTokenValid: null as boolean | null,
    emailExists: null as string | null,
    token: null as string | null,
    envioEmailSucceded: null as boolean | null,
    clinicas: [] as Schemas.Clinica[] & { isSocioAdmin?: boolean },
    clinicaIdSelecionada: null as number | null,
  },
  {
    loginFetch (state) {
      state.fetching = true
    },
    loginSuccess (state, payload: { token, clinicas?}) {
      state.fetching = false
      state.token = payload.token
      if (payload.clinicas?.length) {
        state.clinicas = payload.clinicas
        state.clinicaIdSelecionada = payload.clinicas[0].id
      }
    },
    loginError (state) {
      state.fetching = false
    },
    logout (state) {
      state.token = null
      state.clinicaIdSelecionada = null
      state.clinicas = []
    },
    changeEcv (state, clinicaId: number) {
      state.clinicaIdSelecionada = clinicaId
    },
    mudarSenhaTokenFetch (state) {
      state.loading = true
    },
    mudarSenhaTokenSuccess (state) {
      state.loading = false
      state.isTokenValid = false
    },
    mudarSenhaTokenError (state) {
      state.loading = false
    },
    validaToken (state, isTokenValid: boolean) {
      state.isTokenValid = isTokenValid
    },
    checkEmailExistsFetch (state) {
      state.loading = true
    },
    stopCheckEmailExistsSuccess (state) {
      state.loading = false
    },
    stopCheckEmailExists (state) {
      state.loading = false
    },
    enviaEmailSuccess (state) {
      state.loading = false
      state.envioEmailSucceded = true
    },
    clearEnvioEmailSucceded (state) {
      state.envioEmailSucceded = null
    },
    checkEmailExistsSuccess (state, emailExists: string) {
      state.emailExists = emailExists
      state.loading = false
    },
    clearEmail (state) {
      state.emailExists = null
    },
    importClinicaSuccess (state, clinica: Schemas.Clinica) {
      if (!state.clinicas.some(x => x.id === clinica.id)) {
        state.clinicas = [...state.clinicas, clinica]
      }
      state.clinicaIdSelecionada = clinica.id
    },
    changeClinica (state, clinicas: Schemas.Clinica[]) {
      state.clinicas = clinicas
    },
    updateCadastroToken (state, token: string) {
      state.token = token
    },
  },
  {
    thunks: {
      recuperarSenha ({ email }: { email: string }) {
        return async (dispatch: any) => {
          try {
            dispatch(AuthStore.actions.checkEmailExistsFetch())
            await api.Clientes.recuperaSenha({ email })
            dispatch(AuthStore.actions.enviaEmailSuccess())
            toast('E-mail será enviado nas próximas horas')
          } catch (error: any) {
            dispatch(AuthStore.actions.stopCheckEmailExistsSuccess())
            const message = error.response?.data?.message ?? 'Falha ao enviar envio de email para recuperação de senha'
            toast.error(message)
          }
        }
      },
      mudarSenhaToken ({ senha, token }: { senha: string, token: string }) {
        return async (dispatch: any) => {
          try {
            dispatch(AuthStore.actions.mudarSenhaTokenFetch())
            await api.Clientes.updateSenhaToken({ senha, token })
            dispatch(AuthStore.actions.mudarSenhaTokenSuccess())
            toast('Senha alterada com sucesso')
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Falha ao enviar envio de email para recuperação de senha'
            toast.error(message)
            dispatch(AuthStore.actions.mudarSenhaTokenError())
          }
        }
      },
      validaToken ({ token }: { token: string }) {
        return async (dispatch: any) => {
          try {
            const isTokenValid = await api.Clientes.validaToken({ token })
            dispatch(AuthStore.actions.validaToken(isTokenValid))
          } catch (error: any) {
            const message = error.response?.data?.message ?? 'Falha ao realizar validação de token'
            toast.error(message)
          }
        }
      },
      recuperarEmail ({ cpfCnpj }: { cpfCnpj: string }) {
        return async (dispatch: any) => {
          try {
            dispatch(AuthStore.actions.checkEmailExistsFetch())
            const emailExists = await api.Clientes.recuperaEmail({ cpfCnpj })
            dispatch(AuthStore.actions.checkEmailExistsSuccess(emailExists))
          } catch (error: any) {
            dispatch(AuthStore.actions.stopCheckEmailExists())
            const message = error.response?.data?.message ?? 'Falha ao buscar email'
            toast.error(message)
          }
        }
      },
    },
    options: {
      cache: {
        key: 'CLINICAFACIL_AUTH',
        location: 'LOCALSTORAGE',
      },
    },
  }
)

type IState = ReturnType<typeof AuthStore.useState>

export const getUsuarioLogado = createSelector(
  (s: IState) => s.token,
  token => {
    if (!token) return null
    const decodedToken = jwtDecode<JwtPayload & Schemas.Cliente & { isAdmin?: boolean }>(token)
    const now = Date.now().valueOf() / 1000
    // if (!decodedToken || !decodedToken.exp || decodedToken.exp < now) return null
    return decodedToken
  }
)

export const selectClinicaAtual = createSelector(
  (s: IState) => s.clinicas,
  (s: IState) => s.clinicaIdSelecionada,
  (clinicas, clinicaId) => {
    return clinicas.find(e => e.id === clinicaId)
  }
)
