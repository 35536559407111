import React from 'react'
import { InputLabel } from './InputLabel'
import InputMask from 'react-input-mask'
export const TelefoneInput = ({ value, name, onChange, ...props }) => {
  return (
    <InputMask
    mask="(99) 99999-9999"
    value={value}
    name={name}
    onChange={e => {
      onChange(e)
    }}
    >
    {(inputProps) => <InputLabel {...inputProps} label="Telefone" {...props} />}
    </InputMask>
  )
}
