import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import bgHome from '../../assets/bg-home.jpg'
import { Button } from '../../components/Button'
import { InputSenha } from '../../components/InputSenha'
import toast from 'react-hot-toast'
import { IAlterarSenha } from '../adm/cadastro/dadosDto'
import { useParams, Link } from 'react-router-dom'
import { AuthStore } from './AuthStore'
import { Show } from '../../components/Show'

export const MudarSenhaToken = () => {
  const { token } = useParams<{ token }>()
  const {
    Layout,
    Background,
    PasswordResetContainer,
    SubHeading,
    Title,
    ContainerButton,
    ContainerInput,
    VoltarPaginaInicial,
  } = MudarSenhaToken
  const [dadosSenha, setDadosSenha] = useState<IAlterarSenha>({ novaSenha: '', confirmarSenha: '' })
  const authDispatch = AuthStore.useDispatch()
  const { confirmarSenha, novaSenha } = dadosSenha
  const { loading, isTokenValid } = AuthStore.useState()

  const valid = useMemo(() => {
    if (!confirmarSenha || !novaSenha) return false
    if (novaSenha.length < 6) return false
    if (novaSenha !== confirmarSenha) return false
    return true
  }, [dadosSenha])

  useEffect(() => {
    authDispatch(AuthStore.thunks.validaToken({ token }))
  }, [])

  const handleSubmit = async () => {
    if ((novaSenha && novaSenha.length < 6) || (novaSenha && novaSenha.length < 6))
      return toast.error('A senha deve ter no mínimo 6 caracteres')

    try {
      authDispatch(AuthStore.thunks.mudarSenhaToken({ senha: dadosSenha.novaSenha, token }))
    } catch (error: any) {
      const message = error.response?.data?.message ?? 'Ocorreu um erro'
      toast.error(message)
    }
  }

  const handleSetDadosSenha = (data: IAlterarSenha) => {
    setDadosSenha({
      ...dadosSenha,
      ...data,
    })
  }

  return (
    <>
      <Background />
      <Layout>
        <PasswordResetContainer>
          <Title>Redefinição de Senha Vistoria Fácil</Title>
          <Show when={!!isTokenValid}>
            <SubHeading>Digite aqui a sua nova senha</SubHeading>
            <ContainerInput>
              <NovaSenha handleSetDadosSenha={handleSetDadosSenha} novaSenha={dadosSenha.novaSenha} />
            </ContainerInput>
            <ContainerInput>
              <ConfirmacaoNovaSenha
                handleSetDadosSenha={handleSetDadosSenha}
                confirmarSenha={dadosSenha.confirmarSenha}
              />
            </ContainerInput>
            <ContainerButton>
              <Button
                type="submit"
                width={100}
                disabled={!valid}
                loading={loading}
                primary={valid}
                onClick={handleSubmit}
                data-cy="btn-continuar"
              >
                Alterar
              </Button>
            </ContainerButton>
          </Show>
          <Show when={!isTokenValid}>
            <SubHeading>Senha alterada com sucesso!</SubHeading>
            <VoltarPaginaInicial>
              <a
                href={!process.env.NODE_ENV.startsWith('prod') ? 'http://localhost:3000' : 'www.vistoriafacil.net'}
                rel="noreferrer"
              >
                {' '}
                Voltar para página inicial
              </a>
            </VoltarPaginaInicial>
          </Show>
        </PasswordResetContainer>
      </Layout>
    </>
  )
}

MudarSenhaToken.VoltarPaginaInicial = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: semi-bold;
`
MudarSenhaToken.Layout = styled.div``

MudarSenhaToken.Background = styled.div`
  color: white;
  min-height: 100vh;
  background-size: contain;
  background-position-x: right;

  padding-left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  background: radial-gradient(50% 50% at 50% 50%, #f8ca50 0%, #f4b62d 100%);
  color: #122640;
  /* z-index: 1; */
  @media (min-width: 700px) {
    background-size: cover;
    background-position: center right;
    background-image: url('/vistoria-facil-bg.jpg');
  }

  @media (max-width: 700px) {
    padding: 0;
  }
  filter: blur(3px);
  -webkit-filter: blur(3px);
  z-index: 0;
`

MudarSenhaToken.PasswordResetContainer = styled.div`
  position: absolute;
  padding: 48px;
  top: 2.91%;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  border-radius: 24px;
  left: calc((100vw - 384px) / 2);
  width: 455px;
  box-shadow: 0px 10px 36px rgba(33, 37, 41, 0.25);
  height: 510px;
`

MudarSenhaToken.Title = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #122640;
  text-align: center;
`

MudarSenhaToken.Subtitle = styled.div`
  font-size: 14px;
  line-height: 120%;
  color: #636c74;
  text-align: center;
  margin-top: 12px;
`

MudarSenhaToken.Divider = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid #d4d4d4;
`

MudarSenhaToken.Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 120%;
  margin-top: 24px;
  color: #545451;
`

MudarSenhaToken.ButtonFechar = styled(Button)`
  border: none;
`

MudarSenhaToken.SubHeading = styled.div`
  font-size: 16px;
  color: #122640;
  text-align: center;
  margin-top: 16px;
`

MudarSenhaToken.ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`

MudarSenhaToken.ContainerInput = styled.div`
  margin: 13px 0;
`

const NovaSenha = ({ novaSenha, handleSetDadosSenha }) => {
  const errors = novaSenha && novaSenha.length < 6 ? ['A senha deve ter no mínimo 6 caracteres'] : []

  return (
    <InputSenha
      value={novaSenha}
      onChange={(e) => handleSetDadosSenha({ novaSenha: e.target.value })}
      label="Nova Senha"
      placeholder="Digite sua nova senha "
      errors={errors}
    />
  )
}

const ConfirmacaoNovaSenha = ({ confirmarSenha, handleSetDadosSenha }) => {
  return (
    <InputSenha
      value={confirmarSenha}
      onChange={(e) => handleSetDadosSenha({ confirmarSenha: e.target.value })}
      label="Confirmar Nova Senha"
      placeholder="Digite novamente a senha"
    />
  )
}
