import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { NovaSolicitacaoStore as Store } from '../NovaSolicitacaoStore'
import { Show } from '../../../components/Show'
import { useApi } from '../../../components/Api'
import { format } from 'date-fns'

export const PassoAPassoVistoria = ({
  passoAtual,
  ...props
}: { passoAtual: number } & React.HTMLAttributes<HTMLDivElement>) => {
  const { Layout, Title, Bloco } = PassoAPassoVistoria
  return (
    <Layout {...props}>
      <Title>Solicitação de exame</Title>
      <Bloco>
        <Step numero={1} passoAtual={passoAtual} label="Dados do solicitante" />
        <DadosSolicitante />
      </Bloco>
      <Bloco>
        <Step numero={2} passoAtual={passoAtual} label="Clínica" />
        <DadosEcv />
      </Bloco>
      <Bloco>
        <Step numero={3} passoAtual={passoAtual} label="Pagamento" />
        <DadosPagamento />
      </Bloco>
      <Bloco>
        <Step numero={4} passoAtual={passoAtual} label="Agendamento" />
        <DadosAgendamento />
      </Bloco>
    </Layout>
  )
}
PassoAPassoVistoria.Layout = styled.div`
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  width: 228px;
`
PassoAPassoVistoria.Title = styled.div`
  padding: 16px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #7f7f7d;
`
PassoAPassoVistoria.Bloco = styled.div`
  border-top: 1px solid #d4d4d4;
  padding: 16px;
  font-size: 12px;
  line-height: 120%;
  color: #545451;
  & > div {
    margin-top: 4px;
  }
  strong {
    color: #292926;
  }
`

const DadosSolicitante = () => {
  const { Layout } = DadosSolicitante

  const { solicitacao } = Store.useState()
  const cliente = solicitacao.cliente

  if (!cliente) return null
  return (
    <div data-cy="dados-solicitante">
      <div>
        Nome: &nbsp;
        <strong>{cliente.nome}</strong>
      </div>

      <div>
        {cliente.cpfCnpj?.length === 14 ? 'CPF' : 'CNPJ'}: &nbsp;
        <strong>{cliente.cpfCnpj}</strong>
      </div>

      <div>
        Renach: <strong>{solicitacao?.renach}</strong>
      </div>

      <Show when={!!cliente.telefone}>
        <div>
          Telefone: &nbsp;
          <strong>{cliente.telefone}</strong>
        </div>
      </Show>

      <Show when={!!cliente.email}>
        <div>
          E-mail: <strong>{cliente.email}</strong>
        </div>
      </Show>
    </div>
  )
}
DadosSolicitante.Layout = styled.div``

const DadosPagamento = () => {
  const { Layout } = DadosPagamento

  const { solicitacao } = Store.useState()
  const cliente = solicitacao.cliente

  const meioPagamento = getMeioPagamento(solicitacao.meioPagamento)

  function getMeioPagamento(meioPagamento) {
    if (meioPagamento === 'BOLETO') return 'Boleto'
    if (meioPagamento === 'CARTAO_CREDITO') return 'Cartão de Crédito'
    if (meioPagamento === 'PIX') return 'Pix'
    if (meioPagamento === 'SALDO_HYPERPAY') return 'Saldo Hyperpay'
    return '-'
  }

  if (!cliente) return null
  return (
    <div data-cy="dados-pagamento">
      <Show when={!!solicitacao.meioPagamento}>
        <div>
          Tipo: &nbsp;
          <strong>{meioPagamento}</strong>
        </div>
      </Show>

      <Show when={!!solicitacao.valorCobranca}>
        <div>
          Valor: <strong>R$ {solicitacao.valorCobranca?.toFixed(2).replace('.', ',')}</strong>
        </div>
      </Show>
    </div>
  )
}
DadosPagamento.Layout = styled.div``

const DadosEcv = () => {
  const {
    solicitacao: { clinica },
    solicitacao,
  } = Store.useState()
  if (!clinica?.id) return null
  return (
    <>
      <div>
        Município: <strong>{clinica.municipio}</strong>
      </div>
      <div>
        Clínica: <strong>{clinica.nome}</strong>
        <br />
        <small>
          {clinica.endereco} {clinica.bairro}
        </small>
      </div>
      <div>
        Serviço: <strong>{solicitacao?.tipoExame?.replace(/_/g, ' ')}</strong>
      </div>
    </>
  )
}

const DadosAgendamento = () => {
  const { solicitacao } = Store.useState()

  if (solicitacao.dataAgendamento) {
    return (
      <>
        <div>
          Data: <strong>{format(new Date(solicitacao.dataAgendamento), 'dd/MM/yyyy')}</strong>
        </div>
        <div>
          Horário: <strong>{format(new Date(solicitacao.dataAgendamento), 'HH:mm')}</strong>
        </div>
      </>
    )
  }
  return <></>
}

const Step = ({ numero, passoAtual, label }) => {
  const { Layout } = Step
  const active = passoAtual === numero
  const disabled = passoAtual < numero
  return (
    <Layout active={active} disabled={disabled}>
      <div className="badge">{numero}</div>
      {label}
    </Layout>
  )
}
Step.Layout = styled.div<{ active; disabled }>(
  ({ active, disabled }) => `
  .badge {
    width: 22px;
    height: 22px;
    background: #022859;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${active ? 'background: #0597F2;' : ''}
    ${disabled ? 'background: #a0a8b3;' : ''}
  }
  display: flex;
  align-items: center;
  gap: 8px;
  ${disabled ? 'color: #bbbbb9;' : ''}
`
)
