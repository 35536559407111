import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { NovaVistoriaContainer } from './nova-vistoria/NovaVistoriaContainer'
import { NovaSolicitacaoStore } from './NovaSolicitacaoStore'
import 'react-calendar/dist/Calendar.css'
import { api, useApi } from '../../components/Api'
import toast from 'react-hot-toast'
import { AuthStore } from '../auth/AuthStore'
import { AgendamentoComponent } from './components/AgendamentoComponent'
import { Schemas } from '../../components/ApiSchemas'
import { Button } from 'components/Button'
import { addDays } from 'date-fns/esm'

export const Agendamento = () => {
  const [loading, setLoading] = useState(false)
  const { solicitacao } = NovaSolicitacaoStore.useState()
  const dispatch = NovaSolicitacaoStore.useDispatch()
  const { token } = AuthStore.useState()
  const navigate = useNavigate()
  console.log(solicitacao)
  useEffect(() => {
    // if (!token) navigate('/')
    if (!solicitacao?.dataPagamentoCobranca) navigate('/pagamento')
  }, [])

  const handleAlterarPagamento = async () => {
    try {
      const solicitacaoAlterada = await api.Solicitacoes.alteraPagamentoDetran({
        id: solicitacao.id,
        dataPagamentoCobranca: addDays(new Date(), 10),
      })
    } catch (error: any) {
      toast.error(error.response?.data?.message)
    }
  }
  const handleCancelaPagamento = async () => {
    try {
      const solicitacaoCancelada = await api.Solicitacoes.cancelaPagamentoDetran({
        id: solicitacao.id,
      })
    } catch (error: any) {
      toast.error(error.response?.data?.message)
    }
  }

  const handleAgendar = async (
    horarioSelecionadoId: number,
    horariosDisponiveis: Schemas.GetHorariosDisponiveisResponseDto[]
  ) => {
    if (loading) return
    if (horarioSelecionadoId === null) return toast.error('Selecione um horário!')
    const dataAgendamento: any = horariosDisponiveis[horarioSelecionadoId].inicio

    try {
      setLoading(true)
      await api.Solicitacoes.createAgendamento(
        { dataAgendamento },
        { solicitacaoId: solicitacao.id }
      )
      dispatch(NovaSolicitacaoStore.actions.efetuarAgendamentoSuccess(dataAgendamento))
      toast.success('Agendamento efetuado com sucesso!')
      navigate('/adm')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Erro ao criar agendamento!')
      setLoading(false)
    }
  }

  return (
    <NovaVistoriaContainer passoAtual={5}>
      <AgendamentoComponent solicitacao={solicitacao} handleAgendar={handleAgendar} />
      <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
        <Button onClick={handleAlterarPagamento}>Alterar Pagamento</Button>
        <Button onClick={handleCancelaPagamento}>Cancelar Pagamento</Button>
      </div>
    </NovaVistoriaContainer>
  )
}
