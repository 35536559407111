import styled from '@emotion/styled'
import { Show } from 'components/Show'
import { Paragraph } from 'components/Typography'
import { format } from 'date-fns'
import React from 'react'
import exameMedicoIcon from '../../../assets/exame-medico-icon.png'
import examePsicologico from '../../../assets/exame-psicologico-icon.png'
import { Schemas } from '../../../components/ApiSchemas'
import { Button } from '../../../components/Button'

interface THandleFluxoConsulta {
  tipo: 'EXAME_MEDICO' | 'EXAME_PSICOLOGICO'
  solicitacaoDetran: Schemas.ConsultaCondutorResponseDto
  solicitacaoExame: Schemas.Solicitacao
}
export const ExameComponent = ({
  solicitacaoDetran,
  onClose,
  handleFluxoConsulta,
  ...props
}: {
  solicitacaoDetran: Schemas.ConsultaCondutorResponseDto
  onClose
  handleFluxoConsulta: ({ tipo, solicitacaoDetran, solicitacaoExame }: THandleFluxoConsulta) => void
}) => {
  const { Layout, ContainerButton, Button, ContainerInfoCondutor, Grid } = ExameComponent
  console.log({ solicitacaoDetran })
  return (
    <Layout {...props}>
      <ContainerInfoCondutor>
        <Grid>
          <span>
            Prezado(a),&nbsp;
            <strong>{solicitacaoDetran.nomeCandidato}</strong>
            <br />
            Abaixo listado a clínica correspondente a cada tipo de exame. Selecione para ser feito o
            pagamento e logo após o agendamento.
          </span>
        </Grid>
      </ContainerInfoCondutor>
      <ContainerBloco>
        <Show when={!!solicitacaoDetran?.razaoClinicaMed}>
          <Bloco
            tipo="Exame Médico"
            solicitacaoDetran={solicitacaoDetran}
            clinica={solicitacaoDetran?.razaoClinicaMed}
            telefone={solicitacaoDetran?.telefoneClinicaMed}
            {...props}
            onClick={() =>
              handleFluxoConsulta({
                tipo: 'EXAME_MEDICO',
                solicitacaoDetran,
                solicitacaoExame: solicitacaoDetran?.solicitacaoMedica,
              })
            }
          />
        </Show>
        <Show when={!!solicitacaoDetran?.razaoClinicaPsi}>
          <Bloco
            tipo="Exame Psicológico"
            solicitacaoDetran={solicitacaoDetran}
            clinica={solicitacaoDetran?.razaoClinicaPsi}
            telefone={solicitacaoDetran?.telefoneClinicaPsi}
            {...props}
            onClick={() =>
              handleFluxoConsulta({
                tipo: 'EXAME_PSICOLOGICO',
                solicitacaoDetran,
                solicitacaoExame: solicitacaoDetran?.solicitacaoPsi,
              })
            }
          />
        </Show>
      </ContainerBloco>
      <ContainerButton>
        <Button primary onClick={onClose}>
          Fechar
        </Button>
      </ContainerButton>
    </Layout>
  )
}
ExameComponent.Layout = styled.div`
  padding: 30px 16px;
`
ExameComponent.ContainerInfoCondutor = styled.div`
  display: flex;
  justify-content: flex-start;
`
ExameComponent.Grid = styled.div`
  display: grid;
  gap: 7px;
  color: #292926;
  span {
    font-size: 16px;
    line-height: 1.3;
  }
`
ExameComponent.ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`
ExameComponent.Button = styled(Button)`
  width: 180px;
`
const ContainerBloco = ({ children }) => {
  const { Layout } = ContainerBloco

  return <Layout>{children}</Layout>
}

ContainerBloco.Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`

interface IBloco
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  solicitacaoDetran: Schemas.ConsultaCondutorResponseDto
  clinica
  telefone: any
  tipo: string
}

const Bloco = ({ solicitacaoDetran, clinica, telefone, tipo, ...props }: IBloco) => {
  const { Layout, Flex, ContainerDadosExame, catchBorderColor, DefineStatus } = Bloco

  return (
    <Layout color={catchBorderColor(tipo)} {...props}>
      <Flex>
        <img src={tipo === 'Exame Médico' ? exameMedicoIcon : examePsicologico} />
        <ContainerDadosExame>
          <FlexText>
            <Title>Tipo: </Title>
            <Text>{tipo.toUpperCase()}</Text>
          </FlexText>
          <FlexText>
            <Title>Clínica: </Title>
            <Text>{clinica}</Text>
          </FlexText>
          <FlexText>
            <Title>Telefone: </Title>
            <Text>{telefone}</Text>
          </FlexText>
          <Show when={tipo === 'Exame Médico'}>
            <FlexText>
              <Title>Endereço: </Title>
              <Text>
                {`${solicitacaoDetran?.enderecoMed?.logradouro} - 
                ${solicitacaoDetran?.enderecoMed?.bairro}`}
              </Text>
            </FlexText>
            <FlexText>
              <Title>Cidade: </Title>
              <Text>
                {`${solicitacaoDetran?.enderecoMed?.municipio} - 
                ${solicitacaoDetran?.enderecoMed?.uf}`}
              </Text>
            </FlexText>
            <Show when={!!solicitacaoDetran?.solicitacaoMedica}>
              {DefineStatus(solicitacaoDetran?.solicitacaoMedica)}
            </Show>
          </Show>
          <Show when={tipo === 'Exame Psicológico'}>
            <FlexText>
              <Title>Endereço: </Title>
              <Text>
                {`${solicitacaoDetran?.enderecoPsi?.logradouro} - 
                ${solicitacaoDetran?.enderecoPsi?.bairro}`}
              </Text>
            </FlexText>
            <FlexText>
              <Title>Cidade: </Title>
              <Text>
                {`${solicitacaoDetran?.enderecoPsi?.municipio} - 
                ${solicitacaoDetran?.enderecoPsi?.uf}`}
              </Text>
            </FlexText>
            <Show when={!!solicitacaoDetran?.solicitacaoPsi}>
              {DefineStatus(solicitacaoDetran?.solicitacaoPsi)}
            </Show>
          </Show>
        </ContainerDadosExame>
      </Flex>
    </Layout>
  )
}
Bloco.catchBorderColor = (tipo) => (tipo === 'Exame Médico' ? '#BA1F33' : '#0597F2')
Bloco.Layout = styled.div<{ color }>(
  ({ color }) => `
  flex: 0 1 470px;
  display: flex;
  align-items: center;
  padding: 7px 5px;
  cursor: pointer;
  transition: 0.27s;
  border-radius: 10px;
  min-height: 150px;
  &:hover {
    filter: brightness(.85);
    box-shadow: 0px 0px 5px 1px ${color};
  }
  border: 1px solid ${color};
`
)
Bloco.Flex = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    width: 56px;
  }
`
Bloco.ContainerDadosExame = styled.div`
  display: grid;
  gap: 5px;
  flex: 1;
  justify-content: center;
  color: #292926;
  span {
    font-size: 14px;
    font-weight: 500;
    strong {
      font-size: 15px;
      font-weight: bold;
    }
  }
`
Bloco.DefineStatus = (solicitacao: Schemas.Solicitacao) => {
  return (
    <React.Fragment>
      <Show when={!!solicitacao?.dataPagamentoCobranca}>
        <FlexText>
          <Title>Data Pagamento: </Title>
          <Text>{getFullDate(solicitacao?.dataPagamentoCobranca)}</Text>
        </FlexText>
      </Show>
      <Show when={!!solicitacao?.dataAgendamento}>
        <FlexText>
          <Title>Data Agendamento: </Title>
          <Text>{getFullDate(solicitacao?.dataAgendamento)}</Text>
        </FlexText>
      </Show>
    </React.Fragment>
  )
}
const FlexText = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
const Text = styled(Paragraph)`
  font-size: 13px !important;
`
const Title = styled(Paragraph)`
  font-size: 14.5px;
  font-weight: 600 !important;
`
function getFullDate(date) {
  if (!date) return ''
  return format(new Date(date), 'dd/MM/yyyy HH:mm')
}
