import React, { ReactNode, useState } from 'react'
import styled from '@emotion/styled'
import { Schemas } from '../../../components/ApiSchemas'
import logo from '../../../assets/logo-clinica-facil-roxo-fundo-branco.svg'
import { format } from 'date-fns'
import { Button } from '../../../components/Button'
import iconPrinter from '../../../assets/icon-printer.svg'
import iconWhatsapp from '../../../assets/icon-whatsapp.svg'
import iconNotaFiscal from '../../../assets/icon-notafiscal.svg'
import { Show } from '../../../components/Show'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'
import { useWindowWidth } from '../../../components/hooks/use-window-width'
import { api } from '../../../components/Api'
import { NovaSolicitacaoStore } from '../NovaSolicitacaoStore'
import { Modal } from '../../../components/Modal'

function getMeioPagamento(meioPagamento) {
  if (meioPagamento === 'BOLETO') return 'Boleto'
  if (meioPagamento === 'CARTAO_CREDITO') return 'Cartão de Crédito'
  if (meioPagamento === 'PIX') return 'Pix'
  if (meioPagamento === 'SALDO_HYPERPAY') return 'Saldo Hyperpay'
  return '-'
}

function handleClickImprimir() {
  window.print()
}

function handleClickNotaFiscal() {
  toast('Sua nota fiscal está sendo gerada. Volte novamente em breve para visualizá-la.')
}

export function handleClickWhatsapp(solicitacao: Schemas.Solicitacao) {
  let msg

  if (!solicitacao.dataPagamentoCobranca) {
    msg = `✅ Olá! Sua solicitação Nº *${solicitacao.id} e Clinica *${solicitacao.clinica.nome}* ✅ \n\n`
    msg +=
      'A partir de agora você já pode realizar o pagamento. Você tem até 30 dias a partir da data de emissão dessa solicitação para realizar o pagamento. \n\n'
    msg += 'Após essa data a solicitação será finalizada automaticamente.\n\n'
  }

  if (solicitacao.dataPagamentoCobranca && !solicitacao.dataAgendamento) {
    msg = `✅ Olá! Sua solicitação Nº *${solicitacao.id}* está com o pagamento realizado com sucesso no Vistoria Fácil! ✅ \n\n`

    if (solicitacao.clinica.tipoAtendimento === 'ORDEM_CHEGADA') {
      msg += `${solicitacao.dataPagamentoCobranca} A partir de agora você já pode comparecer no local escolhido para realizar sua solicitacao. A *${solicitacao.clinica.nome}*  está localizada no endereço *${solicitacao.clinica.endereco}, ${solicitacao.clinica.bairro}, ${solicitacao.clinica.municipio}-${solicitacao.clinica.uf}*.\n\n`
    }

    if (solicitacao.clinica.tipoAtendimento === 'AGENDAMENTO' && !solicitacao.dataAgendamento) {
      msg +=
        'A partir de agora você já pode agendar o horário no local escolhido para realizar sua solicitacao quando quiser. \n\n'
    }

    msg +=
      'Caso a solicitacao seja reprovada, você terá até 30 dias corridos para realizar uma nova solicitação gratuita. \n\n'
    msg += 'Caso perca o prazo ou seja reprovado, será necessário iniciar uma nova solicitação.'
  }

  if (solicitacao.dataAgendamento) {
    msg = `✅ Olá! Sua solicitação Nº *${
      solicitacao.id
    }* está agendada no Vistoria Fácil no Vistoria Fácil! em ${format(
      new Date(solicitacao.dataAgendamento),
      'dd/MM/yyyy HH:mm'
    )} ✅ \n\n`
    msg += `A partir de agora você já pode comparecer no local escolhido para realizar sua solicitacao. A *${solicitacao.clinica.nome}* está localizada no endereço *${solicitacao.clinica.endereco}, ${solicitacao.clinica.bairro}, ${solicitacao.clinica.municipio}-${solicitacao.clinica.uf}*.\n\n`
    msg +=
      'Procure chegar no horário agendado. O veículo será recepcionado com uma tolerância máxima de 30 minutos para atrasos. \n\n'
    msg +=
      'Caso o veículo seja reprovado ou bloqueado na solicitacao, o solicitante terá até 30 dias corridos para realizar uma nova solicitação gratuita. \n\n'
    msg +=
      '*Caso perca o prazo ou seja novamente reprovado, será necessário pagar uma nova solicitacao.*'
  }

  msg = window.encodeURIComponent(msg)
  window.open(`https://api.whatsapp.com/send?text=${msg}`)
}

function handleClickLocalizacao(solicitacao: Schemas.Solicitacao) {
  window.open(`https://www.google.com/maps/dir/?api=1&destination=$-12776272823%20$-17126166718`)
}

const getFormatDataAgendamento = (solicitacao) =>
  solicitacao.dataAgendamento
    ? format(new Date(solicitacao.dataAgendamento), 'dd/MM/yyyy HH:mm:ss')
    : ''
const getFormatdataEmissao = (solicitacao) =>
  format(new Date(solicitacao.createdAt), 'dd/MM/yyyy HH:mm:ss')
const getFormatdataPagamento = (solicitacao) =>
  solicitacao.dataPagamentoCobranca
    ? format(new Date(solicitacao.dataPagamentoCobranca), 'dd/MM/yyyy HH:mm:ss')
    : ''

export const ReciboComponent = ({
  solicitacao,
  children,
}: {
  solicitacao: Schemas.Solicitacao
  children?: ReactNode
}) => {
  const {
    Layout,
    Flex,
    Logo,
    ContainerValor,
    ContainerComprovante,
    ContainerBotoes,
    ModalContainer,
    Button,
    ButtonWhatsapp,
  } = ReciboComponent
  const isMobile = useWindowWidth() <= 900
  const [isModalVisible, setIsModalVisible] = useState(false)
  const meioPagamento = getMeioPagamento(solicitacao.meioPagamento)

  return (
    <Layout data-cy="recibo-component">
      <ContainerComprovante>
        <Flex>
          <Logo src={logo} />
          <DadosCobranca solicitacao={solicitacao} dataEmissao={getFormatdataEmissao(solicitacao)}>
            <Show when={!!solicitacao.dataPagamentoCobranca}>
              <div>
                Pago via <strong>{meioPagamento}</strong> em {getFormatdataPagamento(solicitacao)}
              </div>
            </Show>
          </DadosCobranca>
        </Flex>
        <Show when={isMobile}>
          <ContainerTableMobile solicitacao={solicitacao} />
        </Show>
        <Show when={!isMobile}>
          <ContainerTable solicitacao={solicitacao} />
        </Show>
        <Show when={!!children}>{children}</Show>
        <ContainerValor>
          Valor: R$ {solicitacao.valorCobranca?.toFixed(2).replace('.', ',')}
        </ContainerValor>
      </ContainerComprovante>
      <ContainerBotoes>
        <Button onClick={handleClickImprimir}>
          Imprimir <img src={iconPrinter} />
        </Button>
        <Button onClick={handleClickNotaFiscal}>
          Nota Fiscal <img src={iconNotaFiscal} />
        </Button>
        <Button onClick={() => setIsModalVisible(true)}>Cancelar solicitação</Button>
        <ButtonWhatsapp onClick={() => handleClickWhatsapp(solicitacao)}>
          Enviar via WhatsApp <img src={iconWhatsapp} />
        </ButtonWhatsapp>

        <ModalContainer
          width={600}
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          style={{ paddingTop: 40, paddingBottom: 30, marginRight: 30 }}
        >
          <ModalCancelamentoSolicitacaoPaga close={() => setIsModalVisible(false)} />
        </ModalContainer>
      </ContainerBotoes>
    </Layout>
  )
}
ReciboComponent.Layout = styled.div`
  @media print {
    position: fixed;
    min-height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3900;
    background: white;
  }
  .comprovante-info-agendamento {
    margin: 10px auto;
    color: #7f7f7d;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
  }
`
ReciboComponent.Flex = styled.div`
  display: flex;
  color: #292926;
  font-size: 16px;
  gap: 48px;
  justify-content: space-between;
  align-items: center;
  small {
    font-weight: 600;
    font-size: 14px;
  }
`

ReciboComponent.ModalContainer = styled(Modal)`
  /* display: none; */

  @media (max-width: 600px) {
    display: grid;
    margin-top: 600px;
  }
`

const ContainerTableMobile = ({ solicitacao }) => {
  const { Layout, Flex, Grid, ContainerButtonLocalizacaoVistoria } = ContainerTableMobile
  const labelCpfCnpj = solicitacao.cliente.cpfCnpj.length === 14 ? 'CPF' : 'CNPJ'
  return (
    <Layout>
      <Flex>
        <Grid>
          <span>Solicitante</span>
          <span>{solicitacao.cliente.nome}</span>
        </Grid>
        <Grid>
          <span>{labelCpfCnpj}</span>
          <span>{solicitacao.cliente.cpfCnpj}</span>
        </Grid>
      </Flex>
      <Flex>
        <Grid>
          <span>Data Pagamento</span>
          <span>{getFormatdataPagamento(solicitacao)}</span>
        </Grid>
        <Show when={!!solicitacao?.dataAgendamento}>
          <Grid>
            <span>Data Agendamento</span>
            <span>{getFormatDataAgendamento(solicitacao)}</span>
          </Grid>
        </Show>
      </Flex>
      <Flex>
        <Grid>
          <span>Local da clínica</span>
          <span>{solicitacao.clinica.nome}</span>
          <span>
            {solicitacao.clinica.endereco}, {solicitacao.clinica.bairro},{' '}
            {solicitacao.clinica.municipio} - {solicitacao.clinica.uf}
          </span>
          <span>{solicitacao.clinica.telefone}</span>
          <ContainerButtonLocalizacaoVistoria>
            <Button onClick={() => handleClickLocalizacao(solicitacao)}>
              Localização da clínica
            </Button>
          </ContainerButtonLocalizacaoVistoria>
        </Grid>
      </Flex>
    </Layout>
  )
}

ContainerTableMobile.Layout = styled.div`
  padding-top: 10px;
  border-top: 1px solid #d4d4d4;
`
ContainerTableMobile.Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
  padding: 10px 6px;
  border-bottom: 1px solid #d4d4d4;
`

ContainerTableMobile.Grid = styled.div`
  display: grid;
  color: #292926;
  font-size: 14px;
  & > *:first-of-type {
    font-weight: bold;
    font-size: 15px;
  }
`

ContainerTableMobile.ContainerButtonLocalizacaoVistoria = styled.div`
  margin-top: 12px;
  @media print {
    display: none;
  }
`

const ContainerTable = ({ solicitacao }) => {
  const { Table } = ContainerTable

  return (
    <Table cellPadding={0} cellSpacing={0}>
      <ContainerTrInicial solicitacao={solicitacao} />
      <ContainerTrFinal solicitacao={solicitacao} />
    </Table>
  )
}

ContainerTable.Table = styled.table`
  color: #292926;
  font-size: 16px;
  width: 100%;
  small {
    font-weight: 600;
    font-size: 14px;
  }
  td {
    vertical-align: top;
    border-bottom: 1px solid #d4d4d4;
    padding: 24px 0;
  }
`

const ContainerTrInicial = ({ solicitacao }) => {
  const meioPagamento = getMeioPagamento(solicitacao.meioPagamento)
  const labelCpfCnpj = solicitacao.cliente.cpfCnpj.length === 14 ? 'CPF' : 'CNPJ'
  return (
    <tbody>
      <tr>
        <td>
          <small>Solicitante</small>
          <div>{solicitacao.cliente.nome}</div>
        </td>
        <td style={{ minWidth: 163 }}>
          <small>{labelCpfCnpj}</small>
          <div>{solicitacao.cliente.cpfCnpj}</div>
        </td>
        <td>
          <small>Forma Pagamento</small>
          <div>{meioPagamento}</div>
        </td>
        <td>
          <small>Data Pagamento</small>
          <div>{getFormatdataPagamento(solicitacao)}</div>
        </td>
        <Show when={!!solicitacao?.dataAgendamento}>
          <td>
            <small style={{ minWidth: 163 }}>Data Agendamento</small>
            <div>{getFormatDataAgendamento(solicitacao)}</div>
          </td>
        </Show>
      </tr>
    </tbody>
  )
}

const ContainerTrFinal = ({ solicitacao }) => {
  const { ContainerButtonLocalizacaoVistoria } = ContainerTrFinal
  return (
    <tbody>
      <tr>
        <td>
          <small>Local da clínica</small>
          <div>{solicitacao.clinica.nome}</div>
          <div>
            {solicitacao.clinica.endereco}, {solicitacao.clinica.bairro},{' '}
            {solicitacao.clinica.municipio} - {solicitacao.clinica.uf}
          </div>
          <div>{solicitacao.clinica.telefone}</div>
          <ContainerButtonLocalizacaoVistoria>
            <Button onClick={() => handleClickLocalizacao(solicitacao)}>
              Localização da clínica
            </Button>
          </ContainerButtonLocalizacaoVistoria>
        </td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  )
}

ContainerTrFinal.ContainerButtonLocalizacaoVistoria = styled.div`
  margin-top: 12px;
  @media print {
    display: none;
  }
`

const DadosCobranca = ({ solicitacao, dataEmissao, children, ...props }) => {
  const { Layout, Title } = DadosCobranca

  return (
    <Layout>
      <Title className="titulo-comprovante">Comprovante</Title>
      <span>Cobrança #{solicitacao.cobrancaId} </span>
      <span>Emissão em {dataEmissao} </span>
      <div>{children}</div>
    </Layout>
  )
}

DadosCobranca.Layout = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
  & > *:first-of-type {
    margin-bottom: 10px;
  }
  @media (max-width: 900px) {
    .titulo-comprovante {
      font-size: 16px;
    }
    span {
      font-size: 13px;
    }
    div {
      font-size: 13px;
    }
  }
`

DadosCobranca.Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #122640;
`

ReciboComponent.Logo = styled.img`
  @media (max-width: 900px) {
    width: 80px;
    align-self: flex-start;
  }
`
ReciboComponent.Divider = styled.div`
  border-bottom: 1px solid #d4d4d4;
  width: 100%;
  margin: 24px 0;
`

ReciboComponent.ContainerValor = styled.div`
  font-weight: 700;
  font-size: 20px;
  text-align: right;
  margin-top: 24px;
`
ReciboComponent.ButtonAgendamento = styled(Button)`
  font-size: 18px;
  font-weight: bold;
`

ReciboComponent.ContainerComprovante = styled.div`
  padding: 28px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  margin: 24px auto 0 auto;
  max-width: 1200px;
  @media (max-width: 1269px) {
    margin: 24px 24px 0 24px;
  }
  @media (max-width: 900px) {
    padding: 16px;
    margin: 18px auto 0 auto;
  }
`
ReciboComponent.InnerHeading = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #545451;
  text-align: center;
  margin-bottom: 24px;
`
ReciboComponent.ContainerBotoes = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  gap: 16px;
  align-items: center;
  @media print {
    display: none;
  }
  @media (max-width: 480px) {
    button {
      width: 100%;
      justify-content: center;
    }
    flex-direction: column;
  }

  @media (max-width: 600px) {
    button {
      width: 100%;
      justify-content: center;
    }
    flex-direction: column;
    width: 100%;
  }
`
ReciboComponent.Button = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
`
ReciboComponent.ButtonWhatsapp = styled(ReciboComponent.Button)`
  border-color: #239c39;
  color: #239c39;
`
const ModalCancelamentoSolicitacaoPaga = ({ close }) => {
  const { Layout, Heading, SubHeading, Button, ButtonContainer } = ModalCancelamentoSolicitacaoPaga
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  async function handleCancelarSolicitacao() {
    window.open('https://app.pipefy.com/public/form/bwZBZfz4', '_blank')
  }

  return (
    <Layout>
      <SubHeading>
        Antes, preencha o formulário da Hyperpay Pagamentos. Tem certeza que deseja cancelar esta
        solicitação?
      </SubHeading>
      <ButtonContainer>
        <Button primary loading={loading} onClick={handleCancelarSolicitacao}>
          Sim
        </Button>
        <Button primary onClick={close}>
          Não
        </Button>
      </ButtonContainer>
    </Layout>
  )
}

ModalCancelamentoSolicitacaoPaga.Layout = styled.div`
  width: 100%;
  color: #545451;
`
ModalCancelamentoSolicitacaoPaga.Heading = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
  color: #122640;
  text-align: center;
`
ModalCancelamentoSolicitacaoPaga.SubHeading = styled.div`
  font-size: 16px;
  color: #122640;
  text-align: center;
  margin-top: 16px;
`
ModalCancelamentoSolicitacaoPaga.Button = styled(Button)`
  width: 40%;
  margin-top: 24px;
  height: 36px;
  font-size: 15px;
  text-align: center;
`
ModalCancelamentoSolicitacaoPaga.ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`
