import React, { useState } from 'react'
import iconHidden from '../assets/icon-hidden.svg'
import { InputLabel, InputLabelType } from './InputLabel'

export const InputSenha = (props: InputLabelType) => {
  const [viewPassword, setViewPassword] = useState(false)

  return (
    <InputLabel
      type={viewPassword ? 'text' : 'password'}
      iconSvg={iconHidden}
      onIconClick={() => setViewPassword(!viewPassword)}
      {...props}
    />
  )
}
