import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { InputLabel } from './InputLabel'
import { isValid as validateCpf } from '@fnando/cpf'
import { isValid as validateCnpj } from '@fnando/cnpj'
import iconCheck from '../assets/icon-check.svg'
import iconError from '../assets/icon-solicitacoes-nao-realizadas.svg'
import { validateCpfCnpj } from '../domains/home/functions'

export const CpfCnpjInput = ({ value: originalValue, onChange, onBlur, name, ...props }) => {
  const { getErrors } = CpfCnpjInput

  const [value, setValue] = useState('')
  useEffect(() => {
    setValue(originalValue)
  }, [originalValue])

  const errors = getErrors(value)

  return (
    <InputMask
      mask='999.999.999-99'
      maskChar=""
      name={name}
      value={value}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e)
      }}
    >
      {(inputProps) =>
        <InputLabel
          {...inputProps}
          iconSvg={value && validateCpfCnpj(value)
            ? iconCheck
            : value && !validateCpfCnpj(value)
              ? iconError
              : null}
          label="CPF/CNPJ *"
          errors={errors}
          {...props}
        />}
    </InputMask>
  )
}
CpfCnpjInput.isValid = (value) => {
  if (value.length === 14 && !validateCpf(value)) return false
  if (value.length === 18 && !validateCnpj(value)) return false
  return true
}
CpfCnpjInput.getErrors = (value) => {
  if (CpfCnpjInput.isValid(value)) return []
  return [value.length === 14 ? 'CPF Inválido' : 'CNPJ Inválido']
}
