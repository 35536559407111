import React from 'react'
import styled from '@emotion/styled'
import { Input } from './Input'
import { Show } from './Show'

export interface InputLabelType
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string
  onIconClick?: () => any
  iconSvg?: any
  errors?: string[]
  loading?: boolean
}

export const InputLabel = ({
  label,
  className,
  style,
  iconSvg,
  onIconClick,
  errors = [],
  loading,
  ...props
}: InputLabelType) => {
  const { Layout, Error } = InputLabel

  return (
    <Layout className={className} style={style}>
      {(!!iconSvg || loading) && <InputAdornment onClick={onIconClick} iconSvg={iconSvg} loading={loading} />}
      {label}
      <br />
      <Input style={{ marginTop: 8, paddingRight: iconSvg ? 30 : 0 }} {...props} />
      <Show when={!!errors.length}>
        {errors.map((error) => (
          <Error key={error}>{error}</Error>
        ))}
      </Show>
    </Layout>
  )
}
InputLabel.Layout = styled.label`
  font-size: 14px;
  line-height: 120%;
  color: #292926;
`
InputLabel.Error = styled.div`
  font-size: 11px;
  color: #eb2a00;
  margin: 0;
  text-align: left;
  margin-top: 4px;
  text-align: right;
`

const InputAdornment = ({ iconSvg, onClick, loading }) => {
  const { Layout } = InputAdornment

  if (loading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    )
  }
  return (
    <Layout onClick={onClick}>
      <img src={iconSvg} />
    </Layout>
  )
}
InputAdornment.Layout = styled.div`
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    top: 38px;
    right: 10px;
  }
`

const Loader = styled.div`
  border: 3px solid #011425;
  border-radius: 50%;
  border-top: 3px solid transparent;
  width: 18px;
  height: 18px;
  animation: spin 1.5s linear infinite;
  position: absolute;
  top: 38px;
  right: 10px;
`
