import React from 'react'
import styled from '@emotion/styled'
import iconLogo from '../assets/icon-logo.svg'

export function LogoMarcaVeiculo ({ marca, ...props }: { marca: string } & React.HTMLAttributes<HTMLDivElement>) {
  const { Layout } = LogoMarcaVeiculo
  return (
    <Layout {...props}>
      <img src={iconLogo} />
    </Layout>
  )
}
LogoMarcaVeiculo.Layout = styled.div`

`
